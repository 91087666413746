import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { getToken } from "../../utils/AuthUtils";

const EditCompanyNewsPage = () => {
  const { id } = useParams(); // Get the company news ID from the URL params
  const [formData, setFormData] = useState({
    company_news_title: "",
    company_news_description: "",
    company_news_image: null, // Store file object for the image
    auther_image: null, // Changed to auther_image
    auther_name: "", // Changed to auther_name
    company_news_coordinator: "",
    publish_date: "", // Use an empty string for publish date
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [authorImagePreview, setAuthorImagePreview] = useState("");
  const navigate = useNavigate();

  // Fetch company news details when the component mounts
  useEffect(() => {
    const fetchCompanyNews = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/company_news/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          const newsData = response.data.data;
          setFormData({
            company_news_title: newsData.company_news_title,
            company_news_description: newsData.company_news_description,
            company_news_coordinator: newsData.company_news_coordinator,
            auther_name: newsData.auther_name || "", // Changed to auther_name
            publish_date: newsData.publish_date || "", // Add publish_date if available
            company_news_image: null, // Start with null for image
            auther_image: null, // Changed to auther_image
          });
          setImagePreview(newsData.company_news_image); // Set initial image preview
          setAuthorImagePreview(newsData.auther_image); // Changed to auther_image
        }
      } catch (err) {
        setError("Error fetching company news details");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyNews();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      if (name === "company_news_image") {
        setFormData((prevData) => ({
          ...prevData,
          company_news_image: files[0], // Store the file object
        }));
        setImagePreview(URL.createObjectURL(files[0])); // Set the image preview
      } else if (name === "auther_image") { // Changed to auther_image
        setFormData((prevData) => ({
          ...prevData,
          auther_image: files[0], // Store the author image file object
        }));
        setAuthorImagePreview(URL.createObjectURL(files[0])); // Set the author image preview
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // Update form data for text fields
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    for (const key in formData) {
      if (key === "company_news_image" && formData.company_news_image instanceof File) {
        data.append(key, formData.company_news_image);
      } else if (key === "auther_image" && formData.auther_image instanceof File) { // Changed to auther_image
        data.append(key, formData.auther_image);
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/company_news/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        navigate("/company_news_list", { state: { snackbarMessage: 'Company news updated successfully' } }); // Redirect to the company news list page after update
      }
    } catch (err) {
      setError("Error updating company news");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(""); // Reset preview
    setFormData((prevData) => ({ ...prevData, company_news_image: null })); // Reset the file input
  };

  const handleRemoveAuthorImage = () => {
    setAuthorImagePreview(""); // Reset preview
    setFormData((prevData) => ({ ...prevData, auther_image: null })); // Reset the file input
  };

  const handleCancel = () => {
    navigate("/company_news_list"); // Navigate to the company news list page
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Edit Company News
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="company_news_title"
            label="News Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.company_news_title}
            onChange={handleChange}
            required
          />
          <TextField
            name="company_news_description"
            label="News Description"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.company_news_description}
            onChange={handleChange}
            required
          />
          
          <Typography variant="subtitle1" gutterBottom>
            Company News Image
          </Typography>
          <input
            name="company_news_image"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {imagePreview && (
            <Box mt={2} position="relative">
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: "200px", height: "auto", borderRadius: "4px" }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}

          <Typography variant="subtitle1" gutterBottom>
            Author Image
          </Typography>
          <input
            name="auther_image" // Changed to auther_image
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {authorImagePreview && (
            <Box mt={2} position="relative">
              <img
                src={authorImagePreview}
                alt="Author Preview"
                style={{ width: '200px', height: 'auto', borderRadius: '4px' }}
              />
              <IconButton
                onClick={handleRemoveAuthorImage}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          
          <TextField
            name="company_news_coordinator"
            label="Coordinator"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.company_news_coordinator}
            onChange={handleChange}
            required
          />
          <TextField
            name="auther_name" // Changed to auther_name
            label="Author Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.auther_name}
            onChange={handleChange}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update Company News"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            sx={{ mt: 0 }}
          >
            Cancel
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </div>
  );
};

export default EditCompanyNewsPage;

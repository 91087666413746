import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  Typography,
  MenuItem,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import EditSubCategoryModal from "./EditSubCategoryModal";
import AddSubCategoryModal from "./AddSubCategoryModal";
import { getToken } from "../../../utils/AuthUtils";
import { color } from "@mui/system";

function SubCategoryList() {
  const [subCategories, setSubCategories] = useState([]);
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  // const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchSubCategories();
  }, [page, rowsPerPage, selectedCategory]); // Add selectedCategory to dependencies

  const fetchSubCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const subCategoriesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/sub_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
              category: selectedCategory, // Include selected category
            },
          }
        );

        const partnersResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/category/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPartners(partnersResponse.data.data);
        setSubCategories(subCategoriesResponse.data.data);
        setTotalItems(subCategoriesResponse.data.pagination.totalItems);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Error fetching data");
      setOpenSnackbar(true);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedSubCategoryId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSubCategoryId(null);
  };

  const handleDeleteSubCategory = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/partner/sub_category/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Subcategory deleted successfully");
        setOpenSnackbar(true);
        await fetchSubCategories(); // Refresh the list after deleting
      }
    } catch (err) {
      setSnackbarMessage("Failed to delete subcategory");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedSubCategory(response.data.data);
        setSelectedSubCategoryId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setError("Error fetching subcategory details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedSubCategoryId(null);
    setSelectedSubCategory(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddSubCategory = () => {
    handleCloseAddModal();
    fetchSubCategories(); // Refresh the list after adding
  };

  // const handleStatusToggle = (id, currentStatus) => {
  //   const newStatus = !currentStatus;
  //   updateSubCategoryStatus(id, newStatus); // Update status in the backend
  // };

  // const updateSubCategoryStatus = async (id, status) => {
  //   try {
  //     const token = getToken();
  //     if (token) {
  //     await axios.put(
  //       `${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${id}/status_subcategorypartner`,
  //       {
  //         status,
  //       },{
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setSnackbarMessage("Subcategory status updated successfully");
  //     setOpenSnackbar(true);
  //     await fetchSubCategories(); // Refresh the list after status change
  //   } }catch (err) {
  //     setError("Failed to update Subcategory status");
  //     setOpenSnackbar(true);
  //   }
  // };
  const handleStatusToggle = async (id, currentStatus) => {
    const newStatus = !currentStatus; // Toggle between true and false
    setSubCategories((prev) =>
      prev.map((category) =>
        category._id === id ? { ...category, status: newStatus } : category
      )
    );

    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${id}/status_subcategorypartner`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("subcategory status updated successfully");
        setOpenSnackbar(true);
        fetchSubCategories();
      }
    } catch (err) {
      setSubCategories((prev) =>
        prev.map((category) =>
          category._id === id
            ? { ...category, status: currentStatus }
            : category
        )
      );

      setSnackbarMessage("Failed to update subcategory status");
      setOpenSnackbar(true);
    }
  };

  const filteredCategories = subCategories
    .filter((subCategory) =>
      subCategory.SubCategory_name?.toLowerCase().includes(
        searchTerm.toLowerCase()
      )
    )
    .filter((subCategory) =>
      selectedCategory ? subCategory.partner_id === selectedCategory : true
    );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Partner Subcategory</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Grid container spacing={2} mb={2} alignItems="flex-start">
        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={partners.sort((a, b) =>
              a.partner_name.localeCompare(b.partner_name, "en", {
                sensitivity: "base",
              })
            )}
            getOptionLabel={(option) => option.partner_name || ''}
            value={partners.find(partner => partner._id === selectedCategory) || null}
            onChange={(event, newValue) => {
              setSelectedCategory(newValue ? newValue._id : '');
              setPage(0);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Category"
                variant="outlined"
                size="small"
              />
            )}
            sx={{ width: "300px" }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent="flex-end"
          sx={{ order: 2, marginLeft: "auto" }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenAddModal}
          >
            Create
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Subcategory </TableCell>
              <TableCell>Category </TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((subCategory, index) => {
                const partner = partners.find(
                  (p) => p._id === subCategory.partner_id
                );
                const partnerName = partner
                  ? partner.partner_name
                  : "No Partner";

                return (
                  <TableRow key={subCategory._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{subCategory.SubCategory_name}</TableCell>
                    <TableCell>{partnerName}</TableCell>
                    <TableCell>{new Date(subCategory?.createdAt).toLocaleDateString()} </TableCell>
                    <TableCell sx={{ textAlign: "center !important" }}>
                      <Switch
                        checked={subCategory.status}
                        onChange={() =>
                          handleStatusToggle(
                            subCategory._id,
                            subCategory.status
                          )
                        }
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                      >
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenEditModal(subCategory._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() =>
                            handleOpenDeleteDialog(subCategory._id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No subcategories found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 100 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddSubCategoryModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddSubCategory}
      />
      {selectedSubCategoryId && (
        <EditSubCategoryModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          category={selectedSubCategory}
          onUpdate={() => {
            setSnackbarMessage("subcategory  updated successfully");
            setOpenSnackbar(true);
            handleCloseEditModal();
            fetchSubCategories();
          }}
        />
      )}
      {selectedSubCategoryId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this subcategory?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteSubCategory(selectedSubCategoryId)}
              color="secondary"
              sx={{ backgroundColor: "#e0e0e0" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        // message={snackbarMessage}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default SubCategoryList;

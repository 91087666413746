import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import AddProjectModal from "./AddProjectModal";
import EditProject from "./EditProject";

function ProjectList() {
  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/category`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
          params: { page: page + 1, limit: rowsPerPage, search: searchTerm },
        }
      );
      setSubjects(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError("Error fetching subjects");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, [page, rowsPerPage, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (subjectId) => {
    setSelectedSubjectId(subjectId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSubjectId(null);
  };

  const handleDeleteSubject = async (id) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/project/category/delete/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setSnackbarMessage("category deleted successfully");
      setOpenSnackbar(true);
      fetchSubjects();
    } catch (err) {
      setSnackbarMessage("Failed to delete subject");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setSelectedSubject(response.data.data);
      setSelectedSubjectId(id);
      setOpenEditModal(true);
    } catch (err) {
      setError("Error fetching subject details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedSubjectId(null);
    setSelectedSubject(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddSubject = () => {
    setSnackbarMessage("Category added successfully");
    setOpenSnackbar(true);
    handleCloseAddModal();
    fetchSubjects();
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateSubjectStatus(id, newStatus);
  };

  const updateSubjectStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/project/category/${id}/status_project`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token_key"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Category status updated successfully");
      setOpenSnackbar(true);
      fetchSubjects();
    } catch (err) {
      setError("Failed to update category status");
      setOpenSnackbar(true);
    }
  };
  const filteredCategories = subjects
  .filter((subject) =>
    subject.project_category_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  )
  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        padding={1}
        sx={{ backgroundColor: "#e0e0e0" }}
      >
        <Typography variant="h4" gutterBottom>
          Project Category
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Project Category Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.length > 0 ? (
              filteredCategories
                .map((subject, index) => (
                  <TableRow key={subject._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{subject.project_category_name}</TableCell>
                    <TableCell>
                      <Switch
                        checked={subject.status}
                        onChange={() =>
                          handleStatusToggle(subject._id, subject.status)
                        }
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditModal(subject._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(subject._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No category found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddProjectModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddSubject}
      />
      {openEditModal && selectedSubject && (
        <EditProject
          open={openEditModal}
          onClose={handleCloseEditModal}
          subjectId={selectedSubjectId}
          subject={selectedSubject}
          onUpdate={() => {
            setSnackbarMessage("Category updated successfully");
            setOpenSnackbar(true);
            handleCloseEditModal();
            fetchSubjects();
          }}
        />
      )}
      {openDeleteDialog && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this category?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteSubject(selectedSubjectId)}
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ProjectList;

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  Box,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import "./AddAssignCourseToPartner .css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AssignCourse = () => {
  const [partnerCategoryId, setpartnerCategoryId] = useState("");
  const [partnerSubCategoryId, setpartnerSubCategoryId] = useState("");
  const [partnerId, setpartnerId] = useState("");
  const [courseCategoryId, setCourseCategoryId] = useState("");
  const [courseSubCategoryId, setCourseSubCategoryId] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [alreadyAssignedCourses, setAlreadyAssignedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [partnerCategories, setpartnerCategories] = useState([]);
  const [partnerSubCategories, setpartnerSubCategories] = useState([]);
  const [partners, setpartners] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredpartners, setFilteredpartners] = useState([]);
  const [filteredSubpartnerCategories, setFilteredpartnerSubCategories] =
    useState([]);

  const token = getToken();
  const navigate = useNavigate();

  const handleCancel = () => {
    // Reset the form fields
    setpartnerCategoryId("");
    setpartnerSubCategoryId("");
    setpartnerId("");
    setCourseCategoryId("");
    setCourseSubCategoryId("");

    // Optionally, navigate the user to the previous page or list page
    navigate("/assign_courses_to_partner_list");
  };

  // Fetch initial partner and course categories
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const [partnerCategoriesResponse, courseCategoriesResponse] =
            await Promise.all([
              axios.get(
                `${process.env.REACT_APP_BACKEND}/api/partner/category/nopagination`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
              axios.get(
                `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
            ]);

          setpartnerCategories(partnerCategoriesResponse.data.data);
          console.log(
            "Partner Categories Response:",
            partnerCategoriesResponse.data.data
          );

          setCourseCategories(
            courseCategoriesResponse.data.data.filter(
              (category) => category.status === 1
            )
          );
        }
      } catch (error) {
        setError("Failed to fetch data.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/courses`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setFilteredCourses(data.data);
    } catch (error) {
      setError("Failed to fetch courses.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch partner subcategories and partners based on selections
  useEffect(() => {
    const fetchDataBasedOnSelections = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          // Fetch partner subcategories based on selected partner category
          if (partnerCategoryId) {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/partner/sub_category/nopagination/bysubcat/${partnerCategoryId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // params: { partner_id: partnerCategoryId },
              }
            );
            console.log("Partner Subcategories Response:", response.data);
            const filteredSubCategories = response.data.data;
            setFilteredpartnerSubCategories(filteredSubCategories);
          } else {
            setFilteredpartnerSubCategories([]); // Reset subcategories if no category is selected
          }

          // Fetch partners based on selected partner subcategory
          if (partnerSubCategoryId) {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/partner/partnersbysubcat/${partnerSubCategoryId}`,
              {
                // params: { subCatId: partnerSubCategoryId },
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setpartners(data.data);
          } else {
            setpartners([]); // Reset partners if no subcategory is selected
          }
        }
      } catch (error) {
        setError("Failed to fetch Partner subcat data.");
        console.error(
          "Error fetching dependent data:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchDataBasedOnSelections();
  }, [partnerCategoryId, partnerSubCategoryId]); // Added partnerSubCategoryId to the dependency array

  // Filter partner based on the selected subcategories category
  useEffect(() => {
    if (partnerSubCategoryId) {
      console.log("Current partnerSubCategoryId:", partnerSubCategoryId);
      console.log("Current partners:", partners);

      const fetchPartner = async (req, res) => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/partnersbysubcat/${partnerCategoryId}/${partnerSubCategoryId}`,
          {
            // params: { subCatId: partnerSubCategoryId },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // setpartners(data.data);
        setFilteredpartners(data.data);
      };
      fetchPartner();
    }
  }, [partnerSubCategoryId, partners]);

  // Fetch already assigned courses when partnerId or courseSubCategoryId changes
  useEffect(() => {
    const fetchAlreadyAssignedCourses = async () => {
      if (partnerId && courseCategoryId && courseSubCategoryId) {
        setLoading(true);
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/assigned_courses_partner/${partnerId}/${courseCategoryId}/${courseSubCategoryId}`,
            {
              method: "GET",
              // params: { partner_id: partnerId, subcategory_id: courseSubCategoryId },
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setAlreadyAssignedCourses(data.data);

          setSelectedCourses(data.data.map((course) => course.course_id)); // Assuming the API returns an array of courses with course_id
        } catch (error) {
          setError("Failed to fetch assigned courses.");
          console.error("Error fetching assigned courses:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAlreadyAssignedCourses();
  }, [partnerId, courseSubCategoryId]);

  // Fetch course subcategories and courses based on selections
  useEffect(() => {
    const fetchCourseDataBasedOnSelections = async () => {
      setLoading(true);
      try {
        // Fetch subcategories based on selected course category
        if (courseCategoryId) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/bycatid`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { category_id: courseCategoryId },
            }
          );
          setFilteredSubCategories(
            data.data.filter((subCategory) => subCategory.status === 1)
          );
        } else {
          setFilteredSubCategories([]);
        }

        // Fetch courses based on selected course subcategory
        if (courseSubCategoryId) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses/without_pagination`,
            {
              params: { subcategory_id: courseSubCategoryId },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCourses(data.data.filter((course) => course.status === 1));
        } else {
          setCourses([]);
        }
      } catch (error) {
        setError("Failed to fetch course data.");
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDataBasedOnSelections();
  }, [courseCategoryId, courseSubCategoryId]);

  // Filter courses based on the selected course Subcategory
  useEffect(() => {
    if (courseSubCategoryId) {
      const filtered = courses.filter(
        (course) => course.course_subcategory_id === courseSubCategoryId
      );
      setFilteredCourses(filtered);
    }
  }, [courseSubCategoryId, courses]);

  const handleCourseChange = (courseId) => {
    setSelectedCourses((prev) => {
      if (prev.includes(courseId)) {
        return prev.filter((id) => id !== courseId);
      } else {
        return [...prev, courseId];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (
      !partnerCategoryId ||
      !partnerSubCategoryId ||
      !partnerId ||
      !courseCategoryId ||
      !courseSubCategoryId ||
      selectedCourses.length === 0
    ) {
      setError("Please fill out all required fields.");
      return;
    }

    // Filter out already assigned courses
    const filteredSelectedCourses = selectedCourses.filter(
      (courseId) =>
        !alreadyAssignedCourses.some(
          (assignedCourse) => assignedCourse.course_id === courseId
        )
    );

    // If no new courses to assign
    if (filteredSelectedCourses.length === 0) {
      setError("No new courses selected to assign.");
      return;
    }

    const assignData = {
      partner_category_id: partnerCategoryId,
      partner_sub_category_id: partnerSubCategoryId,
      partner_id: partnerId,
      course_category_id: courseCategoryId,
      course_sub_category_id: courseSubCategoryId,
      course_ids: filteredSelectedCourses, // Only new courses
    };

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner`,
        assignData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        setSuccess("Courses assigned successfully!");
        setError(null);
        // Reset fields after successful submission
        setpartnerCategoryId("");
        setpartnerSubCategoryId("");
        setpartnerId("");
        setCourseCategoryId("");
        setCourseSubCategoryId("");
        setSelectedCourses([]);
        if (response.status === 201) {
          // Store success message in sessionStorage
          sessionStorage.setItem(
            "assignSuccessMessage",
            "Course successfully assigned to partner"
          );
          // Redirect to Labnames list
          navigate("/assign_courses_to_partner_list");
        }
        // Redirect to the list page
        // navigate('/assign_courses_to_partner_list');
      } else {
        setError(response.data.message || "Failed to assign course.");
      }
    } catch (error) {
      setError("Failed to assign course.");
      console.error("Error assigning course:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };

  return (
    <Container maxWidth="100%" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        padding={1}
        sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4">Assign Course To Partner</Typography>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Alert severity="error" sx={{ marginBottom: "20px" }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select partner Category"
                    value={partnerCategoryId}
                    onChange={(e) => {
                      setpartnerCategoryId(e.target.value);
                      setpartnerSubCategoryId(""); // Reset dependent state
                      setpartners([]); // Reset partners
                    }}>
                    {partnerCategories.length > 0 ? (
                      partnerCategories.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.partner_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No categories available</MenuItem>
                    )}
                  </TextField>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select partner Subcategory"
                    value={partnerSubCategoryId}
                    onChange={(e) => {
                      setpartnerSubCategoryId(e.target.value);
                      setpartners([]); // Reset partners
                    }}>
                    {filteredSubpartnerCategories.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.SubCategory_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select partner"
                    value={partnerId}
                    onChange={(e) => setpartnerId(e.target.value)}>
                    {filteredpartners.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.partner_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select Course Category"
                    value={courseCategoryId}
                    onChange={(e) => {
                      setCourseCategoryId(e.target.value);
                      setCourseSubCategoryId(""); // Reset dependent state
                      setCourses([]); // Reset courses
                    }}>
                    {courseCategories.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.courses_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select Course Subcategory"
                    value={courseSubCategoryId}
                    onChange={(e) => {
                      setCourseSubCategoryId(e.target.value);
                      setCourses([]); // Reset courses
                    }}>
                    {filteredSubCategories.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.courses_sub_category_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Already Assigned Courses</Typography>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    {alreadyAssignedCourses.length > 0 ? (
                      alreadyAssignedCourses.map((item) => (
                        <div key={item._id}>
                          <span>Course: {item.course_id.courses_name}</span>{" "}
                          {/* Adjust to display relevant course properties */}
                        </div>
                      ))
                    ) : (
                      <p>No courses assigned.</p>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant=""
                    color="primary"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#e0e0e0", marginLeft: "10px" }}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  alignItems="center">
                  <Typography variant="h6">Available Courses</Typography>
                  <Box
                    sx={{
                      height: "500px", // Set the specific height you want for the grid
                      overflowY: "auto",
                    }}>
                    <FormGroup>
                      {filteredCourses
                        .filter(
                          (course) =>
                            !alreadyAssignedCourses.some(
                              (assignedCourse) =>
                                assignedCourse.course_id._id === course._id
                            )
                        )
                        .map((course) => (
                          <FormControlLabel
                            key={course._id}
                            control={
                              <Checkbox
                                checked={selectedCourses.includes(course._id)}
                                onChange={() => handleCourseChange(course._id)}
                              />
                            }
                            label={course.courses_name}
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </Grid>
                <Box textAlign="center" mt={3}>
                  <Typography variant="body">
                    Scroll to view more <ExpandMoreIcon />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <Snackbar
        open={success !== null}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssignCourse;

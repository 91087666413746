import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  TextField,
  TablePagination,
  Button,
  IconButton,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SchoolIcon from "@mui/icons-material/School";
import Rating from "@mui/material/Rating";

import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

const StudentPage = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortTerm, setSortTerm] = useState("grade-ascending"); // default sorting value
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0); // Initialize totalItems as number
  const [selectedGrade, setSelectedGrade] = useState({
    grade: "",
    gradeDivision: "",
  });
  const [filter, setFilter] = useState({
    schoolCategory: "",
    schoolSubCategory: "",
    school: "",
    grade: "",
  });
  const [grades, setGrades] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [school, setSchool] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState([]);

  useEffect(() => {
    fetchStudents();
    // fetchNumCourses();
  }, [page, rowsPerPage, searchTerm]);

  // Fetch grades data
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade`
        );
        setGrades(response.data.data); // Adjust based on your API response structure
      } catch (err) {
        console.error("Failed to fetch grades:", err);
      }
    };
    fetchGrades();
  }, []);
  const fetchStudents = async () => {
    // setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access students.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;
      if (data?.data) {
        setStudents(data.data);
        console.log(data.data);

        setTotalItems(data.pagination.totalItems); // Correctly set totalItems
      } else {
        setError("No students found");
      }
    } catch (err) {
      setError("Failed to fetch students");
    } finally {
      setLoading(false);
    }
  };

  // Submit filter and fetch filtered schools
  const handleFilterSubmit = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/filter_student`,
          {
            params: filter,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Update the displayed students with the filtered students
        setStudents(response.data.data);
        setTotalItems(response.data.pagination.totalItems); // Assuming your API supports pagination
      }
    } catch (err) {
      console.error("Failed to fetch filtered schools:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchstudentBygradedivision = async () => {
      if (selectedGrade.grade === "") {
        // If "Select All" is chosen, fetch all students
        fetchStudents();
        return;
      }
      if (!selectedGrade.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/grades/${selectedGrade.gradeDivision}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const students = response.data.data;
        setStudents(students);
        console.log("students: ", response.data.data);

        // const filteredTeachers = resourcePersonData.filter(
        //   (item) => item.teacher_subCategoryId === newProduct.teacherSubCategory
        // );
        // setTeacherFilter(filteredTeachers);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchstudentBygradedivision();
  }, [selectedGrade.gradeDivision]);

  const deleteStudent = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/student/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSnackbarMessage("Student deleted successfully");
      setOpenSnackbar(true);
      fetchStudents();
    } catch (err) {
      setError("Failed to delete student");
    }
  };

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized access.");
        return;
      }

      const newStatus = !currentStatus;

      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/student/${id}/status_student`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSnackbarMessage("Student status updated successfully");
      setOpenSnackbar(true);
      fetchStudents();
    } catch (err) {
      setError("Failed to update student status");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset page to 0 when search term changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Function to handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(`Filter updated: ${name} = ${value}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();
        if (token) {
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category?limit=3000`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(categoriesResponse.data.data);
      } }catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };
    fetchData();
  }, []); // Reload based on changes to countryId and stateId

  // Fetch Subcategory based on selected category
  useEffect(() => {
    const fetchSubCategories = async () => {
      if (filter.schoolCategory) {
        try {
          const token = getToken();
          if (token) {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/sub_school_category?limit=300`,
              {
                params: { school_category: filter.schoolCategory },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setSubCategories(response.data.data);
          }
        } catch (err) {
          console.error("Failed to fetch Subcategory:", err);
        }
      } else {
        setSubCategories([]);
      }
    };
    fetchSubCategories();
  }, [filter.schoolCategory]);

  useEffect(() => {
    const fetchSchools = async () => {
      if (filter.schoolSubCategory) {
        try {
          const token = getToken();
          if (token) {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/school?limit=300`,
              {
                params: { schoolSubCategory: filter.schoolSubCategory }, // Ensure this matches your API's expected parameter
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setSchool(response.data.data);
          }
        } catch (err) {
          console.error("Failed to fetch schools:", err);
        }
      } else {
        setSchool([]); // Clear the school list if no Subcategory is selected
      }
    };
    fetchSchools();
  }, [filter.schoolSubCategory]); // Trigger when the Subcategory changes

  // Use filteredSchools if filters are applied, otherwise show all schools
  const schoolsToDisplay =
    filteredStudent.length > 0 ? filteredStudent : students;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0">
        <Typography variant="h4" component="h1">
          Students
        </Typography>
      </Box>
      <Box padding={2} paddingLeft={0}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          // sx={{ width: "300px" }}
        />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Category */}
            <Grid item xs={12} sm={6} md={2.5}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  name="schoolCategory"
                  value={filter.schoolCategory}
                  onChange={handleFilterChange}
                  label="Category">
                  {categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.school_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Subcategory */}
            <Grid item xs={12} sm={6} md={2.5}>
              <FormControl fullWidth>
                <InputLabel>Subcategory</InputLabel>
                <Select
                  name="schoolSubCategory"
                  value={filter.schoolSubCategory}
                  onChange={handleFilterChange}
                  label="Subcategory">
                  {subCategories.map((subcategory) => (
                    <MenuItem key={subcategory._id} value={subcategory._id}>
                      {subcategory.SubCategory_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* School */}
            <Grid item xs={12} sm={6} md={2.5}>
              <FormControl fullWidth>
                <InputLabel>Schools</InputLabel>
                <Select
                  name="school"
                  value={filter.school}
                  onChange={handleFilterChange}
                  label="School">
                  {school.map((school) => (
                    <MenuItem key={school._id} value={school._id}>
                      {school.school_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Grade */}
            <Grid item xs={12} sm={6} md={2.5}>
              <FormControl fullWidth>
                <InputLabel>Grade</InputLabel>
                <Select
                  name="grade"
                  value={filter.grade}
                  onChange={handleFilterChange}
                  label="Grade">
                  {grades.map((grade) => (
                    <MenuItem key={grade._id} value={grade._id}>
                      {grade.gradename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Button */}
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              display="flex"
              justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilterSubmit}
                disabled={loading}
                sx={{ minWidth: 150, backgroundColor: "#e0e0e0" }}>
                {loading ? <CircularProgress size={24} /> : "Apply Filters"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* <Box flexGrow={1} /> */}
        {/* <Button
                component={Link}
                to="/add-student"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Add Student
              </Button> */}
        <Grid
          container
          spacing={2}
          sx={{
            padding: 2,
            paddingRight: { xs: 0, sm: 0, md: 2 },
          }}>
          {schoolsToDisplay.length > 0 ? (
            schoolsToDisplay.map((student) => {
              // Split grade_division into grade and division
              const [_, grade, division] = student.grade_division
                ? student.grade_division.split(" ")
                : ["", "N/A", "N/A"];

              return (
                <Grid item xs={12} sm={12} md={6} xl={4} key={student._id}>
                  <Card
                    sx={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "12px",
                      // p:'2'
                    }}>
                    {/* Top Section: Name, ID, Grade, and Division */}
                    <Box sx={{ backgroundColor: "#e0dfda", padding: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        {/* Student Name */}
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", color: "#000" }}>
                          {student.name}
                        </Typography>
                      </Box>

                      {/* ID, Grade, Division */}
                      <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                        <Typography
                          sx={{
                            backgroundColor: "#a0947c",
                            padding: "4px 8px",
                            borderRadius: "8px",
                            fontSize: "12px",
                            color: "#fff",
                          }}>
                          {`ID-${student.student_id}`}
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: "#a0947c",
                            padding: "4px 8px",
                            borderRadius: "8px",
                            fontSize: "12px",
                            color: "#fff",
                          }}>
                          {`Grade-${grade}`}
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: "#a0947c",
                            padding: "4px 8px",
                            borderRadius: "8px",
                            fontSize: "12px",
                            color: "#fff",
                          }}>
                          {`Division-${division}`}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Bottom Section: Profile Picture, Details, and Rating */}
                    <Box sx={{ backgroundColor: "#fff", padding: 2 }}>
                      {/* Profile Picture & Details */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          mt: 2,
                        }}>
                        {/* Profile Picture */}
                        <Box sx={{ marginRight: "2px" }}>
                          <img
                            src={student.profile_url || "/default-profile.png"}
                            alt={`${student.name}'s profile`}
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              border: "1px solid #333",
                              marginRight: "15px",
                            }}
                          />
                        </Box>

                        {/* Details and Rating aligned vertically */}
                        <Box
                          flexGrow={1}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}>
                          {/* Details */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}>
                              <MailIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "4px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.email}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}>
                              <PhoneIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "4px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.mobile}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}>
                              <SchoolIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "3px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.totalCourses || "N/A"}
                              </Typography>
                            </Box>
                          </Box>

                          {/* Rating */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}>
                            <ThumbUpIcon
                              sx={{
                                fontSize: "22px",
                                mr: 1,
                                color: "#fff",
                                backgroundColor: "#a0947c",
                                padding: "3px",
                                borderRadius: "10px",
                              }}
                            />
                            <Rating
                              name="student-rating"
                              value={student.rating || 3}
                              precision={0.5}
                              readOnly
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <p>No students Available</p>
          )}
        </Grid>

        {totalItems > rowsPerPage && (
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[6, 12, 24]}
              labelRowsPerPage="Items per page"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentPage;

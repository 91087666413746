import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Container,
  Snackbar,
  Alert
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

function AddSubCategoryModal({ open, onClose, onAdd }) {
  const [subCategoryName, setSubCategoryName] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    subCategoryName: "",
    partnerId: "",
    fetchPartners: "",
    api: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/partner/category`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("API Response:", response.data); // Log API response

          if (response.data && Array.isArray(response.data.data)) {
            setPartners(response.data.data);
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              fetchPartners: "Invalid response format",
            }));
          }
        }
      } catch (err) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fetchPartners: "Failed to fetch partners",
        }));
        console.error("Fetch Error:", err); // Log fetch error
      }
    };

    fetchPartners();
  }, []);

  const validate = () => {
    let isValid = true;
    const newErrors = {
      subCategoryName: "",
      partnerId: "",
    };

    if (!subCategoryName.trim()) {
      newErrors.subCategoryName = "Subcategory Name is required";
      isValid = false;
    }

    if (!partnerId) {
      newErrors.partnerId = "Partner Name is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAdd = async () => {
    if (!validate()) return;
    if (isLoading) return; // Prevent multiple clicks

    try {
      setIsLoading(true);
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/partner/sub_category`,
          {
            SubCategory_name: subCategoryName,
            partner_id: partnerId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("subcategory added successfully");
        setOpenSnackbar(true);
        setErrors({
          subCategoryName: "",
          partnerId: "",
          fetchPartners: "",
          api: "",
        });
        onAdd();
        setSubCategoryName("");
        setPartnerId(""); 
        onClose();
      }
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        api: "Failed to add subcategory",
      }));
      console.error("API Error:", err); // Log API error
    } finally {
      setIsLoading(false);
    }
  };

  // Filter partners by status (not deleted and active)
  const filteredPartners = partners.filter(
    (partner) => !partner.isDeleted && partner.status
  );

  // Handle snackbar close event
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
   <Container>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Subcategory</DialogTitle>
        <form onSubmit={handleAdd}>
        <DialogContent>
          <FormControl fullWidth margin="dense" error={!!errors.partnerId}>
            <TextField
              select
              value={partnerId}
              onChange={(e) => setPartnerId(e.target.value)}
              label="Category Name"
              displayEmpty
              required >
              {filteredPartners.length > 0 ? (
                filteredPartners.map((partner) => (
                  <MenuItem key={partner._id} value={partner._id}>
                    {partner.partner_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No partners available</MenuItem>
              )}
            </TextField>
            {/* {errors.partnerId && (
              <FormHelperText>{errors.partnerId}</FormHelperText>
            )} */}
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            label=" Name"
            fullWidth
            value={subCategoryName}
            onChange={(e) => setSubCategoryName(e.target.value)}
            // error={!!errors.subCategoryName}
            // helperText={errors.subCategoryName}
            required
          />
          {errors.fetchPartners && (
            <FormHelperText error>{errors.fetchPartners}</FormHelperText>
          )}
          {errors.api && <FormHelperText error>{errors.api}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button 
            type="submit"
            // onClick={handleAdd} 
            color="primary" 
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? 'Adding...' : 'Add'}
          </Button>
        </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
   </Container>
    
  );
}

export default AddSubCategoryModal;

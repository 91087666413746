import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Container,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditNewsletterPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    publish_date: "",
    author: "",
    file_url: null, // for new file upload
    status: 1,
    existingFile: "", // for displaying existing file name
  });

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({}); // Track form validation errors
  const navigate = useNavigate();

  // Fetch the newsletter data
  const fetchNewsletter = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/newsletter/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const publishDate = new Date(response.data.data.publish_date)
          .toISOString()
          .split("T")[0];
        setFormData({
          title: response.data.data.title,
          content: response.data.data.content,
          publish_date: publishDate,
          author: response.data.data.author,
          file_url: null, // Reset to null since we're handling it separately
          status: response.data.data.status,
          existingFile: response.data.data.file_url, // Save existing file URL
        });
      }
    } catch (err) {
      setError("Error fetching newsletter data");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsletter();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file" && files.length > 0) {
      setFormData({
        ...formData,
        file_url: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate form fields
    let errors = {};
    if (!formData.title) errors.title = "Title is required";
    if (!formData.content) errors.content = "Content is required";
    if (!formData.publish_date)
      errors.publish_date = "Publish date is required";
    if (!formData.author) errors.author = "Publish date is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Set errors if any field is missing
      setLoading(false);
      return; // Prevent form submission if there are errors
    }

    const data = new FormData();
    for (const key in formData) {
      // If file_url is null (no new file selected), keep the existing file URL
      if (key === "file_url" && !formData.file_url) {
        data.append(key, formData.existingFile);
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/newsletter/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        navigate("/newsletter_list", {
          state: { snackbarMessage: "Newsletter updated successfully" },
        });
      }
    } catch (err) {
      setError("Error updating newsletter");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/newsletter_list");
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" >
          Edit Newsletter
        </Typography>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="title"
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
            required
            error={!!formErrors.title} // Highlight if there's an error
            helperText={formErrors.title} // Show error message
          />
          <TextField
            name="content"
            label="Content"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.content}
            onChange={handleChange}
            required
            error={!!formErrors.content} // Highlight if there's an error
            helperText={formErrors.content} // Show error message
          />
          <TextField
            name="publish_date"
            label="Publish Date"
            variant="outlined"
            fullWidth
            margin="normal"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.publish_date}
            onChange={handleChange}
            error={!!formErrors.publish_date} // Highlight if there's an error
            helperText={formErrors.publish_date} // Show error message
          />
          <TextField
            name="author"
            label="Author"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.author}
            onChange={handleChange}
            error={!!formErrors.author} // Highlight if there's an error
            helperText={formErrors.author} // Show error message
          />
          <input
            name="file_url"
            type="file"
            accept="application/pdf"
            onChange={handleChange}
          />
          {formData.file_url ? (
            <Typography variant="body2">
              File selected: {formData.file_url.name}
            </Typography>
          ) : (
            formData.existingFile && (
              <Typography variant="body2" color="textSecondary">
                Current file: {formData.existingFile.split("/").pop()}
              </Typography>
            )
          )}
          
          <Button
            type="button"
            color="secondary"
            sx={{ mt: 2, mr: 2,}}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, backgroundColor: "#e0e0e0" }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </Container>
  );
};

export default EditNewsletterPage;

import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/AuthUtils';

const AddSliderPage = () => {
  const [formData, setFormData] = useState({
    title: '',
    image_url: null,
    redirected_url: '',
    start_date: '',
    end_date: '',
    is_active: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        image_url: files[0],
      }));
      setImagePreview(URL.createObjectURL(files[0]));
      setFormErrors({ ...formErrors, image_url: null });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const isValidURL = (urlString) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)\\.)+([a-z]{2,})|' + // domain...
      'localhost|' + // localhost...
      '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IP...
      '\\[?[a-f0-9]*:[a-f0-9:%.~+?&=#\\[\\]]*\\])' + // IPv6...
      '(\\:\\d+)?(\\/[-a-z0-9%_.~+?&=#]*)*$', 'i'); // port and path
    return pattern.test(urlString);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(''); // Clear previous errors

    // Validation for date
    if (new Date(formData.start_date) > new Date(formData.end_date)) {
      setError("End date must be after start date.");
      setIsLoading(false);
      return;
    }

    // Validate redirected_url
    if (!isValidURL(formData.redirected_url)) {
      setError("Please provide a valid URL.");
      setIsLoading(false);
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const token = getToken();
      if (token) {
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/slider`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        navigate('/slider_list', { state: { snackbarMessage: 'Slider added successfully' } });
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Error adding slider';
      setError(errorMessage);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({ ...formData, image_url: null });
    setFormErrors({ ...formErrors, image_url: ' image is required' });
  };

  const handleCancel = () => {
    navigate('/slider_list');
  };

  return (
    <Box padding={2}>
      <Typography variant="h4">Add New Slider</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <input
          type="file"
          name="image_url"
          accept="image/*"
          onChange={handleChange}
          required
        />
        {formErrors.event_image && (
          <Typography color="error">{formErrors.event_image}</Typography>
        )}
        {imagePreview && (
          <Box mt={2} position="relative">
            <img
              src={imagePreview}
              alt="Preview"
              style={{ width: '200px', height: 'auto', borderRadius: '4px' }}
            />
            <IconButton
              onClick={handleRemoveImage}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <TextField
          label="Redirected URL"
          name="redirected_url"
          value={formData.redirected_url}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Start Date"
          name="start_date"
          type="date"
          value={formData.start_date}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          label="End Date"
          name="end_date"
          type="date"
          value={formData.end_date}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Button type="submit" variant="contained" color="primary">
              Add Slider
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button>
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </form>
    </Box>
  );
};

export default AddSliderPage;

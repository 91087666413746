// src/api/companyServices.js

const API_URL = `${process.env.REACT_APP_BACKEND}/company`; // Adjust the URL as needed
const API_URL_P = `${process.env.REACT_APP_BACKEND}/partner`; // Adjust the URL as needed



export const fetchCompanies = async () => {
    try {
        const response = await fetch(API_URL);
        if (!response.ok) throw new Error('Failed to fetch companies');
        const result = await response.json();

        // Check if result.data is an array
        if (result.status !== 200 || !Array.isArray(result.data)) {
            throw new Error('Data is not an array');
        }

        return result.data; // Return the array of companies
    } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
    }
};

export const fetchPartner = async () => {
    try {
        const response = await fetch(API_URL_P);
        if (!response.ok) throw new Error('Failed to fetch companies');
        const result = await response.json();

        // Check if result is an array or an object
        if (!Array.isArray(result) && typeof result !== 'object') {
            throw new Error('Data is neither an array nor an object');
        }

        // If it's an object, convert it to an array
        let companies = Array.isArray(result) ? result : Object.values(result);

        return companies;
    } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
    }
};


// Fetch a particular company by ID
export const fetchCompanyById = async (id) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) throw new Error('Failed to fetch company');
        return await response.json();
    } catch (error) {
        console.error('Error fetching company:', error);
        throw error;
    }
};
export const fetchPartnerById = async (id) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) throw new Error('Failed to fetch company');
        return await response.json();
    } catch (error) {
        console.error('Error fetching company:', error);
        throw error;
    }
};

// Add a new company
export const addCompany = async (companyData) => {
    try {
        const response = await fetch(API_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(companyData)
        });
        if (!response.ok) throw new Error('Failed to add company');
        return await response.json();
    } catch (error) {
        console.error('Error adding company:', error);
        throw error;
    }
};
export const addPartner = async (companyData) => {
    try {
        const response = await fetch(API_URL_P, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(companyData)
        });
        if (!response.ok) throw new Error('Failed to add company');
        return await response.json();
    } catch (error) {
        console.error('Error adding company:', error);
        throw error;
    }
};

// Update a company by ID
export const updateCompany = async (id, companyData) => {
    try {
        const response = await fetch(`${API_URL}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(companyData)
        });
        if (!response.ok) throw new Error('Failed to update company');
        return await response.json();
    } catch (error) {
        console.error('Error updating company:', error);
        throw error;
    }
};

// Delete a company by ID
export const deleteCompany = async (id) => {
    try {
        const response = await fetch(`${API_URL}/${id}`, {
            method: 'DELETE'
        });
        if (!response.ok) throw new Error('Failed to delete company');
        return await response.json();
    } catch (error) {
        console.error('Error deleting company:', error);
        throw error;
    }
};

// Soft delete a school
export const softDeleteCompany = async (id) => {
    try {
        const response = await fetch(`${API_URL}/${id}`, {
            method: 'PATCH', // Use PATCH for updates
            headers: { 'Content-Type': 'application/json' }
        });
        if (!response.ok) throw new Error('Failed to soft delete company');
        return await response.json();
    } catch (error) {
        console.error('Error soft deleting school:', error);
        throw error;
    }
};

export const fetchTeachers = async () => {
    try {
        const response = await fetch(API_URL);
        if (!response.ok) throw new Error('Failed to fetch companies');
        const result = await response.json();

        // Check if result.data is an array
        if (result.status !== 200 || !Array.isArray(result.data)) {
            throw new Error('Data is not an array');
        }

        return result.data; // Return the array of companies
    } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
    }
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditAssessment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [assessment, setAssessment] = useState({
    assessment_title: "",
    unit: "",
    total_score: "",
    expire_time: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [units, setUnits] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(true);

  const fetchAssessment = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAssessment(response.data.data);
        // setAssessment.unit=response.data.data.unit.unit_name;
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching assessment details");
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchAssessment();
  // }, [id]);

  const fetchUnits = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUnits(response.data.data);
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching units");
    } finally {
      setUnitsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessment();
    fetchUnits();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssessment((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${id}`,
          assessment,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Assessment updated successfully");
        setSnackbarType("success");
        setSnackbarOpen(true);
        navigate("/partner/assessment_list"); // Redirect to assessment list
      }
    } catch (err) {
      setSnackbarMessage("Error updating assessment");
      setSnackbarType("error");
      setSnackbarOpen(true);
    }
  };
  const handleCancel = () => {
    // Navigate to the assessment list page without saving
    navigate("/partner/assessment_list", {
      state: { snackbarMessage: "Changes discarded." },
    });
  };

  const filteredUnits = units.filter((unit) => unit._id === assessment.unit); // Filter units based on assessment's unit ID
  return (
    <Container maxWidth="100%" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Edit Assessment
      </Typography>

      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="assessment_title"
                label="Assessment Title"
                variant="outlined"
                value={assessment.assessment_title}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel sx={{ backgroundColor: "#fff" }}>Unit</InputLabel>
                <Select
                  name="unit"
                  value={assessment.unit._id}
                  onChange={handleChange}
                >
                  {units.map((unit) => (
                    <MenuItem
                      key={unit._id}
                      value={unit._id}
                    >
                      {unit.unit_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="total_score"
                label="Total Score"
                type="number"
                variant="outlined"
                value={assessment.total_score}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="expire_time"
                label="Expire Time (ms)"
                type="number"
                variant="outlined"
                value={assessment.expire_time}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                sx={{ marginTop: 2, backgroundColor: "#e0e0e0" }}
              >
                Update Assessment
              </Button>
              <Button
                onClick={handleCancel}
                variant="outlined"
                color="secondary"
                sx={{ marginTop: 2, marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditAssessment;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import { Container } from "@mui/system";

function EditCourse({ open, onClose, categoryId, onUpdate }) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });

  // Fetch the school Subcategory data by ID
  useEffect(() => {
    const fetchData = async () => {
      if (categoryId) {
        setLoading(true);
        try {
          const token = getToken();
          if (token) {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/courses_category/${categoryId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setName(response.data.data.courses_name); // Update state with fetched name
          }
        } catch (error) {
          setSnackbar({ open: true, message: "Failed to fetch category data", severity: "error" });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [categoryId]);

  // Update the school Subcategory
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const updateData = { courses_name: name };
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/courses_category/${categoryId}`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbar({ open: true, message: "Category updated successfully", severity: "success" });
        // Optionally delay closing dialog

        setTimeout(() => {
          onUpdate();
          onClose();
        }, 1000); // Delay closing the dialog
      }
    } catch (err) {
      console.error(err);
      setSnackbar({ open: true, message: err.response?.data?.message || "Failed to update category", severity: "error" });
    } finally {
      setLoading(false);
    }
  };
  

  // Handle snackbar close event
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={loading} // Disable input while loading
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            disabled={loading}
            variant="contained">
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default EditCourse;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Container,
  TablePagination,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material";

import { getToken } from "../../../utils/AuthUtils";

const Parents = () => {
  const [parents, setParents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of parents
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchParents = async () => {
      setIsLoading(true);
      setError(""); // Reset error state
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/parent`,
          {
            params: {
              page: page + 1, // Backend expects 1-based page index
              limit: rowsPerPage,
              searchTerm: searchTerm,
              selectedCategory, // Passing selected school category
              schoolSubCategory: selectedSubCategory, // Passing selected school
              selectedSchool,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
          }
        );
        setParents(response.data.data); // Access the 'data' field from the response
        setTotalItems(response.data.pagination.totalItems); // Set the total number of items for pagination
      } catch (err) {
        setError("Error fetching parents");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchParents();
    fetchCourseCategories();
    fetchschoolSubCategories();
  }, [
    page,
    rowsPerPage,
    searchTerm,
    selectedCategory,
    selectedSubCategory,
    selectedSchool,
  ]); // Fetch data when page or rowsPerPage changes

  // Fetch Course Categories
  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchschoolSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category/getsubcat/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolSubCategories(response.data.data || []);
        // get list of filter units
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/parent/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setParents(subcategoryResponse1.data.data);
        console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/getschool/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSchools(subcategoryResponse4.data.data);
        console.log("get school :", subcategoryResponse4.data.data);

        // get list of filter units
        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/parent/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setParents(subcategoryResponse2.data.data);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }
      if (selectedSchool) {
        // get list of filter units
        const subcategoryResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/parent/${selectedCategory}/${selectedSubCategory}/${selectedSchool}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setParents(subcategoryResponse9.data.data);
        console.log(
          "filtered teachers with school:",
          subcategoryResponse9.data.data
        );
      }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };
  // Handle Category Change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  // Handle Subcategory Change
  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  // Handle Search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage); // Update page state
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Update rowsPerPage state
    setPage(0); // Reset to first page
  };

  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0", mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Parent List
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>School Category</InputLabel>
          <Select
            value={selectedCategory || ""}
            label="School Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="">All Categories</MenuItem>
            {schoolCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.school_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>School Subcategory</InputLabel>
          <Select
            value={selectedSubCategory || ""}
            label="Subcategory"
            onChange={handleSubCategoryChange}
          >
            <MenuItem value="">All Subcategories</MenuItem>
            {schoolSubCategories.map((subCategory) => (
              <MenuItem key={subCategory._id} value={subCategory._id}>
                {subCategory.SubCategory_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>School</InputLabel>
          <Select
            value={selectedSchool || ""}
            label="School"
            onChange={handleSchoolChange}
          >
            <MenuItem value="">All School</MenuItem>
            {schools.map((school) => (
              <MenuItem key={school._id} value={school._id}>
                {school.school_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Relation to Student</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parents.length > 0 ? (
                parents.map((parent, index) => (
                  <TableRow key={parent._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{parent.parents_name}</TableCell>
                    <TableCell>{parent.p_email}</TableCell>
                    <TableCell>{parent.contact_num}</TableCell>
                    <TableCell>{parent.relation_to_student}</TableCell>
                    <TableCell>
                      {parent.status ? "Active" : "Inactive"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No parents found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Table Pagination */}
      {totalItems > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 75, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Container>
  );
};

export default Parents;

import React, { useState, useEffect } from "react";

import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  Box,
} from "@mui/material";

import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";

import { getToken } from "../../utils/AuthUtils";

const EditAssignedCourse = () => {
  const { id: courseId } = useParams();

  const [partnerCategoryId, setpartnerCategoryId] = useState("");

  const [partnerSubCategoryId, setpartnerSubCategoryId] = useState("");

  const [partnerId, setpartnerId] = useState("");

  const [courseCategoryId, setCourseCategoryId] = useState("");

  const [courseSubCategoryId, setCourseSubCategoryId] = useState("");

  const [selectedCourse, setSelectedCourse] = useState("");

  const [loading, setLoading] = useState(true);

  const [success, setSuccess] = useState(null);

  const [error, setError] = useState(null);

  const [partnerCategories, setpartnerCategories] = useState([]);

  const [partnerSubCategories, setpartnerSubCategories] = useState([]);

  const [partners, setpartners] = useState([]);

  const [courseCategories, setCourseCategories] = useState([]);

  const [courseSubCategories, setCourseSubCategories] = useState([]);

  const [courses, setCourses] = useState([]);

  // Filtered states

  const [filteredpartnerSubCategories, setFilteredpartnerSubCategories] =
    useState([]);

  const [filteredpartners, setFilteredpartners] = useState([]);

  const [filteredCourseSubCategories, setFilteredCourseSubCategories] =
    useState([]);

  const [filteredCourses, setFilteredCourses] = useState([]);

  const token = getToken();

  const navigate = useNavigate();

  const handleCancel = () => {
    // Reset the form fields

    setpartnerCategoryId("");

    setpartnerSubCategoryId("");

    setpartnerId("");

    setCourseCategoryId("");

    setCourseSubCategoryId("");

    setSelectedCourse("");

    // Optionally, navigate the user to the previous page or list page

    navigate("/assign_courses_to_partner_list");
  };

  // Fetch initial partner and course categories

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);

      try {
        const token = getToken();

        if (token) {
          const [partnerCategoriesResponse, courseCategoriesResponse] =
            await Promise.all([
              axios.get(
                `${process.env.REACT_APP_BACKEND}/api/partner/category/nopagination`,

                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),

              axios.get(
                `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,

                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
            ]);

          setpartnerCategories(partnerCategoriesResponse.data.data);

          setCourseCategories(
            courseCategoriesResponse.data.data.filter(
              (category) => category.status === 1
            )
          );
        }
      } catch (error) {
        setError("Failed to fetch categories. Please try again later.");

        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  // Fetch existing assignment details using course_id

  useEffect(() => {
    const fetchAssignedCourseDetails = async () => {
      setLoading(true);

      try {
        const token = getToken();

        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/${courseId}`,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = response.data.data;

          // Populate state with fetched data

          setpartnerCategoryId(data.partner_category_id);

          setpartnerSubCategoryId(data.partner_sub_category_id);

          setpartnerId(data.partner_id);

          setCourseCategoryId(data.course_category_id);

          setCourseSubCategoryId(data.course_sub_category_id);

          setSelectedCourse(data.course_id); // Ensure selectedCourse is a string
        }
      } catch (error) {
        setError("Failed to fetch assigned course details.");

        console.error("Error fetching assigned course details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignedCourseDetails();
  }, [courseId]);

  useEffect(() => {
    console.log("Filtered courses:", filteredCourses);

    console.log("Updated selectedCourse:", selectedCourse);
  }, [filteredCourses, selectedCourse]);

  // Fetch partner subcategories and partners based on selections

  useEffect(() => {
    const fetchDataBasedOnSelections = async () => {
      setLoading(true);

      try {
        const token = getToken();

        if (token) {
          if (partnerCategoryId) {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/partner/sub_category/bycat/nopagination`,

              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },

                params: { categoryId: partnerCategoryId },
              }
            );

            setpartnerSubCategories(data.data);
          } else {
            setpartnerSubCategories([]);
          }

          if (partnerSubCategoryId) {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/partner`,

              {
                params: { Partner_sub_Category_id: partnerSubCategoryId },

                headers: { Authorization: `Bearer ${token}` },
              }
            );

            setpartners(data.data);
          } else {
            setpartners([]);
          }
        }
      } catch (error) {
        setError("Failed to fetch dependent data. Please try again.");

        console.error("Error fetching dependent data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataBasedOnSelections();
  }, [partnerCategoryId, partnerSubCategoryId, token]);

  // Fetch course subcategories and courses based on selections

  useEffect(() => {
    const fetchCourseDataBasedOnSelections = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          if (courseCategoryId) {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/courses_category_sub`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                params: { category_id: courseCategoryId },
              }
            );
            setCourseSubCategories(
              data.data.filter((subCategory) => subCategory.status === 1)
            );
          } else {
            setCourseSubCategories([]);
          }

          if (courseSubCategoryId) {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/courses/with_out_pagination`,
              {
                params: { subcategory_id: courseSubCategoryId },
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            // Log the data from the response
            console.log("Courses data received:", data.data);

            const filteredCourses = data.data.filter(
              (course) => course.status === 1
            );
            setCourses(filteredCourses);
          } else {
            setCourses([]);
          }
        }
      } catch (error) {
        setError("Failed to fetch course data. Please try again.");
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDataBasedOnSelections();
  }, [courseCategoryId, courseSubCategoryId]);

  useEffect(() => {
    console.log("courseSubCategoryId:", courseSubCategoryId);
    console.log("Courses fetched:", courses);

    if (courseSubCategoryId && courses.length > 0) {
      // Check the type of both values
      console.log(
        "Comparing course_subcategory_id (string) and courseSubCategoryId (string)"
      );

      const filtered = courses.filter((course) => {
        const courseSubCategoryIdString = String(course.course_subcategory_id);
        const selectedSubCategoryIdString = String(courseSubCategoryId);

        console.log(
          "Comparing:",
          courseSubCategoryIdString,
          selectedSubCategoryIdString
        );

        return courseSubCategoryIdString === selectedSubCategoryIdString;
      });

      console.log("Filtered Courses:", filtered);

      setFilteredCourses(filtered);
    } else {
      console.log(
        "Either courseSubCategoryId is missing or courses array is empty"
      );
    }
  }, [courseSubCategoryId, courses]); // Ensure this dependency array is correct

  // Filter subcategories based on the selected partner category

  useEffect(() => {
    console.log("Current partner subcategories:", partnerSubCategories);

    console.log("Current partner category ID:", partnerCategoryId);

    if (partnerCategoryId) {
      const filtered = partnerSubCategories.filter(
        (subcategory) => subcategory.partner_id === partnerCategoryId
      );

      setFilteredpartnerSubCategories(filtered);

      console.log("filtered subpartner:", filteredpartnerSubCategories);
    }
  }, [partnerCategoryId, partnerSubCategories]);

  // Filter partner based on the selected subcategories category

  useEffect(() => {
    console.log("Current partnerSubCategoryId:", partnerSubCategoryId);

    console.log("Current partners:", partners);

    if (partnerSubCategoryId) {
      const filtered1 = partners.filter(
        (partner) =>
          partner.Partner_sub_Category_id === String(partnerSubCategoryId)
      );

      setFilteredpartners(filtered1);

      console.log("Filtered partners:", filteredpartners);
    }
  }, [partnerSubCategoryId, partners]);

  // Filter subcategories based on the selected course category

  useEffect(() => {
    if (courseCategoryId) {
      const filtered = courseSubCategories.filter(
        (subcategory) => subcategory.course_category_id === courseCategoryId
      );

      setFilteredCourseSubCategories(filtered);
    }
  }, [courseCategoryId, courseSubCategories]);

  // Filter courses based on the selected course Subcategory

  useEffect(() => {
    console.log("courseSubCategoryId:", courseSubCategoryId);
    console.log("courses:", courses);

    if (courseSubCategoryId && courses.length > 0) {
      const filtered = courses.filter(
        (course) =>
          String(course.course_subcategory_id) === String(courseSubCategoryId)
      );

      console.log("Filtered Courses:", filtered); // Log the filtered courses

      setFilteredCourses(filtered);
    }
  }, [courseSubCategoryId, courses]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !partnerCategoryId ||
      !partnerSubCategoryId ||
      !partnerId ||
      !courseCategoryId ||
      !courseSubCategoryId ||
      !selectedCourse
    ) {
      setError("Please fill out all required fields.");

      return;
    }

    const assignData = {
      partner_category_id: partnerCategoryId,

      partner_sub_category_id: partnerSubCategoryId,

      partner_id: partnerId,

      course_category_id: courseCategoryId,

      course_sub_category_id: courseSubCategoryId,

      course_id: selectedCourse,
    };

    setLoading(true);

    try {
      const token = getToken();

      if (token) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/${courseId}`,

          assignData,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data) {
          setSuccess("Course updated successfully!");

          setError(null);

          // Reset fields after successful submission

          setpartnerCategoryId("");

          setpartnerSubCategoryId("");

          setpartnerId("");

          setCourseCategoryId("");

          setCourseSubCategoryId("");

          setSelectedCourse(""); // Reset selected course

          if (response.status === 200) {
            // Store success message in sessionStorage

            sessionStorage.setItem(
              "assignSuccessMessage",

              "Course assignment to partner updated successfully"
            );

            // Redirect to Labnames list

            navigate("/assign_courses_to_partner_list");
          }

          // Redirect to the list page

          // navigate('/assign_courses_to_partner_list');
        } else {
          setError(response.data.message || "Failed to update course.");
        }
      }
    } catch (error) {
      setError("Failed to update course. Please try again.");

      console.error("Error updating course:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(null);

    setError(null);
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={3}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Edit Assigned Course</Typography>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Alert severity="error" sx={{ marginBottom: "20px" }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select partner Category"
                  value={partnerCategoryId}
                  onChange={(e) => {
                    setpartnerCategoryId(e.target.value);

                    setpartnerSubCategoryId(""); // Reset dependent state

                    setpartners([]); // Reset partners
                  }}
                >
                  {partnerCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.partner_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select partner Subcategory"
                  value={partnerSubCategoryId}
                  onChange={(e) => {
                    setpartnerSubCategoryId(e.target.value);

                    setpartners([]); // Reset partners
                  }}
                >
                  {filteredpartnerSubCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.SubCategory_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select partner"
                  value={partnerId}
                  onChange={(e) => setpartnerId(e.target.value)}
                >
                  {filteredpartners.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.partner_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select Course Category"
                  value={courseCategoryId}
                  onChange={(e) => {
                    setCourseCategoryId(e.target.value);

                    setCourseSubCategoryId(""); // Reset dependent state

                    setCourses([]); // Reset courses
                  }}
                >
                  {courseCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.courses_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select Course Subcategory"
                  value={courseSubCategoryId}
                  onChange={(e) => {
                    setCourseSubCategoryId(e.target.value);

                    setCourses([]); // Reset courses
                  }}
                >
                  {filteredCourseSubCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.courses_sub_category_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Select Course"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)} // Single course selection
                >
                  {filteredCourses.length > 0 ? (
                    filteredCourses.map((course) => (
                      <MenuItem key={course._id} value={course._id}>
                        {course.courses_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No courses available</MenuItem>
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  onClick={handleCancel}
                  sx={{ marginRight: "10px",}}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" sx={{ backgroundColor: "#e0e0e0" }}>
                  Update Course
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <Snackbar
        open={success !== null || error !== null}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={success ? "success" : "error"}
        >
          {success || error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditAssignedCourse;

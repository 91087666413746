import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  TablePagination,
  DialogContentText,
  DialogTitle,
  Switch,
  Snackbar,
  Alert,
  Container,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/system";
import { getToken } from "../../utils/AuthUtils";

const SliderListPage = () => {
  const [sliders, setSliders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [sliderToDelete, setSliderToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check for snackbar message in the location state and set it
    if (location.state?.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarOpen(true);

      // Clear the location state snackbar message after it has been set
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  const fetchSliders = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/slider`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSliders(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      setError("Error fetching sliders");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchSliders();
    }, 300);
    return () => clearTimeout(handler);
  }, [searchTerm, page, rowsPerPage]);

  const handleAddSlider = () => navigate("/sliders/add");
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };
  const handleEditSlider = (id) => navigate(`/sliders/edit/${id}`);

  const handleStatusToggle = async (id, currentStatus) => {
    const newStatus = !currentStatus;
    setSliders((prev) =>
      prev.map((slider) =>
        slider._id === id ? { ...slider, status: newStatus } : slider
      )
    );
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/slider/${id}/status_slider`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Slider status updated successfully");
        setSnackbarOpen(true);
      }
    } catch {
      setSliders((prev) =>
        prev.map((slider) =>
          slider._id === id ? { ...slider, status: currentStatus } : slider
        )
      );
      setSnackbarMessage("Failed to update slider status");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteConfirmation = (slider) => {
    setSliderToDelete(slider);
    setOpenDialog(true);
  };

  const handleDeleteSlider = async () => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/slider/delete/${sliderToDelete._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOpenDialog(false);
        fetchSliders();
        setSnackbarMessage("Slider deleted successfully");
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage("Error deleting slider");
      setSnackbarOpen(true);
      setSnackbarType("error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const truncateUrl = (url) => {
    const maxLength = 30; // Maximum length for the URL display
    if (url.length > maxLength) {
      return `${url.substring(0, maxLength)}...`;
    }
    return url;
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Sliders</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddSlider}
          sx={{ backgroundColor: "#e0e0e0" }}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20px" }}> No.</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Redirected URL</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliders.length > 0 ? (
                // Filter sliders based on searchTerm
                sliders
                  .filter(
                    (slider) =>
                      slider?.title
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      slider?.redirected_url
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      new Date(slider?.start_date)
                        .toLocaleDateString()
                        .includes(searchTerm.toLowerCase()) ||
                      new Date(slider?.end_date)
                        .toLocaleDateString()
                        .includes(searchTerm.toLowerCase())
                  )
                  .map((slider, index) => (
                    <TableRow key={slider._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{slider.title}</TableCell>
                      <TableCell>
                        <a
                          href={slider.image_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={slider.image_url}
                            alt={slider.title}
                            style={{ width: "100px", height: "auto" }}
                          />
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          href={slider.redirected_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {truncateUrl(slider.redirected_url)}
                        </a>
                      </TableCell>
                      <TableCell>
                        {new Date(slider.start_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {new Date(slider.end_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {new Date(slider?.createdAt).toLocaleDateString()}{" "}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center !important" }}>
                        <Switch
                          checked={slider.status}
                          onChange={() =>
                            handleStatusToggle(slider._id, slider.status)
                          }
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center" // Optional, aligns buttons horizontally in the center
                        >
                          <IconButton
                            color="primary"
                            onClick={() => handleEditSlider(slider._id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteConfirmation(slider)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                // Check if no sliders match the search term
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {"No Data Found"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems > 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the slider "{sliderToDelete?.title}
            "?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteSlider}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SliderListPage;

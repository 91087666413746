import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Container,
  Typography,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const SchoolList = () => {
  const [schools, setSchools] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  // Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const [filter, setFilter] = useState({
    schoolCategory: "",
    schoolSubCategory: "",
    countryId: "",
    stateId: "",
    districtId: "",
    partnerId: "",
  });

  const [filteredSchools, setFilteredSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [noSchoolsFound, setNoSchoolsFound] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [canCreate, setCanCreate] = useState(false); // Toggle between showing cropper and input
  const [canView, setCanView] = useState(false); // Toggle between showing cropper and input
  const [canUpdate, setCanUpdate] = useState(false); // Toggle between showing cropper and input
  const [canDelete, setCanDelete] = useState(false); // Toggle between showing cropper and input
  const navigate = useNavigate();

  // Update the schoolsToDisplay definition in your component
  // const schoolsToDisplay =
  //   filteredSchools.length > 0
  //     ? filteredSchools.filter(
  //         (school) =>
  //           school.school_name
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase()) ||
  //           school.school_code
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase()) ||
  //           school.principal_name
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase()) ||
  //           school.school_address
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase())
  //       )
  //     : schools.filter(
  //         (school) =>
  //           school.school_name
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase()) ||
  //           school.school_code
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase()) ||
  //           school.principal_name
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase()) ||
  //           school.school_address
  //             .toLowerCase()
  //             .includes(searchTerm.toLowerCase())
  //       );

        useEffect(() => {
          const fetchPermissions = async () => {
            const token = localStorage.getItem('token_key');
            
            if (token) {
              try {
                const roleResponse = await axios.get(
                  `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions/1`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                );
                
                const rolePermissions = roleResponse.data.data;
                
                // Check role permissions or set the role
                if (rolePermissions ) {
                  // navigate("/unauthorized");
                  // console.log("You are not authorized to create a school",rolePermissions);
                  setCanCreate(rolePermissions.create);
                  setCanView(rolePermissions.view);
                  setCanUpdate(rolePermissions.update);
                  setCanDelete(rolePermissions.delete);
                }
              } catch (error) {
                console.error("Error fetching role permissions:", error);
                // navigate("/unauthorized"); // Redirect in case of error
              }
            }
          };
      
          fetchPermissions();
        }, []);
  // fetch country
  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch countries");
    }
  };

  const fetchCountryStateDistrict = async () => {
    if (!selectedCountry) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/without_pagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
        // get list of filter units
        // const school1 = await axios.get(
        //   `${process.env.REACT_APP_BACKEND}/api/school/getcountry/${selectedCountry}`,
        //   {
        //     headers: { Authorization: `Bearer ${token}` },
        //   }
        // );
        // setSchools(school1.data.data);
        // console.log("filtered school:", school1.data.data);
      }

      if (selectedState) {
        const response77 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/without_pagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response77.data.data);
        console.log("get state :", response77.data.data);

        // // get list of filter units
        // const subcategoryResponse20 = await axios.get(
        //   `${process.env.REACT_APP_BACKEND}/api/school/getstate/${selectedCountry}/${selectedState}`,
        //   {
        //     headers: { Authorization: `Bearer ${token}` },
        //   }
        // );
        // setSchools(subcategoryResponse20.data.data);
        // console.log("filtered school:", subcategoryResponse20.data.data);
      }
      // if (selectedDistrict) {
      //   // get list of filter units
      //   // get list of filter units
      //   const subcategoryResponse20 = await axios.get(
      //     `${process.env.REACT_APP_BACKEND}/api/school/getdistrict/${selectedCountry}/${selectedState}/${selectedDistrict}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   );
      //   setSchools(subcategoryResponse20.data.data);
      //   console.log("filtered school:", subcategoryResponse20.data.data);
      // }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };

  // Fetch partners
  const fetchPartners = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPartners(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch partner");
    }
  };

  useEffect(() => {
    fetchSchoolsIfPartner();
  }, [selectedPartner]);

  const fetchSchoolsIfPartner = async () => {
    if (!selectedPartner) {
      return;
    }
    try {
      const token = getToken();
      if (token) {
        const partnerschool = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/filterpartner/${selectedPartner}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSchools(partnerschool.data.data);
        console.log("filtered school:", partnerschool.data.data);
      }
    } catch (error) {
      setError("Failed to fetch school ");
    }
  };

  const fetchSchools = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/current-user`,
          {
            params: {
              page: page + 1, // Assuming the API uses 1-based index
              limit: rowsPerPage,
              searchTerm: searchTerm,
              selectedCategory, // Passing selected school category
              schoolSubCategory: selectedSubCategory, // Passing selected school
              selectedSchool,
              selectedCountry,
              selectedState,
              selectedDistrict
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchools(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } else {
        setError("Unauthorized");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setError("Error fetching schools");
      setOpenSnackbar(true);
    }
  };
  useEffect(() => {
    fetchSchools();
    fetchCountries();
    fetchCourseCategories();
    fetchPartners();
  }, [
    page,
    rowsPerPage,
    searchTerm,
    filteredSchools,
    selectedCategory,
    selectedSubCategory,
    selectedSchool,
    selectedCountry,
    selectedState,
    selectedDistrict
  ]);

  useEffect(() => {
    fetchCountryStateDistrict();
  }, [selectedCountry, selectedState, selectedDistrict]);

  // Fetch Course Categories
  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };
  useEffect(() => {
    fetchschoolSubCategories();
  }, [selectedCategory, selectedSubCategory]);

  const fetchschoolSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category/getsubcat/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolSubCategories(response.data.data || []);
        // get list of filter units
        // const subcategoryResponse1 = await axios.get(
        //   `${process.env.REACT_APP_BACKEND}/api/school/schoolget/current-user/${selectedCategory}`,
        //   {
        //     headers: { Authorization: `Bearer ${token}` },
        //   }
        // );
        // setSchools(subcategoryResponse1.data.data);
        // console.log("filtered units:", subcategoryResponse1.data.data);
      }

      // if (selectedSubCategory) {
      //   // get list of filter units
      //   const subcategoryResponse2 = await axios.get(
      //     `${process.env.REACT_APP_BACKEND}/api/school/schoolget/current-user/${selectedCategory}/${selectedSubCategory}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   );
      //   setSchools(subcategoryResponse2.data.data);
      //   console.log("filtered units:", subcategoryResponse2.data.data);
      // }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedSchoolId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteSchool = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/school/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { ...filter, searchTerm },
          }
        );
        setOpenDeleteDialog(false);
        fetchSchools();
      }
    } catch (err) {
      setError("Failed to delete school");
      setOpenSnackbar(true);
    }
  };

  const handleNavigateToAddPage = () => {
    navigate("/add-school");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateToEditPage = (id) => {
    navigate(`/edit-school/${id}`);
  };

  const handleNavigateToViewPage = (id) => {
    navigate(`/view-school/${id}`);
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  // Handle partner Change
  const handlePartnerChange = (event) => {
    setSelectedPartner(event.target.value);
    setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle category Change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    setSelectedPartner("");
    setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">School Listing</Typography>

        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <Grid container spacing={2}>
          {/* Category */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  schoolCategories.find(
                    (category) => category._id === selectedCategory
                  ) || null
                } // Find the selected category by its ID
                onChange={(e, newValue) => {
                  setSelectedCategory(newValue ? newValue._id : ""); 
                  setSelectedSubCategory('')
                }}
                options={schoolCategories.sort((a, b) =>
                  a.school_name.localeCompare(b.school_name)
                )} // Sort categories A to Z by school_name
                getOptionLabel={(option) => option.school_name} // Display the school_name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by category ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School Category"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
              />
            </FormControl>
          </Grid>
          {selectedCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    schoolSubCategories.find(
                      (subCategory) => subCategory._id === selectedSubCategory
                    ) || null
                  } // Find the selected subcategory by its ID
                  onChange={(e, newValue) => {
                    setSelectedSubCategory(newValue ? newValue._id : ""); // Set selectedSubCategory to the ID of the selected subcategory
                  }}
                  options={schoolSubCategories.sort((a, b) =>
                    a.SubCategory_name.localeCompare(b.SubCategory_name)
                  )} // Sort subcategories A to Z by SubCategory_name
                  getOptionLabel={(option) => option.SubCategory_name} // Display the SubCategory_name in the dropdown
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Match by subcategory ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School Subcategory"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  disableClearable={false} // Allow clearing the selection
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  countries.find(
                    (country) => country._id === selectedCountry
                  ) || null
                } // Find the selected country by ID
                onChange={(e, newValue) => {
                  setSelectedCountry(newValue ? newValue._id : ""); // Set selectedCountry to the ID of the selected country
                  setSelectedState(""); // Reset state when country changes
                  setSelectedDistrict(""); // Reset district when country changes
                  fetchSchools();
                }}
                options={countries.sort((a, b) =>
                  a.country_name.localeCompare(b.country_name)
                )} // Sort countries A to Z
                getOptionLabel={(option) => option.country_name} // Display country_name for each option
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by country ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
              />
            </FormControl>
          </Grid>
          {selectedCountry && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    states.find((state) => state._id === selectedState) || null
                  } // Find the selected state by ID
                  onChange={(e, newValue) => {
                    setSelectedState(newValue ? newValue._id : ""); // Set selectedState to the ID of the selected state
                    setSelectedDistrict(""); // Reset district when country changes
                  }}
                  options={states
                    .filter((state) => state.country === selectedCountry) // Filter states by selected country
                    .sort((a, b) => a.state_name.localeCompare(b.state_name))} // Sort states A to Z
                  getOptionLabel={(option) => option.state_name} // Display state_name for each option
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Match by state ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  disableClearable={false} // Allow clearing the selection
                />
              </FormControl>
            </Grid>
          )}
          {selectedState && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    districts.find(
                      (district) => district._id === selectedDistrict
                    ) || null
                  } // Find the selected district by ID
                  onChange={(e, newValue) => {
                    setSelectedDistrict(newValue ? newValue._id : ""); // Set selectedDistrict to the ID of the selected district
                  }}
                  options={districts
                    .filter((district) => district.state === selectedState) // Filter districts by selected state
                    .sort((a, b) =>
                      a.district_name.localeCompare(b.district_name, "en", {
                        sensitivity: "base",
                      })
                    )} // Sort districts A to Z
                  getOptionLabel={(option) => option.district_name} // Display district_name for each option
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Match by district ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="District"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  disableClearable={false} // Allow clearing the selection
                />
              </FormControl>
            </Grid>
          )}
          {/* Button */}
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="center"
            sx={{ order: 2, marginLeft: "auto" }}>
              {canCreate === 1 && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleNavigateToAddPage}
              sx={{
                backgroundColor: "#e0e0e0",
                height: "40px",
                marginLeft: "30px",
              }}>
              Create
            </Button>
             )}
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell> ID</TableCell>
              <TableCell> Logo</TableCell>
              {/* <TableCell>Principal Photo</TableCell> */}
              <TableCell> Name</TableCell>
              <TableCell>Partner ID</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Sub Category</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schools.length > 0 ? (
              schools.map((school, index) => (
                <TableRow key={school._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{school.school_code}</TableCell>
                  {/* School Logo */}
                  <TableCell>
                    {school.school_logo ? (
                      <img
                        src={school.school_logo}
                        alt="School Logo"
                        style={{ width: 50, height: 50, objectFit: "cover" }}
                      />
                    ) : (
                      "No Logo"
                    )}
                  </TableCell>

                  <TableCell>{school.school_name}</TableCell>
                  <TableCell>{school.partnerData?.partner_id}</TableCell>
                  <TableCell>{school.school_category?.school_name}</TableCell>
                  <TableCell>
                    {school.school_sub_category.SubCategory_name}
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center" // Optional, aligns buttons horizontally in the center
                    >
                      {canView === 1 && (
                      <IconButton
                        color="primary"
                        onClick={() => handleNavigateToViewPage(school._id)}>
                        <VisibilityIcon />
                      </IconButton>
                      )}
                      {canUpdate === 1 && (
                      <IconButton
                        color="primary"
                        onClick={() => handleNavigateToEditPage(school._id)}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {canDelete === 1 && (
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(school._id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No Data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems > 100 && (
        <Box display="flex" justifyContent="flex-end" padding={2}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[100, 150, 200, 250]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      {/* Delete Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title">
        <DialogTitle id="delete-dialog-title">Delete School</DialogTitle>
        <DialogContent>
          <Typography> Are you sure you want to delete this  school?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            onClick={() => handleDeleteSchool(selectedSchoolId)}
            color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SchoolList;

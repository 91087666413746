import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditLabname = () => {
  const { id } = useParams();
  const [labName, setLabName] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fetchDepartments = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDepartments(response.data.data);
      }
    } catch (err) {
      setError("Failed to fetch departments.");
      console.error(err);
    }
  };

  // const fetchLabname = async () => {
  //     try {
  //         const token = getToken();
  //         if (token) {
  //         const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/labnames/${id}`,{
  //             headers: {
  //                 Authorization: `Bearer ${token}`,
  //               },
  //         });
  //         const { lab_name, department } = response.data.data;
  //         setLabName(lab_name);
  //         setDepartmentId(department?._id || ''); // Ensure you are setting the department ID
  //     } }catch (err) {
  //         setError('Failed to fetch lab name.');
  //         console.error(err);
  //     } finally {
  //         setLoading(false);
  //     }
  // };
  // Fetch lab name
  const fetchLabname = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/labnames/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { lab_name, department } = response.data.data;
        setLabName(lab_name);
        setDepartmentId(department); // Set the department ID directly
      }
    } catch (err) {
      setError("Failed to fetch lab name.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchLabname();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = getToken();
      if (token) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/labnames/${id}`,
          {
            lab_name: labName,
            department: departmentId,
            // Uncomment if needed
            // status: true,
            // updated_by: null,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          // Store success message in sessionStorage and show Snackbar
          sessionStorage.setItem(
            "labnameSuccessMessage",
            "Lab name updated successfully"
          );
          // setSnackbarMessage("Lab name updated successfully");
          // setOpenSnackbar(true);

          navigate("/labnames");
        }
      }
    } catch (err) {
      setError("Error updating lab name");
      console.error(err);
    }
  };

  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Box padding={2}>
        <Typography variant="h4">Edit Lab Name</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Lab Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={labName}
            onChange={(e) => setLabName(e.target.value)}
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Department</InputLabel>
            <Select
              value={departmentId}
              onChange={(e) => setDepartmentId(e.target.value)}
              label="Department"
            >
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="" alignItems="center" mt={2}>
            <Button variant="contained" color="primary" type="submit">
              Update Lab Name
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              sx={{ ml: 2, backgroundColor: "#e0e0e0" }} // Adjust margin as needed
              onClick={handleCancel} // Cancel button to go back
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditLabname;

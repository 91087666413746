import React from 'react';
import { useNavigate } from 'react-router-dom';

function Dash() {
  const navigate = useNavigate();

  const handleNavigatepartner = () => {
    navigate('/category'); 
  };

  const handleNavigateSubpartner = () => {
    navigate('/sub_category'); 
  };
  
  const handleNavigateCourse = () => {
    navigate('/course'); 
  };
  return (
    <div>
      <h1>Dashboard</h1>
      <button onClick={handleNavigatepartner}>Go to Add Partner Page</button>
      <button onClick={handleNavigateSubpartner}>Go to Sub Partner Page</button>
      <button onClick={handleNavigateCourse}>Go to Course Page</button>

      
    </div>
  );
}

export default Dash;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Link,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

// const navigate = useNavigate();

const PdfList = () => {
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPdfs = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/unit/?page=${currentPage}&limit=${itemsPerPage}`);
        setPdfs(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
      } catch (err) {
        setError('Error fetching PDFs');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdfs();
  }, [currentPage, itemsPerPage]);
  const fetchPdfs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/unit/?page=${currentPage}&limit=${itemsPerPage}`);
      setPdfs(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
    } catch (err) {
      setError('Error fetching PDFs');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (unit_id,id) => {
    console.log(unit_id,id);
    
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/unitupload/deleteunit`,
        {
          unit_id: unit_id,
          id: id
        }
      );
      // setOpenDeleteDialog(false);
      // fetchPdfs();
    } catch (err) {
      setError('Failed to delete Unit');
      // setOpenSnackbar(true);
    }
  };
  const handleView = async (id) => {
    navigate(`/unitinterface/${id}`)
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1); // Adjust for zero-based indexing
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  return (
    <div>
      <h1>Unit List</h1>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Unit Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pdfs.length > 0 ? (
                  pdfs.map((pdf, index) => (
                    <TableRow key={pdf._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{pdf.unitDetails ? pdf.unitDetails.unit_name : 'N/A'}</TableCell>

                      <TableCell>
                      <IconButton
                      color="error"
                      onClick={() => handleView(pdf.units_id)}
                      // component={Link} to={`/unitinterface/${pdf.unitDetails._Id}`}
                    >
                      <VisibilityIcon />
                    </IconButton>
                      {/* <IconButton
                      color="error"
                      onClick={() => handleDelete(pdf.units_id,pdf._id)}
                    >
                      <DeleteIcon />
                    </IconButton> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No PDFs found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={itemsPerPage}
            page={currentPage - 1} // Adjust for zero-based indexing
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  );
};

export default PdfList;

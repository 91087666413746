import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

function EditStudent({ open, onClose, category, onUpdate }) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (open && category) {
      // Fetch student category data if category is provided
      const fetchCategoryData = async () => {
        try {
          const token = getToken();
          if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/student/category/${category._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("response", response.data.data);

          setName(response.data.data.Student_category_name);
        } }catch (err) {
          setError("Failed to fetch category data");
        }
      };
      fetchCategoryData();
    }
  }, [open, category]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/student/category/${category._id}`,
        { Student_category_name: name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      onUpdate(); // Callback to refresh the list
      onClose(); // Close the modal after successful update
    } }catch (err) {
      setError("Failed to update category");
    } finally {
      setLoading(false);
    }
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  return (
 <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit   Category</DialogTitle>
        <DialogContent>
          {error && (
            <Snackbar
              open={Boolean(error)}
              autoHideDuration={6000}
              onClose={() => setError(null)}
            >
              <Alert onClose={() => setError(null)} severity="error">
                {error}
              </Alert>
            </Snackbar>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Student Category Name"
            fullWidth
            variant="outlined"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
 </>
  );
}

export default EditStudent;

import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Snackbar, Alert } from '@mui/material';
import { getToken } from '../../../utils/AuthUtils';

const AddPartnerModal = ({ open, onClose, onAdd }) => {
  const [partnerName, setPartnerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  const handleChange = (event) => {
    setPartnerName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = getToken();
      if (token) {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/partner/category`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ partner_name: partnerName }),
      });

      if (response.ok) {
        setSnackbarMessage('Category added successfully');
        setSnackbarSeverity('success');
        onAdd(); // Notify parent to refresh data
        setPartnerName("")
        onClose(); // Close the modal
      } else {
        setSnackbarMessage('Failed to add partner category');
        setSnackbarSeverity('error');
      }
    }} catch (error) {
      setSnackbarMessage('Error occurred while adding partner category');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Partner Category Name"
              value={partnerName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Add'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddPartnerModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Container,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
// import { Select } from "antd";

const AssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [units, setUnits] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
// state defining for filter 
const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState(""); 
  const [courseCategories, setCourseCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [selectedCourses, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);


  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchCourseSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssessments(subcategoryResponse1.data.data);
        console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chapters/getcourseby/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCourses(subcategoryResponse4.data.data);
        console.log("get courses :", subcategoryResponse4.data.data);

        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssessments(subcategoryResponse2.data.data);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }
      
      if (selectedCourses) {

        const subcategoryResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${selectedCategory}/${selectedSubCategory}/${selectedCourses}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssessments(subcategoryResponse9.data.data);
        console.log(
          "filtered units with course:",
          subcategoryResponse9.data.data
        );
      }

    } catch (err) {
      setError("Failed to fetch chapters");
    }
  };
  
  const fetchAssessments = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
          {
            params: {  page: currentPage,
              limit: itemsPerPage,
              category, 
              subcategory,
               searchTerm, ...location.state },
          }
        );
        setAssessments(response.data.data || []);
        setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching assessments");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessments();
    fetchCourseCategories();
    fetchCourseSubCategories();
    // fetchUnits();
  }, [category, subcategory, searchTerm, selectedCategory, selectedSubCategory, selectedCourses,searchTerm, location.state]);

  const handleAddAssessment = () => navigate("/assessment/add");
  const handleEditAssessment = (id) =>
    navigate(`/partner/assessment/edit/${id}`);
  const handleViewQuestions = (id) =>
    navigate(`/partner/questions/assessment/${id}`);

  const handleDeleteConfirmation = (assessment) => {
    setAssessmentToDelete(assessment);
    setOpenDialog(true);
  };

  const handleDeleteAssessment = async () => {
    setIsDeleting(true);
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/assessment/delete/${assessmentToDelete._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOpenDialog(false);
        fetchAssessments();
        setSnackbarMessage("Assessment deleted successfully");
        setSnackbarType("success");
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage("Error deleting assessment");
      setSnackbarType("error");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); 
    setCurrentPage(0);
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  // const getUnitName = (unitId) => {
  //   const unit = units.find((u) => u._id === unitId); // Match unit by ID
  //   return unit ? unit?.unit_name : "Unknown Unit"; // Return unit name or fallback
  // };
  const getUnitName = (unit) => {
    if (unit) {
      return unit.unit_name || "Unknown Unit"; // Use the unit's name or fallback
    }
    return "No Unit"; // Handle the case where unit is null
  };
  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4" gutterBottom>
          Unit Assessment List
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "300px" }}
        />
         {/* Category Dropdown */}
         <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>course Category</InputLabel>
          <Select
            value={selectedCategory || ""}
            label="course Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="">All Course Categories</MenuItem>
            {courseCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.courses_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Subcategory Dropdown */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Course Subcategory</InputLabel>
          <Select
            value={selectedSubCategory || ""}
            label="Course Subcategory"
            onChange={handleSubCategoryChange}
          >
            <MenuItem value="">All Course Subcategories</MenuItem>
            {courseSubCategories.map((subCategory) => (
              <MenuItem key={subCategory._id} value={subCategory._id}>
                {subCategory.courses_sub_category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Courses Dropdown */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Courses</InputLabel>
          <Select
            value={selectedCourses || ""}
            label="Courses"
            onChange={handleCourseChange}
          >
            <MenuItem value="">All Courses</MenuItem>
            {courses.map((school) => (
              <MenuItem key={school._id} value={school._id}>
                {school.courses_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddAssessment}>
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>Serial No.</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Assessment Title
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Unit</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Total Score
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Expire Time (ms)
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    View Questions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessments.length > 0 ? (
                  assessments
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((assessment, index) => (
                      <TableRow key={assessment._id}>
                        <TableCell sx={{ textAlign: "center" }}>
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {assessment.assessment_title}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {assessment.units?.unit_name?assessment.units.unit_name:"N/A"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {assessment.total_score}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {assessment.expire_time}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleEditAssessment(assessment._id)
                            }>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() =>
                              handleDeleteConfirmation(assessment)
                            }>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="default"
                            onClick={() => handleViewQuestions(assessment._id)}>
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No assessments found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {totalItems >= 10 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
              showFirstButton
              showLastButton
            />
          )}
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the assessment "
            {assessmentToDelete?.assessment_title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAssessment}
            color="primary"
            variant="contained"
            disabled={isDeleting}>
            {isDeleting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssessmentList;

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditAssignedCourse = () => {
  const { id: courseId } = useParams();
  const [schoolCategoryId, setSchoolCategoryId] = useState("");
  const [schoolSubCategoryId, setSchoolSubCategoryId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [courseCategoryId, setCourseCategoryId] = useState("");
  const [courseSubCategoryId, setCourseSubCategoryId] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [schools, setSchools] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);

  // Filtered states
  const [filteredSchoolSubCategories, setFilteredSchoolSubCategories] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [filteredCourseSubCategories, setFilteredCourseSubCategories] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);

  const token = getToken();
  const navigate = useNavigate();

  // Fetch initial school and course categories
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
        const [schoolCategoriesResponse, courseCategoriesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        setSchoolCategories(schoolCategoriesResponse.data.data.filter(category => category.status === 1));
        setCourseCategories(courseCategoriesResponse.data.data.filter(category => category.status === 1));
      }} catch (error) {
        setError("Failed to fetch categories. Please try again later.");
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  // Fetch existing assignment details using course_id
  useEffect(() => {
    const fetchAssignedCourseDetails = async () => {
      setLoading(true);
      try {
        const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course/school/${courseId}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = response.data.data;

        // Populate state with fetched data
        setSchoolCategoryId(data.school_category_id);
        setSchoolSubCategoryId(data.school_sub_category_id);
        setSchoolId(data.school_id);
        setCourseCategoryId(data.course_category_id);
        setCourseSubCategoryId(data.course_sub_category_id);
        setSelectedCourse(data.course_id || ""); // Ensure selectedCourse is a string
      } }catch (error) {
        setError("Failed to fetch assigned course details.");
        console.error("Error fetching assigned course details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignedCourseDetails();
  }, [courseId]);

  // Fetch school subcategories and schools based on selections
  useEffect(() => {
    const fetchDataBasedOnSelections = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
        if (schoolCategoryId) {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`, {
            params: { category_id: schoolCategoryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setSchoolSubCategories(data.data.filter(subCategory => subCategory.status === 1));
        } else {
          setSchoolSubCategories([]);
        }

        if (schoolSubCategoryId) {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school`, {
            params: { subcategory_id: schoolSubCategoryId },
            headers: { Authorization: `Bearer ${token}` },
          });
          setSchools(data.data.filter(school => school.status === 1));
        } else {
          setSchools([]);
        }
      } }catch (error) {
        setError("Failed to fetch dependent data. Please try again.");
        console.error("Error fetching dependent data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataBasedOnSelections();
  }, [schoolCategoryId, schoolSubCategoryId, token]);

  // Fetch course subcategories and courses based on selections
  useEffect(() => {
    const fetchCourseDataBasedOnSelections = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
        if (courseCategoryId) {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`, {
            params: { category_id: courseCategoryId },
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setCourseSubCategories(data.data.filter(subCategory => subCategory.status === 1));
        } else {
          setCourseSubCategories([]);
        }

        if (courseSubCategoryId) {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
            params: { subcategory_id: courseSubCategoryId },
            headers: { Authorization: `Bearer ${token}` },
          });
          setCourses(data.data.filter(course => course.status === 1));
        } else {
          setCourses([]);
        }
      }} catch (error) {
        setError("Failed to fetch course data. Please try again.");
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDataBasedOnSelections();
  }, [courseCategoryId, courseSubCategoryId]);

  // Filter subcategories based on the selected school category
  useEffect(() => {
    if (schoolCategoryId) {
      const filtered = schoolSubCategories.filter(subcategory =>
        subcategory.School_category_id === schoolCategoryId
      );
      setFilteredSchoolSubCategories(filtered);
    }
  }, [schoolCategoryId, schoolSubCategories]);

  // Filter schools based on the selected school Subcategory
  useEffect(() => {
    if (schoolSubCategoryId) {
      const filtered = schools.filter(school =>
        school.school_sub_category === schoolSubCategoryId
      );
      setFilteredSchools(filtered);
    }
  }, [schoolSubCategoryId, schools]);

  // Filter subcategories based on the selected course category
  useEffect(() => {
    if (courseCategoryId) {
      const filtered = courseSubCategories.filter(subcategory =>
        subcategory.course_category_id === courseCategoryId
      );
      setFilteredCourseSubCategories(filtered);
    }
  }, [courseCategoryId, courseSubCategories]);

  // Filter courses based on the selected course Subcategory
  useEffect(() => {
    if (courseSubCategoryId) {
      const filtered = courses.filter(course =>
        course.course_subcategory_id === courseSubCategoryId
      );
      setFilteredCourses(filtered);
    }
  }, [courseSubCategoryId, courses]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!schoolCategoryId || !schoolSubCategoryId || !schoolId || !courseCategoryId || !courseSubCategoryId || !selectedCourse) {
      setError("Please fill out all required fields.");
      return;
    }

    const assignData = {
      school_category_id: schoolCategoryId,
      school_sub_category_id: schoolSubCategoryId,
      school_id: schoolId,
      course_category_id: courseCategoryId,
      course_sub_category_id: courseSubCategoryId,
      course_id: selectedCourse, // Single course
    };

    setLoading(true);

    try {
      const token = getToken();
      if (token) {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/assign_course/school/${courseId}`, assignData,{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.data) {
        // setSuccess("Course updated successfully!");
        // setError(null);

        // Reset fields after successful submission
        setSchoolCategoryId("");
        setSchoolSubCategoryId("");
        setSchoolId("");
        setCourseCategoryId("");
        setCourseSubCategoryId("");
        setSelectedCourse(""); // Reset selected course

        // Redirect to the list page
        navigate('/partner/assign_courses_list', {
          state: { snackbarMessage: 'assigned course updated successfully!' }
        });
      } else {
        setError(response.data.message || "Failed to update course.");
      }
    } }catch (error) {
      setError("Failed to update course. Please try again.");
      console.error("Error updating course:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };
  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" >
        Edit Assigned Course 
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Alert severity="error" sx={{ marginBottom: "20px" }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select School Category"
                  value={schoolCategoryId}
                  onChange={(e) => {
                    setSchoolCategoryId(e.target.value);
                    setSchoolSubCategoryId(""); // Reset dependent state
                    setSchools([]); // Reset schools
                  }}
                >
                  {schoolCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.school_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select School Subcategory"
                  value={schoolSubCategoryId}
                  onChange={(e) => {
                    setSchoolSubCategoryId(e.target.value);
                    setSchools([]); // Reset schools
                  }}
                >
                  {filteredSchoolSubCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.SubCategory_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select School"
                  value={schoolId}
                  onChange={(e) => setSchoolId(e.target.value)}
                >
                  {filteredSchools.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.school_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select Course Category"
                  value={courseCategoryId}
                  onChange={(e) => {
                    setCourseCategoryId(e.target.value);
                    setCourseSubCategoryId(""); // Reset dependent state
                    setCourses([]); // Reset courses
                  }}
                >
                  {courseCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.courses_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select Course Subcategory"
                  value={courseSubCategoryId}
                  onChange={(e) => {
                    setCourseSubCategoryId(e.target.value);
                    setCourses([]); // Reset courses
                  }}
                >
                  {filteredCourseSubCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.courses_sub_category_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Select Course"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)} // Single course selection
                >
                  {filteredCourses.map((course) => (
                    <MenuItem key={course._id} value={course._id}>
                      {course.courses_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Update Course
                </Button>
                <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{ ml: 5, mt: 2, backgroundColor: "#e0e0e0" }}
            onClick={handleCancel} // Cancel button to go back
          >
            Cancel
          </Button>
                
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <Snackbar open={success !== null || error !== null} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"}>
          {success || error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditAssignedCourse;

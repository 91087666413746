import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditCarrierPlanner = () => {
  const { id } = useParams(); // Get the carrier planner ID from the URL
  const [formData, setFormData] = useState({
    title: "",
    image_url: null, // Initialize as an empty string for the URL
    redirected_url: "",
    start_date: "",
    end_date: "",
    is_active: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCarrierPlanner = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/carrier_planner/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(response.data); // Log the response to check its structure
          setFormData({
            title: response.data.data.title, // Accessing the correct path
            image_url: response.data.data.image_url,
            redirected_url: response.data.data.redirected_url,
            start_date: response.data.data.start_date.split("T")[0], // Format for date input
            end_date: response.data.data.end_date.split("T")[0], // Format for date input
            is_active: response.data.data.is_active,
          });
          setImagePreview(response.data.data.image_url);
        }
      } catch (err) {
        console.error(err.response ? err.response.data : err.message); // Log the error details
        setError("Error fetching carrier planner data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCarrierPlanner();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        image_url: files[0],
        [name]: e.target.files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({
      ...formData,
      image_url: formData.image_url, // Retain the existing image if user removes the preview
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();
    for (const key in formData) {
      if (key === "image_url" && formData.image_url instanceof File) {
        data.append(key, formData.image_url);
      } else if (key === "image_url") {
        data.append(key, formData.image_url); // Append the existing image URL
      } else {
        data.append(key, formData[key]);
      }
    }
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/carrier_planner/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        navigate("/career_planner_list");
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Error updating carrier planner";
      setError(errorMessage);
    }
  };

  const handleCancel = () => {
    navigate("/career_planner_list"); // Navigate to the desired route on cancel
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">Edit Carrier Planner</Typography>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <input
            name="image_url"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {imagePreview && (
            <Box mt={2} position="relative">
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: "200px", height: "auto", borderRadius: "4px" }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ position: "absolute", top: 0, right: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <TextField
            label="Redirected URL"
            name="redirected_url"
            value={formData.redirected_url}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Start Date"
            name="start_date"
            type="date"
            value={formData.start_date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="End Date"
            name="end_date"
            type="date"
            value={formData.end_date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <Button
            variant=""
            color="secondary"
            onClick={handleCancel}
            sx={{ mr: 1 }}>
            Cancel
          </Button>

          <Button type="submit" variant="contained" color="primary">
            Update
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </form>
      )}
    </Container>
  );
};

export default EditCarrierPlanner;

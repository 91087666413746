import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import { useLocation, useNavigate } from "react-router-dom";

const ListingPage = () => {
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [schools, setSchools] = useState({});
  const [courses, setCourses] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const navigate = useNavigate();
  const token = getToken();
  const [success, setSuccess] = useState(null);
  const location = useLocation(); // Get location
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
 // Pagination States
 const [currentPage, setCurrentPage] = useState(0); // 0-based index for pages
 const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page
 const [totalItems, setTotalItems] = useState(0);
 const [totalPages, setTotalPages] = useState(1);
  // Fetch assigned courses
  // useEffect(() => {
  //   const fetchAssignedCourses = async () => {
  //     setLoading(true);
  //     try {
  //       const token = getToken();
  //     if (token) {
  //       const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course/school`,{
  //         params: {
  //           page: currentPage, limit: 10 
  //         },
  //         headers: {
  //           'Authorization': `Bearer ${token}`,
  //         },
  //       });
  //       setAssignedCourses(data.data);
  //       setTotalItems(data.pagination.totalItems);
  //       setTotalPages(data.pagination.totalPages);
  //     }} catch (error) {
  //       setError("Failed to fetch assigned courses.");
  //       console.error("Error fetching assigned courses:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchAssignedCourses();
  // }, [currentPage]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
    setCurrentPage(0); // Reset to first page whenever rows per page changes
  };


  // Fetch schools and courses based on IDs
  useEffect(() => {
    const fetchSchoolsAndCourses = async () => {
      if (assignedCourses.length === 0) return;

      const schoolIds = [...new Set(assignedCourses.map(assignment => assignment.school_id))];
      const courseIds = [...new Set(assignedCourses.map(assignment => assignment.course_id))];

      try {
        const [schoolsResponse, coursesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/school/without_pagination`, {
            params: {
              pagnation: 0
            },
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/without_pagination`,{
            params: {
              pagnation: 0
            },
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);

        const schoolsData = schoolsResponse.data.data.reduce((acc, school) => {
          acc[school._id] = school.school_name;
          return acc;
        }, {});

        const coursesData = coursesResponse.data.data.reduce((acc, course) => {
          acc[course._id] = course.courses_name;
          return acc;
        }, {});

        setSchools(schoolsData);
        setCourses(coursesData);
      } catch (error) {
        setError("Failed to fetch schools and courses.");
        console.error("Error fetching schools and courses:", error);
      }
    };

    fetchSchoolsAndCourses();
  }, [assignedCourses, token]);

  const handleSnackbarClose = () => {
    setError(null);
  };

  const handleAddCourse = () => {
    navigate('/partner/assign_courses');
  };

  const handleEditCourse = (id) => {
    navigate(`/partner/assign_courses/edit/${id}`);
  };

  const openDeleteDialog = (_id) => {
    setCourseToDelete(_id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        
          const token = getToken();
        if (token) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/assign_course/school/delete/${courseToDelete}`,{},{
            headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setSnackbarMessage("assigned course deleted successfully");
        setOpenSnackbar(true);
        closeDeleteDialog();
        // Refresh the list after deletion
        fetchAssignedCourses(); // Call to fetch assigned courses again to refresh the list
      } }catch (err) {
        setError("Failed to delete the course.");
        console.error('Error deleting course:', err);
      }
    }
  };

  const fetchAssignedCourses = async () => {
    setLoading(true);
    try {
      
      const token = getToken();
      if (token) {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course/school`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: {
          page: currentPage + 1, // Page is 1-based on the server
          limit: rowsPerPage,
        },
      });
      setAssignedCourses(data.data);
      setTotalItems(data.pagination.totalItems);
          setTotalPages(data.pagination.totalPages);
    }} catch (error) {
      setError('Error fetching schools');
      setOpenSnackbar(true);
      console.error("Error fetching assigned courses:", error);
    } finally {
      setLoading(false);
    }
  };
useEffect(()=>{
  fetchAssignedCourses();
},[currentPage, rowsPerPage])

 // Check if there is a snackbar message passed through state
 useEffect(() => {
  if (location.state && location.state.snackbarMessage) {
    setSnackbarMessage(location.state.snackbarMessage);
    setOpenSnackbar(true);
  }
}, [location.state]);

const handleCloseSnackbar = () => {
  setOpenSnackbar(false);
};


const handlePageChange = (event, newPage) => {
  setCurrentPage(newPage); // Update current page on pagination change
};

 


  return (
    <Container maxWidth="xl" sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Assigned Courses to School 
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleSnackbarClose}>
              <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
                {error}
              </Alert>
            </Snackbar>
          )}

          <Box display="flex" flexDirection="column" padding={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
                sx={{ width: '300px' }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddCourse}
              >
                Assign Course
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial No.</TableCell>
                    <TableCell>School Name</TableCell>
                    <TableCell>Assigned Course Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignedCourses
                    .filter(assignment => {
                      const courseName = courses[assignment.course_id] || '';
                      return courseName.toLowerCase().includes(searchTerm.toLowerCase());
                    })
                    .map((assignment, index) => (
                      <TableRow key={assignment._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{schools[assignment.school_id] || "Loading..."}</TableCell>
                        <TableCell>{courses[assignment.course_id] || "Loading..."}</TableCell>
                        <TableCell>
                          <IconButton color="primary" onClick={() => handleEditCourse(assignment._id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="error" onClick={() => openDeleteDialog(assignment._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
 {/* Table Pagination */}
 {totalItems >= 10 && (
            <Box display="flex" justifyContent="flex-end" padding={2}>
              <TablePagination
                component="div"
                count={totalItems}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage=""
                showFirstButton
                showLastButton
              />
            </Box>
          )}
          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this course?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog}>Cancel</Button>
              <Button color="error" onClick={handleDeleteCourse}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          
        </>
        
      )}

    {/* Snackbar for success message */}
    <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Container>
  );
};

export default ListingPage;

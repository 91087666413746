import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Switch,
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

const RolePermissions = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [division, setRoleName] = useState("");

  useEffect(() => {
    let isMounted = true;

    axios
      .get(`${process.env.REACT_APP_BACKEND}/role/getAllRoles`)
      .then((response) => {
        if (isMounted) {
          const fetchedRoles = response.data;
          setRoles(fetchedRoles);
        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    axios
      .get(`${process.env.REACT_APP_BACKEND}/permission/getAllPermissions`)
      .then((response) => {
        if (isMounted) {
          const fetchedPermissions = response.data;
          setPermissions(fetchedPermissions);
        }
      })
      .catch((error) => {
        console.error("Error fetching permissions:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const handleCheckboxChange = (event, permission) => {
    const { value, checked } = event.target;
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [value]: { permissionId: parseInt(value), status: checked ? 1 : 0 },
    }));
    console.log(selectedValues);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/permission/updatestatus`,
        {
          permissions: Object.values(selectedValues),
        }
      );

      if (response.status === 200) {
        alert("Permissions updated successfully!");
      } else {
        alert("An error occurred while updating permissions.");
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
      alert("An error occurred while updating permissions.");
    }
  };

  const groupedPermissions = {};
  Object.values(permissions).forEach((permission) => {
    if (!groupedPermissions[permission.role_id]) {
      groupedPermissions[permission.role_id] = {};
    }
    groupedPermissions[permission.role_id][permission.permission_name] = permission;
  });

  const permissionTypes = ["view", "create", "update", "delete"];

  return (
    <Box sx={{ padding: 4 }}>
      <Grid
        container
        sx={{
          backgroundColor: "#f0f0f0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px",
          marginBottom: "15px"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4" gutterBottom>
            Role Permission Access
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            <AddCircleOutlinedIcon sx={{ marginRight: "3px" }} />
            Apply
          </Button>
        </Box>
      </Grid>


      {/* Form Fields */}
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <InputLabel>Role Name</InputLabel>
            <Select
              value={division}
              onChange={(e) => setRoleName(e.target.value)}
              label="Select RoleName"
            >
              <MenuItem value={"RoleName 1"}>Name 1</MenuItem>
              <MenuItem value={"RoleName 2"}>Name 2</MenuItem>
            </Select>
          </FormControl>
          </Grid> */}
        <Grid item xs={12} md={7}>
          <TextField fullWidth label="Role Name" variant="outlined" />
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField fullWidth label="Role Code" variant="outlined" />
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center">
            <Typography>Status</Typography>
            <Switch />
          </Box>
        </Grid> */}
      </Grid>


      {/* Permissions Table */}
      {roles.length > 0 ? (
        <Box mt={4}>
          <Grid container spacing={3} justifyContent="center">
            {/* Permissions Table */}
            <Grid item xs={12} justifyContent="center">
              <Box p={3} sx={{ width: "65%", textAlign: "center", margin: "0 auto", border: "1px solid #f0f0f0" }}>
                <Grid container spacing={2}>
                  {/* Header Row (Division and Permissions) */}
                  <Grid item xs={4} display="flex" justifyContent="center">
                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "4px",
                        width: "60%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#fafafa",
                        mb: 2, // Added bottom margin for spacing
                      }}
                    >
                      <Typography>Division</Typography>
                    </Box>
                  </Grid>

                  {/* Header CheckBoxes for Permission Types */}
                  {permissionTypes.map((type) => (
                    <Grid item xs={2} key={type} display="flex" justifyContent="center">
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          borderRadius: "4px",
                          width: "80%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fafafa",
                          mb: 2, // Added bottom margin for spacing
                        }}
                      >
                        <Typography>{type.charAt(0).toUpperCase() + type.slice(1)}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                {/* Role Rows */}
                {roles.map((role) => (
                  <Grid container key={role.role_id} spacing={2}>
                    {/* Role Name with Outline */}
                    <Grid item xs={4} display="flex" justifyContent="center">
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          borderRadius: "4px",
                          width: "60%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fafafa",
                          mb: 2, // Added bottom margin for spacing
                        }}
                      >
                        <Typography>
                          {role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1)}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Checkboxes for Permissions */}
                    {Object.entries(groupedPermissions[role.role_id] || {}).map(([permissionName, permission]) => (
                      <Grid item xs={2} key={permissionName} display="flex" justifyContent="center">
                        <Box
                          sx={{
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            width: "45%",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#fff",
                            mb: 2, // Added bottom margin for spacing
                          }}
                        >
                          <Checkbox
                            name={permissionName}
                            value={permission.permission_id}
                            checked={
                              selectedValues[permission.permission_id]?.status === 1 ||
                              (selectedValues[permission.permission_id] === undefined && permission.status === 1)
                            }
                            onChange={(e) => handleCheckboxChange(e, permission)}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <CircularProgress />
      )}



    </Box>
  );
};

export default RolePermissions;

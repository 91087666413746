import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Container,
} from "@mui/material";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate for redirection
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { getToken } from "../../../utils/AuthUtils"; // assuming getToken is defined in a utility file

const AssessmentsList = () => {
  const [assessments, setAssessments] = useState([]);
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [totalItems, setTotalItems] = useState(0); // Total items for pagination
  const [formData, setFormData] = useState({
    assessment_name: "",
    course_id: "",
    chapter_id: "",
    class_id: "",
    levels: "",
    due_date: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Items per page
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  const navigate = useNavigate(); // Initialize useNavigate for navigation
  const location = useLocation(); // Get location

  // Fetch data on mount and whenever search term, page or rowsPerPage changes
  useEffect(() => {
    fetchAssessments();
    fetchClasses();
    fetchCourses();
    fetchChapters();
  }, [page, rowsPerPage, searchTerm]);

  // Fetch assessments with pagination and search term
  const fetchAssessments = async () => {
    try {
      console.log("searchTerm inside fetchAssessments:", searchTerm); // Debugging search term
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment`, {
          params: {
            page: page + 1, // Backend expects 1-based page indexing
            limit: rowsPerPage,
            search: searchTerm || '', // If searchTerm is empty or undefined, send empty string
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_key")}`,
          },
        }
      );
      setAssessments(response.data.data);
      setTotalItems(response.data.pagination.totalItems); // Set total items for pagination
    } catch (error) {
      setError("Failed to fetch assessments");
    } finally {
      setLoading(false);
    }
  };

  // Fetch available classes
  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/classes`);
      setClasses(response.data.data);
    } catch (error) {
      setError("Failed to fetch classes");
    }
  };

  // Fetch available courses
  const fetchCourses = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCourses(response.data.data);
      }
    } catch (error) {
      setError("Failed to fetch courses");
    }
  };

  // Fetch available chapters
  const fetchChapters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chapters`);
      setChapters(response.data.data);
    } catch (error) {
      setError("Failed to fetch chapters");
    }
  };

  // Delete an assessment
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/assessment/${id}`);
      setSnackbarMessage("Assessment deleted successfully");
      setOpenSnackbar(true);
      fetchAssessments(); // Reload assessments after deletion
    } catch (error) {
      setError("Failed to delete assessment");
    }
  };

  // Navigate to the update page
  const handleUpdate = (id) => {
    navigate(`/update-assessment/${id}`);
  };

  // Navigate to create new assessment page
  const handleCreateNew = () => {
    navigate("/assessment");
  };

  // Handle search input change
  const handleSearch = (event) => {
    const searchValue = event.target.value;
    console.log("searchTerm before update:", searchTerm); // Debugging search term
    setSearchTerm(searchValue);
    setPage(0); // Reset to first page when searching
    console.log("searchTerm after update:", searchValue);  // Debugging search term after update
  };

  // Handle page change in pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change in pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  return (
    <Container maxWidth="xl" className="mt-5">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm} // Bind the input value to the searchTerm state
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateNew}
        >
          Create New Assessment
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Class</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Chapter</TableCell>
              <TableCell>Assessment Title</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.length > 0 ? (
              assessments.map((assessment, index) => (
                <TableRow key={assessment._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {classes.find((c) => c._id === assessment.class_id)?.standard || "N/A"}
                  </TableCell>
                  <TableCell>
                    {courses.find((c) => c._id === assessment.course_id)?.course_name || "N/A"}
                  </TableCell>
                  <TableCell>
                    {chapters.find((c) => c._id === assessment.chapter_id)?.course_name || "N/A"}
                  </TableCell>
                  <TableCell>{assessment.assessment_name}</TableCell>
                  <TableCell>
                    {moment(assessment.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() => handleUpdate(assessment._id)}
                      startIcon={<EditIcon />}
                    >
                      Update
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDelete(assessment._id)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No assessments available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="flex-end" alignItems="center" padding={2}>
        {totalItems > rowsPerPage && (
          <TablePagination
            component="div"
            count={totalItems} // Use totalItems here for pagination
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        )}
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssessmentsList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get location

  useEffect(() => {
    // Check for snackbar message in the location state and set it
    if (location.state?.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarOpen(true);

      // Clear the location state snackbar message after it has been set
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/product/category`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCategories(response.data.data || []);
        }
      } catch (err) {
        console.error("Failed to fetch categories", err);
      }
    };

    const fetchSubCategories = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/product/category_sub`,
            {
              params:{
                pagination: 0,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSubCategories(response.data.data || []);
        }
      } catch (err) {
        console.error("Failed to fetch subcategories", err);
      }
    };

    fetchCategories();
    fetchSubCategories();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchProducts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, page, rowsPerPage]);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProducts(response.data.data);
        setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
      }
    } catch (err) {
      setError("Error fetching products");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/product/${id}`,
          {
            status: status === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchProducts();

        // Show Snackbar message
        setSnackbarMessage("Status updated successfully");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleAddProduct = () => {
    navigate("/products/add");
  };

  const handleEditProduct = (id) => {
    navigate(`/products/edit/${id}`);
  };

  const handleOpenDialog = (id) => {
    setProductToDelete(id);
    setDialogOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialog = () => {
    setProductToDelete(null);
    setDialogOpen(false);
  };

  const handleDeleteProduct = async () => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/product/delete/${productToDelete}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchProducts(); // Refresh the list after deletion
        // Show Snackbar message
        setSnackbarMessage("Product Deleted successfully");
        setSnackbarOpen(true);
      }
    } catch (err) {
      setError("Error deleting product");// Show Snackbar message
      setSnackbarMessage("Error Deleting Product");
      setSnackbarOpen(true);
      console.error(err);
    } finally {
      handleCloseDialog();
    }
  };

  const getCategoryName = (id) => {
    const category = categories.find((cat) => cat._id === id);
    return category ? category.product_category_name : "Unknown";
  };

  const getSubCategoryName = (id) => {
    const subCategory = subCategories.find((subCat) => subCat._id === id);
    return subCategory ? subCategory.product_sub_category_name : "Unknown";
  };
  const filteredproducts = products.filter(
    (product) =>
      product.product_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getCategoryName(product.product_category_id)
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getSubCategoryName(product.product_sub_category_name)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  )
  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Products
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddProduct}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Subcategory</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredproducts.length > 0 ? (
                // Filter products based on search term
                filteredproducts
                  
                  .map((product, index) => (
                    <TableRow key={product._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{product.product_name}</TableCell>
                      <TableCell>
                        {getCategoryName(product.product_category_id)}
                      </TableCell>
                      <TableCell>
                        {getSubCategoryName(product.product_sub_category_name)}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={product.status === 1}
                          onChange={() =>
                            handleToggleStatus(product._id, product.status)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleEditProduct(product._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleOpenDialog(product._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No products found
                  </TableCell>
                </TableRow>
              )}
              {/* Add an additional check for filtered results
              {products.length > 0 &&
                products.filter(
                  (product) =>
                    product.product_name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    getCategoryName(product.product_category_id)
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    getSubCategoryName(product.product_sub_category_name)
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                ).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No  products found
                    </TableCell>
                  </TableRow>
                )} */}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems >=10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this product?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProduct} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MuiAlert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default ProductList;

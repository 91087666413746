import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, Snackbar, Alert } from '@mui/material';
import { getToken } from '../utils/AuthUtils';

const AddDistrict = ({ isOpen, onClose, onAdd }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districtName, setDistrictName] = useState('');
  const [districtCode, setDistrictCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCountries(response.data.data);
    } }catch (err) {
      console.error('Failed to fetch countries');
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, {
        params: { countryId },
        headers: {
          Authorization: `Bearer ${token}`,
        }, // Pass countryId as a query parameter
      });
      setStates(response.data.data);
    } }catch (err) {
      console.error('Failed to fetch states');
    }
  };

  const handleAddDistrict = async () => {
    try {
      const token = getToken();
      if (token) {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/district`, {
        district_name: districtName,
        district_code: districtCode,
        country: selectedCountry,
        state: selectedState,
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onAdd();
      onClose();
      setSnackbarMessage(" District updated successfully");
      setOpenSnackbar(true);
    }} catch (err) {
      console.error('Failed to add District');
    }
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  return (
  <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Create</DialogTitle>
        <DialogContent>
        <TextField
            margin="normal"
            required
            fullWidth
            select
            label="Country"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country._id} value={country._id}>
                {country.country_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            required
            fullWidth
            select
            label="State"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            {states.map((state) => (
              <MenuItem key={state._id} value={state._id}>
                {state.state_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            required
            fullWidth
            label="District Name"
            value={districtName}
            onChange={(e) => setDistrictName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="District Code"
            value={districtCode}
            onChange={(e) => setDistrictCode(e.target.value)}
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddDistrict} color="primary" variant='contained'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
  </>
  );
};

export default AddDistrict;

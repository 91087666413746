import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

function EditCourseSub({ open, onClose, categoryId, category, onUpdate }) {
  const [courseName, setCourseName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(""); // Renamed variable
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/courses_category`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.ok) {
            // Filter categories to include only those with status = 1
            const activeCategories = data.data.filter(
              (category) => category.status === 1
            );
            setCategories(activeCategories);
          } else {
            setSnackbarMessage(data.message || "Failed to fetch categories");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
          }
        }
      } catch (error) {
        setSnackbarMessage("Error fetching categories");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    if (category) {
      setCourseName(category.courses_sub_category_name);
      setSelectedCategoryId(category.course_category_id); // Use renamed variable
    }
  }, [category]);

  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategoryId(e.target.value); // Use renamed variable
  };

  const handleCourseUpdate = async (e) => {
    e.preventDefault();
    if (!courseName || !selectedCategoryId) {
      // Use renamed variable
      setSnackbarMessage("Please fill in all fields");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      const token = getToken();
      if (token) {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/${categoryId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            courses_sub_category_name: courseName,
            course_category_id: selectedCategoryId, // Use renamed variable
          }),
        }
      );

      if (response.ok) {
        setSnackbarMessage("Course updated successfully");
        setSnackbarSeverity("success");
        onUpdate(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        const result = await response.json();
        setSnackbarMessage(result.message || "Failed to update course");
        setSnackbarSeverity("error");
      }
    } }catch (error) {
      setSnackbarMessage("Error occurred while updating course");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Subcategory</DialogTitle>
      <DialogContent>
        <form onSubmit={handleCourseUpdate}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategoryId} // Use renamed variable
              onChange={handleCategoryChange}
              label="Category"
              disabled={loading}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.courses_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Subcategory"
            value={courseName}
            onChange={handleCourseNameChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            disabled={loading}
          />

          {/* <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Update 
              </Button>
            )}
          </Box> */}
        </form>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleCourseUpdate}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCourseSub;

import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

function AddTeacherModal({ open, onClose, onAdd }) {
  const [partnerName, setPartnerName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handlePartnerChange = (e) => {
    setPartnerName(e.target.value);
  };

  const handlePartnerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsLoading(true);

    try {
      const token = getToken();
      if (token) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/teachers/category`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ teacher_name: partnerName }),
          }
        );
        setPartnerName("");
        setOpenSnackbar(false);
        
        
        if (response.ok) {
          onAdd(true);
        onclose();
        } else {
          const errorData = await response.json(); // Parse response body as JSON
          setSnackbarMessage(errorData?.message || "Failed to add Teacher Category");
          setOpenSnackbar(true);
        }

        setOpenSnackbar(true);
      }
          } catch (error) {
      // setSnackbarMessage("Error occurred");
      // setOpenSnackbar(true);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth >
      <DialogTitle>Add Category</DialogTitle>
      <form onSubmit={handlePartnerSubmit}>
      <DialogContent>
          <TextField
            label="Teacher Category  Name"
            value={partnerName}
            onChange={handlePartnerChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          {/* <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add 
              </Button>
            )}
          </Box> */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage === "Teacher added successfully"
                ? "success"
                : "error"
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          // onClick={handlePartnerSubmit}
          variant="contained"
          color="primary"
          disabled={isLoading}
          >
          {isLoading ? 'Adding...' : 'Add'}
        </Button>
      </DialogActions>
      </form>

    </Dialog>
  );
}

export default AddTeacherModal;

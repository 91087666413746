

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Container,
  Box,
  IconButton,
  Switch,
  TextField,
  MenuItem,
  Grid,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import AddIcon from "@mui/icons-material/Add";

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [departmentCategorySubs, setDepartmentCategorySubs] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Department filters
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  useEffect(() => {
    fetchDepartments();
    fetchDepartmentCategories();
    fetchDepartmentCategorySubs();
  }, []);

  useEffect(() => {
    const filtered = departments.filter((dept) => {
      const categoryName =
        departmentCategories.find(
          (cat) => cat._id === dept.department_category_id
        )?.department_category_name || "";
      const subcategoryName =
        departmentCategorySubs.find(
          (sub) => sub._id === dept.department_sub_category_id
        )?.department_sub_category_name || "";

      return (
        (dept?.department_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
          categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          subcategoryName.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (!selectedCategory || dept.department_category_id === selectedCategory) &&
        (!selectedSubcategory || dept.department_sub_category_id === selectedSubcategory)
      );
    });
    setFilteredDepartments(filtered);
  }, [searchQuery, departments, departmentCategories, departmentCategorySubs, selectedCategory, selectedSubcategory]);

  useEffect(() => {
    setPage(0); // Reset to first page when filtered departments change
  }, [filteredDepartments]);

  // Fetch Departments
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDepartments(response.data.data || []);
        setTotalItems(response.data.pagination.totalItems); // Ensure this is correct based on your API response
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Department Categories
  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category/nopagination`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setDepartmentCategories(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };

  // Fetch Department Category Subs
  const fetchDepartmentCategorySubs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/nopagnation`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setDepartmentCategorySubs(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department category subs", error);
    }
  };

  // Filter subcategories based on selected category
  useEffect(() => {
    if (selectedCategory) {
      const filtered = departmentCategorySubs.filter(
        (subcategory) =>
          subcategory.department_category_id === selectedCategory
      );
      setFilteredSubcategories(filtered);
    } else {
      setFilteredSubcategories([]);
    }
  }, [selectedCategory, departmentCategorySubs]);

  // Handle Save or Update
  const handleSave = async () => {
    try {
      const token = getToken();
      if (token) {
        if (isEdit) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/department/${currentDepartment._id}`,
            currentDepartment,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("Department Updated successfully");
          setOpenSnackbar(true);
        } else {
          await axios.post(
            `${process.env.REACT_APP_BACKEND}/api/department`,
            currentDepartment,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("Department added successfully");
          setOpenSnackbar(true);
        }
        fetchDepartments();
        handleCloseDialog();
      }
    } catch (error) {
      console.error("Error saving department", error);
    }
  };

  // Delete Department
  const handleDelete = async () => {
    if (departmentToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/department/delete/${departmentToDelete._id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          fetchDepartments();
          handleCloseConfirmDialog();
          setSnackbarMessage("Department Deleted successfully");
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error("Error deleting department", error);
      }
    }
  };

  // Toggle Status
  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/department/${id}`,
        {
          status: status === 1 ? 0 : 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      fetchDepartments();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Open Dialog for Add/Edit
  const handleOpenDialog = (department = {}) => {
    setCurrentDepartment(department);
    setIsEdit(!!department._id);
    setOpenDialog(true);
  };

  // Close Dialog
  const handleCloseDialog = () => {
    setCurrentDepartment({});
    setOpenDialog(false);
  };

  // Open Confirmation Dialog
  const handleOpenConfirmDialog = (department) => {
    setDepartmentToDelete(department);
    setConfirmDelete(true);
  };

  // Close Confirmation Dialog
  const handleCloseConfirmDialog = () => {
    setDepartmentToDelete(null);
    setConfirmDelete(false);
  };

  // Pagination Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Typography color="error">Error loading data: {error.message}</Typography>
    );

  // Calculate current page data
  const paginatedDepartments = filteredDepartments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Check if fields are valid
  const isFormValid =
    currentDepartment.department_name &&
    currentDepartment.department_category_id &&
    currentDepartment.department_sub_category_id;

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        sx={{ background: "#e0e0e0", p: 1 }}
      >
        <Typography variant="h4" gutterBottom>
          Department List
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ alignSelf: "flex-end" }}
        />
        <TextField
          select
          label="Department Category"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          sx={{ width: 200 }}
        >
          <MenuItem value="">All Categories</MenuItem>
          {departmentCategories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              {category.department_category_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Department Subcategory"
          value={selectedSubcategory}
          onChange={(e) => setSelectedSubcategory(e.target.value)}
          sx={{ width: 200 }}
        >
          <MenuItem value="">All Subcategories</MenuItem>
          {filteredSubcategories.map((sub) => (
            <MenuItem key={sub._id} value={sub._id}>
              {sub.department_sub_category_name}
            </MenuItem>
          ))}
        </TextField>
       
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Department Category</TableCell>
              <TableCell>Department Subcategory</TableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDepartments.length > 0 ? (
              paginatedDepartments.map((department, index) => (
                <TableRow key={department._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{department.department_name}</TableCell>
                  <TableCell>
                    {departmentCategories.find(
                      (cat) => cat._id === department.department_category_id
                    )?.department_category_name || "N/A"}
                  </TableCell>
                  <TableCell>
                    {departmentCategorySubs.find(
                      (sub) => sub._id === department.department_sub_category_id
                    )?.department_sub_category_name || "N/A"}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems >= 10 && (
        <Box display="flex" justifyContent="flex-end" padding={2}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      {/* Dialog for Add/Edit */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {isEdit ? "Edit Department" : "Add Department"}
        </DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Department Category"
            fullWidth
            margin="normal"
            value={currentDepartment.department_category_id || ""}
            onChange={(e) => {
              setCurrentDepartment({
                ...currentDepartment,
                department_category_id: e.target.value,
              });
              setFilteredSubcategories(
                departmentCategorySubs.filter(
                  (sub) => sub.department_category_id === e.target.value
                )
              );
            }}
          >
            {departmentCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.department_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Department Subcategory"
            fullWidth
            margin="outlined"
            value={currentDepartment.department_sub_category_id || ""}
            onChange={(e) =>
              setCurrentDepartment({
                ...currentDepartment,
                department_sub_category_id: e.target.value,
              })
            }
          >
            {filteredSubcategories.map((sub) => (
              <MenuItem key={sub._id} value={sub._id}>
                {sub.department_sub_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Department Name"
            fullWidth
            margin="normal"
            value={currentDepartment.department_name || ""}
            onChange={(e) =>
              setCurrentDepartment({
                ...currentDepartment,
                department_name: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!isFormValid}
          >
            {isEdit ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Deletion */}
      <Dialog open={confirmDelete} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this department?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default DepartmentList;
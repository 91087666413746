import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination,
  Switch,
  Snackbar,
  Alert,
  Container,
  // Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/system";
import { getToken } from "../../utils/AuthUtils";

const CarrierPlannerPage = () => {
  const [carrierPlanners, setCarrierPlanners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [carrierPlannerToDelete, setCarrierPlannerToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  const navigate = useNavigate();

  const fetchCarrierPlanners = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/carrier_planner`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCarrierPlanners(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      setError("Error fetching carrier planners");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchCarrierPlanners();
    }, 300);
    return () => clearTimeout(handler);
  }, [searchTerm, page, rowsPerPage]);

  const handleAddCarrierPlanner = () => navigate("/career_planner/add");
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };
  const handleEditCarrierPlanner = (id) =>
    navigate(`/career_planner/edit/${id}`);

  const handleStatusToggle = async (id, currentStatus) => {
    const newStatus = !currentStatus;
    setCarrierPlanners((prev) =>
      prev.map((carrierPlanner) =>
        carrierPlanner._id === id
          ? { ...carrierPlanner, status: newStatus }
          : carrierPlanner
      )
    );
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/carrier_planner/${id}/status_carrier_planner`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Carrier Planner status updated successfully");
        setSnackbarOpen(true);
      }
    } catch {
      setCarrierPlanners((prev) =>
        prev.map((carrierPlanner) =>
          carrierPlanner._id === id
            ? { ...carrierPlanner, status: currentStatus }
            : carrierPlanner
        )
      );
      setSnackbarMessage("Failed to update carrier planner status");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteConfirmation = (carrierPlanner) => {
    setCarrierPlannerToDelete(carrierPlanner);
    setOpenDialog(true);
  };

  const handleDeleteCarrierPlanner = async () => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/carrier_planner/delete/${carrierPlannerToDelete._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOpenDialog(false);
        fetchCarrierPlanners();
        setSnackbarMessage("Carrier Planner deleted successfully");
        setSnackbarType("success");
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage("Error deleting Carrier planner");
      setSnackbarType("error");
      setSnackbarOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const truncateUrl = (url) => {
    const maxLength = 30; // Maximum length for the URL display
    return url.length > maxLength ? `${url.substring(0, maxLength)}...` : url;
  };

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4" component="h1">
          Career Planners
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddCarrierPlanner}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Redirected URL</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carrierPlanners.length > 0 ? (
                  carrierPlanners
                    .filter((carrierPlanner) =>
                      carrierPlanner?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      carrierPlanner?.redirected_url?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      new Date(carrierPlanner?.start_date).toLocaleDateString().includes(searchTerm.toLowerCase()) ||
                      new Date(carrierPlanner?.end_date).toLocaleDateString().includes(searchTerm.toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((carrierPlanner, index) => (
                      <TableRow key={carrierPlanner._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{carrierPlanner.title}</TableCell>
                        <TableCell>
                          <a
                            href={carrierPlanner.image_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={carrierPlanner.image_url}
                              alt={carrierPlanner.title}
                              style={{ width: "100px", height: "auto" }}
                            />
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={carrierPlanner.redirected_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {truncateUrl(carrierPlanner.redirected_url)}
                          </a>
                        </TableCell>
                        <TableCell>
                          {new Date(carrierPlanner.start_date).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          {new Date(carrierPlanner.end_date).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={carrierPlanner.status}
                            onChange={() =>
                              handleStatusToggle(carrierPlanner._id, carrierPlanner.status)
                            }
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() => handleEditCarrierPlanner(carrierPlanner._id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteConfirmation(carrierPlanner)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      {searchTerm ? "No Career Planners found for your search." : "No Career Planners found."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {totalItems >= 10 && (
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Rows per page"
                showFirstButton
                showLastButton
              />
            </Grid>
          )}
        </>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Career planner "{carrierPlannerToDelete?.title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCarrierPlanner} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CarrierPlannerPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";

const EditTown = ({ open, onClose, townId, onUpdate }) => {
  const [townName, setTownName] = useState("");
  const [townCode, setTownCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (open && townId) {
      fetchTownDetails();
      fetchCountries();
    }
  }, [open, townId]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      fetchDistricts(selectedState);
    } else {
      setDistricts([]);
      setSelectedDistrict("");
    }
  }, [selectedState]);

  const fetchTownDetails = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/${townId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;
        setTownName(data.Town_name);
        // setTownCode(data.Town_code);
        setSelectedCountry(data.country);
        setSelectedState(data.state);
        setSelectedDistrict(data.district);
      }
    } catch (err) {
      setError("Failed to fetch town details");
    } finally {
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch countries:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/by-country`,
          {
            params: { countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch states:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/by-state`,
          {
            params: { stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch districts:",
        err.response?.data?.message || err.message
      );
    }
  };

  const handleUpdateTown = async () => {
    if (
      !townName.trim() ||
      !selectedCountry ||
      !selectedState ||
      !selectedDistrict
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/town/${townId}`,
          {
            Town_name: townName.trim(),
            // Town_code: townCode.trim(),
            country: selectedCountry,
            state: selectedState,
            district: selectedDistrict,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSuccess(true);
        onUpdate();
        onClose();
      }
    } catch (err) {
      setError("Failed to update town.");
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setError(null);
    setSuccess(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Town</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar
            open={Boolean(error)}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        {success && (
          <Snackbar
            open={success}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity="success">
              Town updated successfully
            </Alert>
          </Snackbar>
        )}
        {/* <TextField
          margin="normal"
          fullWidth
          label="Town Code"
          value={townCode}
          onChange={(e) => setTownCode(e.target.value)}
          disabled={loading}
        /> */}
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          disabled={loading}>
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.country_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="State"
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          disabled={!selectedCountry || loading}>
          {states.map((state) => (
            <MenuItem key={state._id} value={state._id}>
              {state.state_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="District"
          value={selectedDistrict}
          onChange={(e) => setSelectedDistrict(e.target.value)}
          disabled={!selectedState || loading}>
          {districts.map((district) => (
            <MenuItem key={district._id} value={district._id}>
              {district.district_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Town Name"
          value={townName}
          onChange={(e) => setTownName(e.target.value)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleUpdateTown}
          color="primary"
          variant="contained"
          disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTown;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import { Container } from "@mui/system";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";

const ViewPartnerPage = () => {
  const { id } = useParams();
  const [partnerData, setPartnerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/partner/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setPartnerData(response.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch partner data:", error);
        setSnackbarMessage("Error fetching partner data.");
        setSnackbarType("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/edit_partner/${id}`);
  };

  if (loading) return <Typography>Loading...</Typography>;

  if (!partnerData) {
    return (
      <Typography variant="h6" color="error">
        Partner not found.
      </Typography>
    );
  }

  return (
    <Container maxWidth="100%" sx={{ padding: { xs: 2, md: 4 } }}>
      {/* Breadcrumbs */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}>
        <Link underline="hover" color="inherit" href="/dashboard">
          <HomeIcon sx={{ mr: 0.5, verticalAlign: "middle" }} />
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/partner_list">
          Partners
        </Link>
        <Typography color="text.primary">Partner Details</Typography>
      </Breadcrumbs>

      {/* Header Box */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        padding={2}
        sx={{ backgroundColor: "#f5f5f5", borderRadius: 1, textAlign: "left" }}>
        <Typography variant="h5" fontWeight="bold">
          PARTNER DETAILS
        </Typography>
      </Box>

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Partner Name:</Typography>
            <Typography>{partnerData.partner_name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Contact Person:</Typography>
            <Typography>{partnerData.contact_person}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Mobile:</Typography>
            <Typography>{partnerData.mobile}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Email:</Typography>
            <Typography>{partnerData.email}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Agreement Start Date:</Typography>
            <Typography>
              {new Date(partnerData.agreement_start_date).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Agreement End Date:</Typography>
            <Typography>
              {new Date(partnerData.agreement_end_date).toLocaleDateString()}
            </Typography>
          </Grid>
          {/* Add more fields as necessary */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Branch Name:</Typography>
            <Typography>{partnerData.branch_name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Account Name:</Typography>
            <Typography>{partnerData.account_name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Account Number:</Typography>
            <Typography>{partnerData.account_number}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">IFSC Code:</Typography>
            <Typography>{partnerData.ifsc_code}</Typography>
          </Grid>
        </Grid>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleEdit}
          sx={{ mt: 2, backgroundColor: "#e0e0e0" }}>
          Edit Partner
        </Button> */}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ViewPartnerPage;

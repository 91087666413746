import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const AddLabname = () => {
  const [labName, setLabName] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Fetch departments from the backend
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDepartments(response.data.data); // Adjust based on your API response
      }
    } catch (err) {
      setError("Failed to fetch departments.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!labName) errors.labName = "Lab name is required";
    if (!departmentId) errors.departmentId = "Department is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return; // Prevent submission if form is invalid

    setError(""); // Clear any previous error messages

    try {
      const token = getToken();
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/labnames`,
          {
            lab_name: labName,
            department: departmentId,
            status: true, // Set default status, modify as needed
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201) {
          // Store success message in sessionStorage
          sessionStorage.setItem("labnameSuccessMessage", "Lab name added successfully");
          // Redirect to Labnames list
          navigate("/labnames");
        }
      }
    } catch (err) {
      setError("Error adding lab name");
      console.error(err);
    }
  };

  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Box padding={2}>
        <Typography variant="h4">Add Lab Name</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Lab Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={labName}
            onChange={(e) => {
              setLabName(e.target.value);
              setFormErrors({ ...formErrors, labName: null }); // Clear error if valid
            }}
            required
            error={!!formErrors.labName}
            helperText={formErrors.labName}
          />
          <FormControl
            fullWidth
            margin="normal"
            required
            error={!!formErrors.departmentId}
          >
            <InputLabel>Department</InputLabel>
            <Select
              value={departmentId}
              onChange={(e) => {
                setDepartmentId(e.target.value);
                setFormErrors({ ...formErrors, departmentId: null }); // Clear error if valid
              }}
            >
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </Select>
            {formErrors.departmentId && (
              <FormHelperText>{formErrors.departmentId}</FormHelperText>
            )}
          </FormControl>
          <Box display="flex" justifyContent="flex-start" alignItems="center" mt={2}>
            <Button variant="contained" color="primary" type="submit">
              Add Lab Name
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              sx={{ ml: 2, backgroundColor: "#e0e0e0" }} // Adjust margin as needed
              onClick={handleCancel} // Cancel button to go back
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddLabname;

import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  Container,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { getToken } from "../../utils/AuthUtils";
import SearchIcon from "@mui/icons-material/Search";

const AddCompanyNewsPage = () => {
  const [formData, setFormData] = useState({
    company_news_title: "",
    company_news_description: "",
    company_news_image: null,
    auther_image: null,
    company_news_coordinator: "",
    publish_date: "",
    auther_name: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [autherImagePreview, setAutherImagePreview] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file" && files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));

      if (name === "company_news_image") {
        setImagePreview(URL.createObjectURL(files[0]));
      } else if (name === "auther_image") {
        setAutherImagePreview(URL.createObjectURL(files[0]));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const {
      company_news_title,
      company_news_description,
      company_news_image,
      auther_image,
      company_news_coordinator,
    } = formData;

    if (
      !company_news_title ||
      !company_news_description ||
      !company_news_image ||
      !auther_image ||
      !company_news_coordinator
    ) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/company_news`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        navigate("/company_news_list", {
          state: { snackbarMessage: "Company news added successfully" },
        });
      }
    } catch (err) {
      setError("Error adding company news");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({ ...formData, company_news_image: null, auther_image: null });
  };

  const handleCancel = () => {
    navigate("/company_news_list"); // Navigate to the desired route on cancel
  };

  return (
    <Container>
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">Add Company News</Typography>
        <TextField
          variant="outlined"
          size="small"
          // onChange={(event) => setSearchTerm(event.target.value)}
          // value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          name="company_news_title"
          label="News Title"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.company_news_title}
          onChange={handleChange}
          required
        />
        <TextField
          name="company_news_description"
          label="News Description"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={formData.company_news_description}
          onChange={handleChange}
          required
        />
        <label htmlFor="company_news_image">Upload Company News Image:</label>
        <input
          name="company_news_image"
          type="file"
          accept="image/*"
          onChange={handleChange}
          required
        />
        {imagePreview && (
          <Box mt={2} position="relative">
            <img
              src={imagePreview}
              alt="Preview"
              style={{ width: "200px", height: "auto", borderRadius: "4px" }}
            />
            <IconButton
              onClick={handleRemoveImage}
              style={{ position: "absolute", top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <label htmlFor="company_news_image">Upload Auther Image:</label>
        <input
          name="auther_image"
          type="file"
          accept="image/*"
          onChange={handleChange}
          required
        />
        {autherImagePreview && (
          <Box mt={2} position="relative">
            <img
              src={autherImagePreview}
              alt="Author Preview"
              style={{ width: "200px", height: "auto", borderRadius: "4px" }}
            />
            <IconButton
              onClick={handleRemoveImage}
              style={{ position: "absolute", top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <TextField
          name="company_news_coordinator"
          label="Coordinator"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.company_news_coordinator}
          onChange={handleChange}
          required
        />

        <TextField
          name="publish_date"
          label="Publish Date"
          type="datetime-local"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.publish_date}
          onChange={handleChange}
          required
          // error={!!formErrors.end_time}
          // helperText={formErrors.end_time}
          InputLabelProps={{
            shrink: true, // Makes the label always visible
          }}
        />
        <TextField
          name="auther_name"
          label="Author Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.auther_name}
          onChange={handleChange}
          required
        />
        <Box display="flex" alignItems="center">
          <Button
            variant=""
            color="secondary"
            onClick={handleCancel} // You can define this function to handle the cancel action // No margin-top needed for alignment
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ backgroundColor: "#e0e0e0", ml: 1 }}
            disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Create"}
          </Button>
        </Box>
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Container>
  );
};

export default AddCompanyNewsPage;

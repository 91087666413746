import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import {
  Typography,
  Box,
  Button,
  Container,
  CircularProgress,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { styled } from "@mui/material/styles";
import "./EditUnit.css";
import 'quill/dist/quill.snow.css'; // or your custom CSS
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const toolbarOptions = [
  [{ font: [] }],
  [{ size: [] }],
  ["bold", "italic", "underline", "strike"],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  ["blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  ["link"],
  ["clean"],
];

function EditUnit() {
  const { id } = useParams();
  const [unitData, setUnitData] = useState([]);
  const [contentData, setContentData] = useState(null);
  const [indexes, setIndexes] = useState("");
  const [unit, setUnit] = useState("");
  const [isEditing22, setIsEditing22] = useState(false); // Changed state name to isEditing22
  const [selectedContentType, setSelectedContentType] = useState(""); // or a valid default value
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setpdfLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [galleryLoading, setGalleryLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();
  // States for image modal
  const [isEditing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [hideFormControl, setHideFormControl] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteGallery, setConfirmDeleteGallery] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteInfo, setDeleteInfo] = useState(null);

  const { coursesname, chaptername, unitname } = useParams();

  const ImageGrid = styled(Box)(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "repeat(3, 1fr)", // Three images per row
    // padding: theme.spacing(2),
    "& img": {
      width: "100%",
      height: "auto",
      borderRadius: theme.shape.borderRadius,
      objectFit: "cover",
      cursor: "pointer",
    },
  }));

  const toggleEdit = async (index) => {
    const updatedContentData = { ...contentData };

    if (
      updatedContentData.units_content[index].type !== "videoUrl" &&
      updatedContentData.units_content[index].type !== "youtubeUrl" &&
      !updatedContentData.units_content[index].data &&
      !updatedContentData.units_content[index].youtubeUrl 
    ) {
      alert("Data is empty or not provided!");
      return;
    }

    updatedContentData.units_content[index].edit =
      updatedContentData.units_content[index].edit === 0 ? 1 : 0;

    setContentData(updatedContentData);
    console.log("edit data", updatedContentData);

    const anyEditing = updatedContentData.units_content.some(
      (unit) => unit.edit === 1
    );
    // Set the editing state
    setEditing(anyEditing);
    if (updatedContentData.units_content[index].edit === 0) {
      setIsSaving(true);
      try {
        // Simulate API call to save the edited content data
        console.log("Saving edited content:", contentData.units_content);

        // Perform your save request here
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/unitupload/editunit/${contentData._id}`,
          contentData
        );

        if (response.status === 200) {
          // Show success message
          setSnackbarMessage("Content saved successfully!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          console.log("Content saved successfully:", contentData);
          // navigate(`/unitinterface/${contentData.units_id}`);
        }
      } catch (error) {
        console.error("Error saving content:", error);
        setSnackbarMessage("Error saving content. Please try again.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const toggleEdit1 = () => {
    const updatedContentData = { ...contentData };

    if (!updatedContentData.units_content[0].data) {
      alert("Data is empty or not provided!");
      return;
    }

    updatedContentData.units_content[0].edit =
      updatedContentData.units_content[0].edit === 0 ? 1 : 0;
    setContentData(updatedContentData);
    console.log("edit data", updatedContentData);
    // Check if any edit value is 1
    const anyEditing = updatedContentData.units_content.some(
      (unit) => unit.edit === 1
    );

    setEditing(anyEditing);
    if (updatedContentData.units_content[0].edit === 0) {
      setIsSaving(true);
      try {
        // Simulate API call to save the edited content data
        console.log("Saving edited content:", contentData.units_content);

        // Perform your save request here
        const response = axios.put(
          `${process.env.REACT_APP_BACKEND}/unitupload/editunit/${contentData._id}`,
          contentData
        );

        if (response.status === 200) {
          // Show success message
          setSnackbarMessage("Content saved successfully!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          console.log("Content saved successfully:", contentData);
          // navigate(`/unitinterface/${contentData.units_id}`);
        }
      } catch (error) {
        console.error("Error saving content:", error);
        setSnackbarMessage("Error saving content. Please try again.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      } finally {
        setIsSaving(false);
      }
    }
  };
  // const handleDelete = (index) => {
  //   const updatedContentData = { ...contentData }; // Create a copy of the content data
  //   updatedContentData.units_content = [...updatedContentData.units_content]; // Ensure units_content is an array

  //   // Remove the section at the specified index
  //   updatedContentData.units_content.splice(index, 1);

  //   setContentData(updatedContentData); // Update the state with the modified content data
  //   console.log(updatedContentData); // Log the updated content data
  // };
  // Trigger delete action and show confirmation dialog
  const handleDeleteDialog = (index) => {
    setDeleteIndex(index); // Set index to delete
    setConfirmDelete(true); // Show confirmation dialog
  };

  // Close the dialog without deleting
  const handleCloseConfirmDialog = () => {
    setConfirmDelete(false); // Close the dialog without deletion
    setConfirmDeleteGallery(false);
  };

  // Confirm deletion and remove the item from contentData
    const handleConfirmDelete = async () => {
    console.log("delete index", deleteIndex);
  
    const updatedContentData = { ...contentData };
    updatedContentData.units_content = [...updatedContentData.units_content]; // Ensure it's an array
  
    // Remove the section at the specified index
    updatedContentData.units_content.splice(deleteIndex, 1);
  
    // Update state with the new contentData
    setContentData(updatedContentData);
    setConfirmDelete(false); // Close the dialog after deletion
    
    setIsSaving(true);
    try {
      // Make the API call with the updated content data
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/unitupload/editunit/${contentData._id}`,
        updatedContentData  // Use updatedContentData instead of contentData
      );
      console.log("response", response);
      
      if (response.status === 200) {
        console.log("Content saved successfully:", updatedContentData);
        setSnackbarMessage("Unit Content Deleted Successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error saving content:", error);
      setSnackbarMessage("Error deleting content. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setIsSaving(false);
    }
  };

  // const handleGalleryDelete = (index, imageIndex) => {
  //   const updatedContentData = { ...contentData }; // Create a copy of the content data
  //   updatedContentData.units_content = [...updatedContentData.units_content]; // Ensure units_content is an array

  //   // Remove the section at the specified index
  //   updatedContentData.units_content[index].data.splice(imageIndex, 1);

  //   setContentData(updatedContentData); // Update the state with the modified content data
  //   console.log(updatedContentData); // Log the updated content data
  // };
  const handleGalleryDeleteDialog = (index, imageIndex) => {
    setDeleteInfo({ index, imageIndex }); // Store the indices temporarily
    setConfirmDeleteGallery(true); // Show the confirmation dialog
  };

  // Close the confirmation dialog without deleting
  // const handleCloseConfirmDialog = () => {
  //   setConfirmDelete(false); // Close the dialog without performing the deletion
  // };

  // Confirm the deletion and delete the image from the content
  const handleConfirmDeleteGallery = () => {
    const { index, imageIndex } = deleteInfo; // Get the indices to delete
    const updatedContentData = { ...contentData };
    updatedContentData.units_content = [...updatedContentData.units_content]; // Ensure units_content is an array

    // Remove the image at the specified index from the data array
    updatedContentData.units_content[index].data.splice(imageIndex, 1);

    // Update the state with the modified content data
    setContentData(updatedContentData);
    setConfirmDeleteGallery(false); // Close the dialog after deletion

    setIsSaving(true);
    try {
      // Simulate API call to save the edited content data
      console.log("Saving edited content:", contentData.units_content);

      // Perform your save request here, e.g., using axios
      const response = axios.put(
        `${process.env.REACT_APP_BACKEND}/unitupload/editunit/${contentData._id}`,
        contentData
      );
      if (response.status) {
        // alert("Content saved successfully!");
        console.log("Content saved successfully:", contentData);
        setSnackbarMessage("Unit Contant Deleted Successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        // navigate(`/unitinterface/${contentData.units_id}`);
      }
    } catch (error) {
      console.error("Error saving content:", error);
    } finally {
      setIsSaving(false);
    }
  };

  // Usage example: handleDelete(1); // This will remove the item at index 1

  const handleContentTypeChange = (event, index) => {
    const { value } = event.target;

    setContentData((prevContentData) => {
      // Ensure prevContentData is an object
      if (typeof prevContentData !== "object" || prevContentData === null) {
        console.error("prevContentData is not an object:", prevContentData);
        return contentData; // or handle as needed
      }

      // Make sure units_content is an array
      const unitsContent = prevContentData.units_content || [];
      if (!Array.isArray(unitsContent)) {
        console.error("units_content is not an array:", unitsContent);
        return contentData; // or handle as needed
      }
      // Determine the new section's data based on the selected value
      const newSection =
        value === "pdf" || value === "gallery"
          ? { type: value, data: [""], edit: 1 } // Use an array for pdf and gallery
          : { type: value, data: "", edit: 1 }; // Use an empty string for other types
      const updatedSections = [...unitsContent];
      updatedSections.splice(index + 1, 0, newSection);
      console.log(updatedSections);

      // Return a new object with updated units_content
      return {
        ...prevContentData,
        units_content: updatedSections,
      };
    });

    setEditing(true);
  };

  const handleImageContentTypeChange = (index, imageIndex) => {
    // Set the new section's data as an empty string
    const newSection = "";

    setContentData((prevContentData) => {
      // Ensure prevContentData is an object
      if (typeof prevContentData !== "object" || prevContentData === null) {
        console.error("prevContentData is not an object:", prevContentData);
        return contentData; // or handle as needed
      }

      // Make sure units_content is an array
      const unitsContent = prevContentData.units_content || [];
      if (!Array.isArray(unitsContent)) {
        console.error("units_content is not an array:", unitsContent);
        return contentData; // or handle as needed
      }

      // Clone the current unit to avoid direct mutation
      const updatedSections = [...unitsContent];

      // Ensure the data property exists and is an array
      if (!Array.isArray(updatedSections[index].data)) {
        console.error("data is not an array:", updatedSections[index].data);
        return contentData; // or handle as needed
      }

      // Add the new section (empty string) at the specified imageIndex
      updatedSections[index].data.splice(imageIndex + 1, 0, newSection);

      console.log("updated sec", updatedSections[index].data);

      // Return a new object with updated units_content
      return {
        ...prevContentData,
        units_content: updatedSections,
      };
    });
  };

  const handleClickOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };
  // Fetching content data (simulate fetching with hardcoded data)
  useEffect(() => {
    const fetchUnit = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/ui/${id}`
        );
        console.log(response.data);

        // Check if the response has unitsPlans property
        if (response.data && response.data.unitsPlans) {
          // Add "edit": 0 to each item in units_content
          setUnitData(response.data.units);
          const updatedUnitsContent =
            response.data.unitsPlans.units_content.map((item) => ({
              ...item,
              edit: 0, // Add the edit property
            }));

          // Set the contentData with updated units_content
          setUnit(response.data.units)
          setIndexes(response.data.units.position)
          setContentData({
            ...response.data.unitsPlans,
            units_content: updatedUnitsContent,
          });
        } else {
          setError("No units plans found");
        }
      } catch (err) {
        setError(err.message || "Failed to fetch unit data");
      } finally {
        setLoading(false);
      }
    };
    console.log("updated code", contentData);
    fetchUnit();
    // setContentData(contentDataaaa);
    setLoading(false);
    setpdfLoading(false);
    setGalleryLoading(false);
    setVideoLoading(false);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleIndexChange = (value) => {
    setIndexes(value);
    console.log("------------------", value);
    
  };
  // Handle content change
  const handleContentChange = (index, value) => {
    const updatedContentData = { ...contentData };
    updatedContentData.units_content[index].data = value;
    setContentData(updatedContentData);
  };
  // Handle content change
  const handleHeadingContentChange = (index, value) => {
    const updatedContentData = { ...contentData };
    updatedContentData.units_content[index].data = `<p>${value}</p>`;
    setContentData(updatedContentData);
  };
  const handleUrlChange = (index, value) => {
    const updatedContentData = { ...contentData };
    updatedContentData.units_content[index].videoUrl = value;
    setContentData(updatedContentData);
  };
  const handleYoutubeUrlChange = (index, value) => {
    const updatedContentData = { ...contentData };
    updatedContentData.units_content[index].youtubeUrl = value;
    setContentData(updatedContentData);
  };

  const handlePdfUploadChange = async (index, event) => {
    const file = event.target.files[0];
    if (!file) return; // Prevent execution if no file is selected

    const formData = new FormData();
    formData.append("pdfFiles", file); // Append the file to FormData

    try {
      setpdfLoading(true); // Set loading state

      // Send a POST request with FormData
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify the content type
          },
        }
      );

      // Handle the response
      console.log("File upload response:", response.data.fileUrl);

      // Update contentData with the file URL
      const updatedContentData = { ...contentData };
      if (!updatedContentData.units_content[index]) {
        updatedContentData.units_content[index] = { type: "pdf", data: [] };
      }

      // Clear the 'data' array and set the uploaded file URL in 'pdfFiles'
      updatedContentData.units_content[index].data = [response.data.fileUrl]; // Assuming response contains the URL
      // updatedContentData.units_content[index].pdfFiles = file.name; // Store the file name or URL as needed

      setContentData(updatedContentData);
      console.log("contentdaat----------", contentData);
    } catch (err) {
      setError(err.message || "Failed to upload file");
    } finally {
      setpdfLoading(false); // Reset loading state
    }
  };

  const handleVideoUploadChange = async (index, event) => {
    const file = event.target.files[0];
    if (!file) return; // Prevent execution if no file is selected

    const formData = new FormData();
    formData.append("videoFile", file); // Append the file to FormData

    try {
      setVideoLoading(true); // Set loading state

      // Send a POST request with FormData
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify the content type
          },
        }
      );

      // Handle the response
      console.log("File upload response:", response.data.fileUrl);

      // Update contentData with the file URL
      const updatedContentData = { ...contentData };
      if (!updatedContentData.units_content[index]) {
        updatedContentData.units_content[index] = {
          type: "videoUpload",
          data: [],
        };
      }

      // Clear the 'data' array and set the uploaded file URL in 'pdfFiles'
      updatedContentData.units_content[index].data = [response.data.fileUrl]; // Assuming response contains the URL
      // updatedContentData.units_content[index].pdfFiles = file.name; // Store the file name or URL as needed

      setContentData(updatedContentData);
      console.log("contentdaat----------", contentData);
    } catch (err) {
      setError(err.message || "Failed to upload file");
    } finally {
      setVideoLoading(false); // Reset loading state
    }
  };

  const handleGalleryChange = async (index, imageIndex, event) => {
    const file = event.target.files[0]; // Only handle one file

    if (!file) return;

    const formData = new FormData();
    formData.append("imageFile", file); // Append the selected file

    try {
      setGalleryLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedContentData = { ...contentData };

      // Replace the specific image at imageIndex with the new URL
      updatedContentData.units_content[index].data[imageIndex] =
        response.data.fileUrl; // Assuming response contains the URL

      setContentData(updatedContentData);
    } catch (err) {
      console.error(err.message || "Failed to upload file");
    } finally {
      setGalleryLoading(false);
    }
  };
  const handleSingleImageChange = async (index, event) => {
    const file = event.target.files[0]; // Only handle one file

    if (!file) return;

    const formData = new FormData();
    formData.append("imageFile", file); // Append the selected file

    try {
      setGalleryLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedContentData = { ...contentData };

      // Replace the specific image at imageIndex with the new URL
      updatedContentData.units_content[index].data = response.data.fileUrl; // Assuming response contains the URL

      setContentData(updatedContentData);
    } catch (err) {
      console.error(err.message || "Failed to upload file");
    } finally {
      setGalleryLoading(false);
    }
  };

  // Save updated content (mocked save functionality)
  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Simulate API call to save the edited content data
      console.log("Saving edited content:", contentData.units_content);

      // Perform your save request here, e.g., using axios
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/unitupload/editunit/${contentData._id}`,
        contentData
      );
      if (response.status) {
        // alert("Content saved successfully!");
        console.log("Content saved successfully:", contentData);
        // navigate(`/unitinterface/${contentData.units_id}`);
      }
    } catch (error) {
      console.error("Error saving content:", error);
    } finally {
      setIsSaving(false);
    }
  };
  const toggleEditIndex = async () => {
    if (isEditing22) {
      // If already in editing mode, save the data
      try {
        setGalleryLoading(true); // Start loading state
        console.log("Sending indexes:", indexes);
        // Await the API call to ensure data is saved before switching mode
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/unitupload/index/${id}`,
          {
            position: indexes,
          },
          {
            headers: {
              // "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
          }
        );
  
        // Once the response is received, update the state with the new unit data
        setUnit(response.data);
  
        // Optionally, handle success message or any additional actions here
        console.log("Data saved successfully:", response.data);
      } catch (err) {
        // Handle error state
        console.error(err.message || "Failed to upload file");
      } finally {
        setGalleryLoading(false); // End loading state regardless of success or failure
      }
  
      // Switch to non-editing mode after saving
      setIsEditing22(false);
    } else {
      // Switch to editing mode
      setIsEditing22(true);
    }
  };

  return (
    <>
      <Container
        className="Nandhu"
        width="100%"
        sx={{
          width: "100%",
          padding: "0px !important",
        }}
      >
        <Box width={"100%"} sx={{ backgroundColor: "#9e503d", height: "auto" }}>
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: "15px", color: "#fff" }}
            >
              <ArrowRightIcon sx={{ color: "#fff" }} /> {unitData?.course_id?.courses_name}
              <ArrowRightIcon sx={{ color: "#fff" }} />{unitData?.chapter_id?.chapter_name}
              <ArrowRightIcon sx={{ color: "#fff" }} /> {unitData?.unit_name}
            </Typography>
          </Box>
        </Box>
        {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
              Index position
            </Typography>
          </Box>
          <IconButton onClick={toggleEditIndex} sx={{ ml: 2 }}>
            {isEditing22 ? <SaveIcon /> : <EditIcon />}  
          </IconButton>
          <TextField
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="Index"
            label="Index"
            sx={{ marginBottom: 3 }}
            value={indexes}  // Assuming you want to control the value with 'indexes'
            onChange={(e) => handleIndexChange(e.target.value)}  // Pass the value correctly
            disabled={!isEditing22}  // Disable text field when not editing
          />
        </Box> */}

        {loading ||
        !contentData ||
        !contentData.units_content ||
        contentData.units_content.length === 0 ? (
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <Box>
            {contentData.units_content.map((item, index) => {
              switch (item.type) {
                case "unitHeading":
                  const isDataEditable1 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Unit Heading
                          </Typography>

                          <Box>
                            <IconButton onClick={toggleEdit1} sx={{ ml: 2 }}>
                              {isDataEditable1 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            {/* <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton> */}
                          </Box>
                        </Box>

                        <Box width={"100%"}>
                          {isDataEditable1 ? (
                            <textarea
                              value={item.data}
                              onChange={(e) =>
                                handleContentChange(index, e.target.value)
                              }
                              style={{
                                width: "100%",
                                height: "100px",
                                resize: "none",
                              }} // Adjust height as needed
                            />
                          ) : (
                            <Typography
                              variant="h4"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Heading1":
                  const isDataEditable2 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Heading 1
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable2 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable2 ? (
                            // <ReactQuill
                            //   theme="snow"
                            //   value={item.data}
                            //   onChange={(value) =>
                            //     handleContentChange(index, value)
                            //   }
                            // />
                            <TextField
                              label="Heading" // Optional: label for the TextField
                              value={item.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                              onChange={(e) =>
                                handleHeadingContentChange(
                                  index,
                                  e.target.value
                                )
                              } // Handle text change
                              variant="outlined" // TextField variant (outlined, filled, etc.)
                              style={{
                                width: "100%",
                                resize: "none", // Disable resizing
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Heading2":
                  const isDataEditable3 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Heading 2
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable3 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable3 ? (
                            // <ReactQuill
                            //   theme="snow"
                            //   value={item.data}
                            //   onChange={(value) =>
                            //     handleContentChange(index, value)
                            //   }
                            // />
                            <TextField
                              label="Heading" // Optional: label for the TextField
                              value={item.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                              onChange={(e) =>
                                handleHeadingContentChange(
                                  index,
                                  e.target.value
                                )
                              } // Handle text change
                              variant="outlined" // TextField variant (outlined, filled, etc.)
                              style={{
                                width: "100%",
                                resize: "none", // Disable resizing
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Subheading1":
                  const isDataEditable4 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            SubHeading 1
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable4 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable4 ? (
                            // <ReactQuill
                            //   theme="snow"
                            //   value={item.data}
                            //   onChange={(value) =>
                            //     handleContentChange(index, value)
                            //   }
                            // />
                            <TextField
                              label="Heading" // Optional: label for the TextField
                              value={item.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                              onChange={(e) =>
                                handleHeadingContentChange(
                                  index,
                                  e.target.value
                                )
                              } // Handle text change
                              variant="outlined" // TextField variant (outlined, filled, etc.)
                              style={{
                                width: "100%",
                                resize: "none", // Disable resizing
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Subheading2":
                  const isDataEditable5 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Sub Heading 2
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable5 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable5 ? (
                            // <ReactQuill
                            //   theme="snow"
                            //   value={item.data}
                            //   onChange={(value) =>
                            //     handleContentChange(index, value)
                            //   }
                            // />
                            <TextField
                              label="Heading" // Optional: label for the TextField
                              value={item.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                              onChange={(e) =>
                                handleHeadingContentChange(
                                  index,
                                  e.target.value
                                )
                              } // Handle text change
                              variant="outlined" // TextField variant (outlined, filled, etc.)
                              style={{
                                width: "100%",
                                resize: "none", // Disable resizing
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "paragraph":
                  const isDataEditable6 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Paragraph
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable6 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable6 ? (
                            // <ReactQuill
                            //   theme="snow"
                            //   value={item.data}
                            //   onChange={(value) =>
                            //     handleContentChange(index, value)
                            //   }
                            // />
                            <ReactQuill
                              theme="snow"
                              value={item.data}
                              onChange={(value) =>
                                handleContentChange(index, value)
                              }
                              modules={{ toolbar: toolbarOptions }}
                            />
                          ) : (
                            <>
                          <style>
                            {`
                              .ql-size-small {
                                font-size: 12px;
                              }

                              .ql-size-large {
                                font-size: 20px;
                              }

                              .ql-size-huge {
                                font-size: 30px;
                              }

                              .ql-align-justify {
                                text-align: justify;
                              }

                              .ql-align-center {
                                text-align: center;
                              }

                              .ql-align-right {
                                text-align: right;
                              }

                              .ql-font-monospace {
                                font-family: monospace;
                              }
                            `}
                          </style>
                            <Typography
                              variant="p"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                            </>
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );

                case "videoUrl":
                  const isDataEditable10 =
                    item.videoUrl === "" ||
                    // item.videoUrl.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Redirection Button URL
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable10 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable10 ? (
                            // <ReactQuill
                            //   theme="snow"
                            //   value={item.data}
                            //   onChange={(value) =>
                            //     handleContentChange(index, value)
                            //   }
                            // />
                            <>
                            <TextField
                              label="Button URL" // Optional: label for the TextField
                              value={item.videoUrl} // Remove <p> tags for editing in TextField
                              onChange={(e) =>
                                handleUrlChange(index, e.target.value)
                              } // Handle text change
                              variant="outlined" // TextField variant (outlined, filled, etc.)
                              style={{
                                width: "100%",
                                resize: "none", // Disable resizing
                                margin: "0px 0px 15px 0px"
                              }}
                            />
                            <TextField
                              label="Button URL" // Optional: label for the TextField
                              value={item.data} // Remove <p> tags for editing in TextField
                              onChange={(e) =>
                                handleContentChange(index, e.target.value)
                              } // Handle text change
                              variant="outlined" // TextField variant (outlined, filled, etc.)
                              style={{
                                width: "100%",
                                resize: "none", // Disable resizing
                              }}
                            />
                            </>
                          ) : (
                            <Typography variant="h5">
                              {item.videoUrl}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "youtubeUrl":
                  const isDataEditable11 =
                    item.youtubeUrl === "" ||
                    // item.youtubeUrl.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Youtube Video URL
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable11 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable11 ? (
                            // <ReactQuill
                            //   theme="snow"
                            //   value={item.data}
                            //   onChange={(value) =>
                            //     handleContentChange(index, value)
                            //   }
                            // />
                            <TextField
                              label="Youtube Video URL" // Optional: label for the TextField
                              value={item.youtubeUrl} // Remove <p> tags for editing in TextField
                              onChange={(e) =>
                                handleYoutubeUrlChange(index, e.target.value)
                              } // Handle text change
                              variant="outlined" // TextField variant (outlined, filled, etc.)
                              style={{
                                width: "100%",
                                resize: "none", // Disable resizing
                              }}
                            />
                          ) : (
                            <iframe
                              width="100%"
                              height="920px"
                              src={`https://www.youtube.com/embed/${
                                item.youtubeUrl.split("v=")[1]
                              }`}
                              title="YouTube Video"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );

                case "pdf":
                  const isDataEditable7 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return pdfLoading ? (
                    <Box sx={{ textAlign: "center", padding: "20px" }}>
                      <CircularProgress />
                      <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Loading...
                      </Typography>
                    </Box>
                  ) : (
                    <Container key={index} width={"100%"}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            mb: 2,
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            PDF Upload
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable7 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          {item.data.map((url, pdfIndex) => (
                            <Box
                              key={pdfIndex}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              {error && (
                                <Typography
                                  color="error"
                                  variant="body2"
                                  sx={{ mb: 2 }}
                                >
                                  Error: {error.message}
                                </Typography>
                              )}
                              <Box
                                sx={{ maxHeight: "25%", width: "25%", p: 1 }}
                              >
                                {url && (
                                <Document
                                  className="stypdf"
                                  file={url}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                  onLoadError={setError}
                                  loading={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        py: 4,
                                      }}
                                    >
                                      <CircularProgress />
                                    </Box>
                                  }
                                >
                                  <Box sx={{ mb: 2 }}>
                                    <Page
                                      pageNumber={1}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                      scale={0.8}
                                      width={
                                        document.querySelector("body")
                                          .offsetWidth - 0
                                      }
                                    />
                                  </Box>
                                </Document>
                                )}
                              </Box>
                            </Box>
                          ))}
                          {isDataEditable7 ? (
                            <TextField
                              name="pdfFiles"
                              type="file"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                accept: "application/pdf",
                                multiple: false,
                              }}
                              onChange={(e) => handlePdfUploadChange(index, e)}
                              sx={{ marginBottom: 3 }}
                            />
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );

                case "videoUpload":
                  const isDataEditable8 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return videoLoading ? (
                    <Box sx={{ textAlign: "center", padding: "20px" }}>
                      <CircularProgress />
                      <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Loading...
                      </Typography>
                    </Box>
                  ) : (
                    <Container key={index} width={"100%"}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            mb: 2,
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Video Upload
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable8 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: 2,
                            width: "100%",
                          }}
                        >
                          {item.data[0] && (
                          <video
                            controls
                            style={{
                              width: "100%",
                              height: "700px",
                              borderRadius: "8px",
                              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                              marginRight: "16px", // Add margin between video and file upload
                            }}
                            onError={(e) => {
                              console.error('Error loading video:', e);
                              console.error('Video source error:', e.nativeEvent);
                            }}
                          >
                          <source src={item.data[0]} type="video/mp4" />
                          <source src={item.data[0]} type="video/webm" />
                          <source src={item.data[0]} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                          )}
                          {isDataEditable8 ? (
                            <TextField
                              name="videoFile"
                              type="file"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                accept: "video/mp4",
                                multiple: false,
                              }}
                              onChange={(e) =>
                                handleVideoUploadChange(index, e)
                              }
                              sx={{ marginBottom: 3, mt: 2 }}
                            />
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );

                case "gallery": {
                  const isDataEditable9 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;

                  return galleryLoading ? (
                    <Box sx={{ textAlign: "center", padding: "20px" }}>
                      <CircularProgress />
                      <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Loading...
                      </Typography>
                    </Box>
                  ) : (
                    <Container width="100%">
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          maxWidth: "1200px",
                          margin: "0 auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 2,
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Gallery
                          </Typography>

                          <IconButton
                            onClick={() => handleDeleteDialog(index)} // Call the delete handler
                            sx={{ ml: 2 }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <ImageGrid>
                          {item.data.map((url, imageIndex) => (
                            <Box
                              key={imageIndex}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginBottom: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  sx={{ mr: 2, color: "#bb6767" }}
                                >
                                  Gallery image {imageIndex + 1}
                                </Typography>

                                <Box>
                                  <IconButton
                                    onClick={() => toggleEdit(index)}
                                    sx={{ ml: 2 }}
                                  >
                                    {isDataEditable9 ? (
                                      <SaveIcon />
                                    ) : (
                                      <EditIcon />
                                    )}
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleGalleryDeleteDialog(
                                        index,
                                        imageIndex
                                      )
                                    }
                                    sx={{ ml: 2 }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  marginBottom: 2,
                                  width: "100%",
                                }}
                              >
                                {url && (
                                <img
                                  src={url}
                                  alt="Gallery"
                                  onClick={() => handleClickOpen(url)}
                                  style={{
                                    width: "100%",
                                    // height: "250px",
                                    cursor: "pointer",
                                  }}
                                />
                                )}
                                {isDataEditable9 && (
                                  <>
                                    <TextField
                                      type="file"
                                      variant="outlined"
                                      margin="normal"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(e) =>
                                        handleGalleryChange(
                                          index,
                                          imageIndex,
                                          e
                                        )
                                      }
                                      sx={{ marginTop: 1, width: "100%" }}
                                    />
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={(event) =>
                                        handleImageContentTypeChange(
                                          index,
                                          imageIndex,
                                          event
                                        )
                                      }
                                      sx={{ 
                                        marginTop: 1,
                                        backgroundColor: "#e0e0e0"
                                      }}
                                    >
                                      Add Image
                                    </Button>
                                  </>
                                )}
                              </Box>
                            </Box>
                          ))}
                        </ImageGrid>

                        <Dialog open={open} onClose={handleClose} maxWidth="lg">
                          <DialogContent>
                            <img
                              src={selectedImage}
                              alt="Selected"
                              style={{ width: "250px", height: "250px" }}
                            />
                          </DialogContent>
                        </Dialog>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                }

                case "singleimage": {
                  const isDataEditable9 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;

                  return galleryLoading ? (
                    <Box sx={{ textAlign: "center", padding: "20px" }}>
                      <CircularProgress />
                      <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Loading...
                      </Typography>
                    </Box>
                  ) : (
                    <Container width="100%">
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          maxWidth: "1200px",
                          margin: "0 auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 2,
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2, color: "#bb6767" }}>
                            Single Image
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable9 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDialog(index)} // Call the delete handler
                              // sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        { item.data && (<img
                          src={item.data}
                          alt="Gallery"
                          onClick={() => handleClickOpen(item.data)}
                          style={{
                            width: "100%",
                            // height: "250px",
                            cursor: "pointer",
                          }}
                        />)}
                        {isDataEditable9 && (
                          <>
                            <TextField
                              type="file"
                              variant="outlined"
                              margin="normal"
                              inputProps={{ accept: "image/*" }}
                              onChange={(e) =>
                                handleSingleImageChange(index, e)
                              }
                              sx={{ marginTop: 1, width: "100%" }}
                            />
                            {/* <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={(event) => handleImageContentTypeChange(index, imageIndex, event )}
                                      sx={{ marginTop: 1 }}
                                    >
                                      Add Image
                                    </Button> */}
                          </>
                        )}
                        <Dialog open={open} onClose={handleClose} maxWidth="lg">
                          <DialogContent>
                            <img
                              src={selectedImage}
                              alt="Selected"
                              style={{ width: "250px", height: "250px" }}
                            />
                          </DialogContent>
                        </Dialog>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="singleimage">
                              Single Image
                            </MenuItem>

                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="youtubeUrl">Youtube video URL</MenuItem>
                            <MenuItem value="videoUrl">
                              Redirect Button URL
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                }

                default:
                  return null;
              }
            })}
          </Box>
        )}

        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              isSaving ||
              isEditing ||
              pdfLoading ||
              galleryLoading ||
              videoLoading
            } // Disable if either isSaving or pdfLoading is true
          >
            {isSaving ? "Saving..." : "Save Changes"}
          </Button> */}
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {/* Confirmation Dialog for Deletion */}
        <Dialog open={confirmDelete} onClose={handleCloseConfirmDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this item?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Dialog for Image Deletion */}
        <Dialog open={confirmDeleteGallery} onClose={handleCloseConfirmDialog}>
          <DialogTitle>Confirm Image Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this image?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDeleteGallery} // Perform deletion
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default EditUnit;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Container,
  Autocomplete, // Import Autocomplete
  TextField, 
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";
import { FormControl as ReactBootstrapFormControl } from "react-bootstrap";

const PdfList = () => {
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]); 
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState(""); 
  const [courseCategories, setCourseCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [selectedCourses, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);
  
// chapters filter states
const [selectedChapters, setSelectedChapters] = useState("");

const [chapters, setChapters] = useState([]);
  

  const navigate = useNavigate();

  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchCourseSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPdfs(subcategoryResponse1.data.data);
        console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses/getcourseby/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCourses(subcategoryResponse4.data.data);
        console.log("get courses :", subcategoryResponse4.data.data);

        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPdfs(subcategoryResponse2.data.data);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }

      if (selectedCourses) {
        const subcategoryChapterResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chapters/bycourse/${selectedCourses}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setChapters(subcategoryChapterResponse.data.data);
        console.log("get courses :", subcategoryChapterResponse.data.data);


        const subcategoryResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}/${selectedSubCategory}/${selectedCourses}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPdfs(subcategoryResponse9.data.data);
        console.log(
          "filtered units with course:",
          subcategoryResponse9.data.data
        );
      }

      if(selectedChapters){
        const subcategorychapterResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}/${selectedSubCategory}/${selectedCourses}/${selectedChapters}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPdfs(subcategorychapterResponse9.data.data);
        console.log(
          "filtered units with chapters:",
          subcategorychapterResponse9.data.data
        );
      }

    } catch (err) {
      setError("Failed to fetch chapters");
    }
  };

  const fetchPdfs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/list/unitslist`,
        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            category, 
            subcategory,
            search: searchTerm,
          },
        }
      );
      setPdfs(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError("Error fetching units");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPdfs();
    fetchCourseCategories();
    fetchCourseSubCategories();
      }, [currentPage, itemsPerPage, category, subcategory, searchTerm, selectedCategory, selectedSubCategory, selectedCourses, selectedChapters,]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
    setPage(0);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); 
    setCurrentPage(0);
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(0);
  };

  const handleChapterChange = (event) => {
    setSelectedChapters(event.target.value);
    setCurrentPage(0);
  };


  const handleDeleteConfirmation = (unit) => {
    setUnitToDelete(unit);
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    if (!unitToDelete) return;
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/list/deleteunit/${unitToDelete._id}`
      );
      setOpenDialog(false);
      fetchPdfs();
    } catch (err) {
      setError("Failed to delete Unit");
      console.error("Error while deleting unit:", err.response || err);
    }
  };

  const handleView = (id, coursesname, chaptername, unitname) => {
    navigate(`/unitinterface/${id}`, {
      state: { coursesname, chaptername, unitname },
    });
  };

  const handleEdit = (id, coursesname, chaptername, unitname) => {
    const url = `/editunit/${id}/${encodeURIComponent(
      coursesname
    )}/${encodeURIComponent(chaptername)}/${encodeURIComponent(unitname)}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0", mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Unit List
        </Typography>
      </Box>

      {/* Autocomplete for Search */}
      <Box mb={2} display="flex" justifyContent="flex-start">
        <Autocomplete
          value={searchTerm}
          onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
          options={pdfs.map((pdf) => pdf.unit_name)}
          renderInput={(params) => (
            <TextField {...params} label="Search Units" variant="outlined" size="small" />
          )}
          freeSolo
          sx={{ width: "300px" }}
        />

        {/* Category Dropdown */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>course Category</InputLabel>
          <Select
            value={selectedCategory || ""}
            label="School Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="">All Categories</MenuItem>
            {courseCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.courses_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Subcategory Dropdown */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Course Subcategory</InputLabel>
          <Select
            value={selectedSubCategory || ""}
            label="Subcategory"
            onChange={handleSubCategoryChange}
          >
            <MenuItem value="">All Subcategories</MenuItem>
            {courseSubCategories.map((subCategory) => (
              <MenuItem key={subCategory._id} value={subCategory._id}>
                {subCategory.courses_sub_category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Courses Dropdown */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Courses</InputLabel>
          <Select
            value={selectedCourses || ""}
            label="Courses"
            onChange={handleCourseChange}
          >
            <MenuItem value="">All Courses</MenuItem>
            {courses.map((school) => (
              <MenuItem key={school._id} value={school._id}>
                {school.courses_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

 {/* Chapters Dropdown */}
 <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Chapters</InputLabel>
          <Select
            value={selectedChapters || ""}
            label="Chapters"
            onChange={handleChapterChange}
          >
            <MenuItem value="">All Chapters</MenuItem>
            {chapters.map((chapter) => (
              <MenuItem key={chapter._id} value={chapter._id}>
                {chapter.chapter_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </Box>

      {/* Table, Pagination, and Dialogs */}
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Unit Name</TableCell>
                  <TableCell>Course Category</TableCell>
                  <TableCell>Course Subcategory</TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Chapter Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pdfs.length > 0 ? (
                  pdfs.map((pdf, index) => (
                    <TableRow key={pdf._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{pdf.unit_name || "N/A"}</TableCell>
                      <TableCell>
                        {pdf.course_category?.courses_name || "N/A"}
                      </TableCell>
                      <TableCell>
                        {pdf.course_sub_category?.courses_sub_category_name || "N/A"}
                      </TableCell>
                      <TableCell>{pdf.course?.courses_name || "N/A"}</TableCell>
                      <TableCell>{pdf.chapterData?.chapter_name || "N/A"}</TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => handleView(pdf._id, pdf.course_category?.courses_name, pdf.chapterData?.chapter_name, pdf.unit_name)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteConfirmation(pdf)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() => handleEdit(pdf._id, pdf.course_category?.courses_name, pdf.chapterData?.chapter_name, pdf.unit_name)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No PDFs found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {totalItems > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={totalItems}
              rowsPerPage={itemsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the unit "{unitToDelete?.unit_name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PdfList;

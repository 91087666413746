import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuizIcon from "@mui/icons-material/Quiz";
import ProjectIcon from "@mui/icons-material/Build";
import CommentIcon from "@mui/icons-material/Comment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { fontWeight } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SlideshowIcon from "@mui/icons-material/Slideshow"; // For Slider
import { getToken } from "../../utils/AuthUtils";
import axios from "axios";

const dashboardData = [
  {
    title: "NO OF SCHOOLS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
  {
    title: "NO OF TEACHERS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
  {
    title: "NO OF STUDENTS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
  {
    title: "PROJECTS SUBMITTED",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
  {
    title: "NO OF ASSESSMENTS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
  {
    title: "NO OF QUIZZES",
    number1: 40,
    number2: 32,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
  {
    title: "NO OF TICKETS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
  {
    title: "TASKS SUBMITTED",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#9f937b",
  },
];

const progressData = [
  { value: 33, label: "COURSES", icon: MenuBookIcon },
  { value: 33, label: "CHAPTERS", icon: GroupWorkIcon },
  { value: 33, label: "UNITS", icon: SlideshowIcon },
  { value: 33, label: "QUIZZES", icon: QuizIcon },
  { value: 4, label: "PROJECTS", icon: ProjectIcon },
  { value: 13, label: "COMMENTS", icon: CommentIcon },
];

const circleData = [
  { title: "Score", value: 67 },
  { title: "Teacher Rating", value: 5 },
  { title: "Quiz", value: 73 },
  { title: "Class Average", value: 67 },
  { title: "Engagement", value: 44 },
  { title: "Discussion", value: 56 },
  { title: "Consistency", value: 78 },
  { title: "Critical Thinking", value: 34 },
  { title: "Creative Thinking", value: 52 },
];

const Dashboard = () => {
  const scrollRef = useRef(null);

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };
  const [dashboardCounts, setDashboardCounts] = useState({
    schools: 0,
    teachers: 0,
    students: 0,
  });
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const token = getToken();
        if (token) {
        const schoolResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school/current-user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const coursesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/getassigned_courses_by_partner`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                const chaptersResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chapters`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                const unitsResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/unit`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
        const teacherResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/teachers`);
        const studentResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/student`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const assessmentResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assessment`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        setDashboardCounts({
          schools: schoolResponse.data.data.length, // Adjust based on the actual response structure
          teachers: teacherResponse.data.data.length,
          students: studentResponse.data.data.length,
          assessments: assessmentResponse.data.data.length,
          courses: coursesResponse.data.data.length,
          chapters: chaptersResponse.data.data.length,
          units: unitsResponse.data.data.length,
        });
        console.log("School count:",assessmentResponse.data.data.length);
        
      }} catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCounts();
  }, []); 
  const dashboardData = [
    {
      title: "NO OF SCHOOLS",
      number1: dashboardCounts.schools,
      number2: 36,
      color1: "#fde0a9",
      color2: "#9f937b",
    },
    {
      title: "NO OF TEACHERS",
      number1: dashboardCounts.teachers,
      number2: 36,
      color1: "#fde0a9",
      color2: "#9f937b",
    },
    {
      title: "NO OF STUDENTS",
      number1: dashboardCounts.students,
      number2: 36,
      color1: "#fde0a9",
      color2: "#9f937b",
    },
    // {
    //   title: "PROJECTS SUBMITTED",
    //   number1: 40,
    //   number2: 36,
    //   color1: "#fde0a9",
    //   color2: "#9f937b",
    // },
    // {
    //   title: "NO OF ASSESSMENTS",
    //   number1: dashboardCounts.assessments,
    //   number2: 36,
    //   color1: "#fde0a9",
    //   color2: "#9f937b",
    // },
    // {
    //   title: "NO OF QUIZZES",
    //   number1: dashboardCounts.assessments,
    //   number2: 32,
    //   color1: "#fde0a9",
    //   color2: "#9f937b",
    // },
    // {
    //   title: "NO OF TICKETS",
    //   number1: 40,
    //   number2: 36,
    //   color1: "#fde0a9",
    //   color2: "#9f937b",
    // },
    // {
    //   title: "TASKS SUBMITTED",
    //   number1: 40,
    //   number2: 36,
    //   color1: "#fde0a9",
    //   color2: "#9f937b",
    // },
  ];
  const progressData = [
    { value: dashboardCounts.courses, label: "COURSES", icon: MenuBookIcon },
    { value: dashboardCounts.chapters, label: "CHAPTERS", icon: GroupWorkIcon },
    { value: dashboardCounts.units, label: "UNITS", icon: SlideshowIcon },
    { value: dashboardCounts.assessments, label: "QUIZZES", icon: QuizIcon },
    // { value: 4, label: "PROJECTS", icon: ProjectIcon },
    // { value: 13, label: "COMMENTS", icon: CommentIcon },
  ];
  return (
    <Container maxWidth="xl" sx={{ paddingY: 4, overflowX: "hidden" }}>
      <Grid container spacing={2}>
        {dashboardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <DashboardCard {...card} />
          </Grid>
        ))}

        <Grid container item xs={12} spacing={2}>
          {["SCHOOLS", "TEACHERS", "STUDENTS", "PROJECTS"].map(
            (type, index) => (
              <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                <RatingCard
                  title={`${type} RATING`}
                  excellent={40}
                  good={22}
                  average={12}
                  bad={6}
                  barWidths={[100, 100, 100, 100]}
                />
              </Grid>
            )
          )}
        </Grid>

        <Grid container item xs={12} spacing={2} sx={{ paddingTop: 2 }}>
          {progressData.map((item, index) => (
            <Grid item key={index}>
              <Progress
                score={item.value}
                total={100}
                title={item.label}
                IconComponent={item.icon}
              />
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            overflow: "hidden",
            paddingX: { xs: 0, sm: 2 },
            mt: 4,
          }}
        >
          <Button
            onClick={handleScrollLeft}
            variant="contained"
            sx={{ minWidth: "0px", backgroundColor: "#e1ded9", width: "20px" }}
          >
            <ArrowBackIosNewIcon />
          </Button>

          <Box
            ref={scrollRef}
            sx={{
              display: "flex",
              overflowX: "auto",
              scrollSnapType: "x mandatory",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              width: "calc(100% - 100px)",
              height: "200px",
            }}
          >
            {circleData.map((circle, index) => (
              <Box
                key={index}
                sx={{
                  flexShrink: 0,
                  scrollSnapAlign: "start",
                  padding: {
                    xs: "0",
                    md: "0 30px",
                  },
                  width: { xs: "120px", sm: "200px" },
                }}
              >
                <CircleCard title={circle.title} value={circle.value} />
              </Box>
            ))}
          </Box>

          <Button
            onClick={handleScrollRight}
            variant="contained"
            sx={{ minWidth: "0px", backgroundColor: "#e1ded9", width: "20px" }}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};

const DashboardCard = ({ title, number1, number2, color1, color2 }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      borderRadius: "5px",
      // boxShadow: 3,
      backgroundColor: "#e1ded9",
      padding: { xs: "12px", sm: "16px", md: "20px" },
    }}
  >
    {/* Title section with improved handling for long text */}
    <Typography
      variant="h6"
      align="center"
      fontWeight="300"
      sx={{
        marginBottom: { xs: 1, sm: 2 },
        fontSize: { xs: "16px", sm: "18px", md: "20px" },
        whiteSpace: "normal",
        // overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "48px", // Adjust this as needed
        lineHeight: "1.2em",
        display: "-webkit-box",
        WebkitLineClamp: 2, // Ensures the title is limited to two lines
        WebkitBoxOrient: "vertical",
      }}
    >
      {title}
    </Typography>

    {/* Inner cards, fixed layout to prevent shifting */}
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      {[number1, number2].map((num, idx) => (
        <Grid item xs={5} key={idx}>
          <Box
            sx={{
              backgroundColor: idx === 0 ? color2 : "#f5f1ec!important",
              color: idx === 0 ? "#fff" : "rgba(157 155 152)",
              textAlign: "center",
              padding: { xs: "10px" },
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { sm: "17px", md: "19px", lg: "22px" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {num}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const RatingCard = ({ title, excellent, good, average, bad, barWidths }) => {
  const ratings = [
    {
      label: "EXCELLENT",
      value: excellent,
      color: "#ebe6e2",
      width: barWidths[2],
      textColor: "#919191",
    },
    {
      label: "GOOD",
      value: good,
      color: "#c9c1b2",
      width: barWidths[2],
      textColor: "#898989",
    },
    {
      label: "AVERAGE",
      value: average,
      color: "#b4aca1",
      width: barWidths[2],
      textColor: "#e5e5e5",
    },
    {
      label: "BAD",
      value: bad,
      color: "#948b82",
      width: barWidths[2],
      textColor: "#fff",
    },
  ];

  return (
    <Card
      sx={{
        borderRadius: "6px",
        backgroundColor: "#e1ded9",
        marginTop: { xs: "10px", sm: "20px", md: "60px" },
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          align="center"
          sx={{ fontWeight: "300", marginBottom: "15px" }}
        >
          {title}
        </Typography>
        <Grid container spacing={0.2}>
          {ratings.map((rating, index) => (
            <Grid item xs={12} key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {/* Bar (Label) on the Left */}
                <Box
                  sx={{
                    backgroundColor: rating.color,
                    color: rating.textColor, // Apply the dynamic text color
                    padding: "8px 16px",
                    width: `${rating.width}%`, // Customize the width with percentage
                    textAlign: "left",
                  }}
                >
                  <Typography variant="body2">{rating.label}</Typography>
                </Box>

                {/* Set of Numbers on the Right */}
                <Box display="flex" alignItems="center">
                  {/* Left number set */}
                  <Box
                    sx={{
                      backgroundColor: "#796d57", // Same color as the rating bar
                      padding: "8px",
                      width: "55px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body2">{rating.value}</Typography>
                  </Box>

                  {/* Right number set */}
                  <Box
                    sx={{
                      backgroundColor: "#9f937a", // Same color as the rating bar
                      padding: "8px",
                      width: "50px",
                      color: "#fff !important",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body2">{rating.value}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
const Progress = ({ score, title, IconComponent }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle width
        height: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle height
        position: "relative",
        marginTop: { xs: "10px", sm: "20px", md: "60px" },
        marginLeft: "20px",
      }}
    >
      <Box
        sx={{
          width: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle width
          height: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle height
          borderRadius: "50%",
          borderColor: "#e1dcd9 !important",
          backgroundColor: "#93876f",
          border: "8px solid #ac3a38", // Adjusted border size for consistency
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {IconComponent && (
          <IconComponent
            sx={{
              fontSize: { xs: "20px", sm: "30px", md: "35px" }, // Responsive icon size
              position: "absolute",
              color: "white",
              bottom: { xs: "5px", sm: "10px", md: "15px" }, // Adjust bottom position based on screen size
              fontWeight: "400",
            }}
          />
        )}
        <Typography
          variant="h6"
          sx={{
            color: "white",
            fontSize: { xs: "18px", sm: "22px", md: "25px" }, // Responsive font size for the score
            zIndex: 1,
            position: "absolute",
            top: { xs: "5px", sm: "8px", md: "10px" }, // Adjust position based on screen size
          }}
        >
          {score}
        </Typography>
      </Box>

      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
          fontSize: { xs: "12px", sm: "14px" }, // Responsive title font size
          textAlign: "center",
          fontWeight: "300",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const CircleCard = ({ title, value }) => {
  return (
    <Box
      sx={{
        width: { xs: "90px", sm: "110px", md: "130px" }, // Responsive width
        height: { xs: "90px", sm: "110px", md: "130px" }, // Responsive height
        position: "relative",
        marginX: { xs: "4px", sm: "6px", md: "8px" }, // Responsive margin
        marginTop: { xs: "10px", sm: "20px", md: "40px" },
        padding: "7px",
      }}
    >
      {/* Full circle */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: "10px solid #e1dcd9", // Circle stroke color
          position: "relative",
          marginRight: "10px",
        }}
      >
        {/* Top half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#5b5441", // Top half background
            borderTopLeftRadius: "60px",
            borderTopRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
          }}
        >
          <Typography
            variant="h6"
            color="#fff"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "25px" }, // Responsive font size for the value
              fontWeight: "250",
            }}
          >
            {value}
          </Typography>
        </Box>

        {/* Bottom half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#938671", // Bottom half background
            borderBottomLeftRadius: "60px",
            borderBottomRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Typography
            variant="h6"
            color="#fff"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "25px" }, // Responsive font size for bottom number
              fontWeight: "250",
            }}
          >
            100
          </Typography>
        </Box>
      </Box>

      {/* Title below the circle */}
      <Typography
        variant="body2"
        align="center"
        sx={{
          position: "absolute",
          bottom: "-20px",
          width: "100%",
          fontWeight: "300",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: { xs: "12px", sm: "15px" }, // Responsive title font size
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import AddPlace from "./AddPlace";
import EditPlace from "../components/pages/EditPlace";
import { getToken } from "../utils/AuthUtils";

function PlaceList() {
  const [places, setPlaces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTown, setSelectedTown] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      fetchDistricts(selectedState);
    } else {
      setDistricts([]);
      setSelectedDistrict("");
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      fetchTowns(selectedDistrict);
    } else {
      setTowns([]);
      setSelectedTown("");
    }
  }, [selectedDistrict]);

  useEffect(() => {
    fetchPlaces();
  }, [
    page,
    rowsPerPage,
    searchTerm,
    selectedCountry,
    selectedState,
    selectedDistrict,
    selectedTown,
  ]);

  const fetchPlaces = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/place`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
              country: selectedCountry,
              state: selectedState,
              district: selectedDistrict,
              town: selectedTown,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlaces(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      setError("Error fetching places");
      setOpenSnackbar(true);
    }
  };

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch countries", err);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/without_pagination`,
          {
            params: { country: countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch states", err);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/without_pagination`,
          {
            params: { state: stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch districts", err);
    }
  };

  const fetchTowns = async (districtId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/without_pagination`,
          {
            params: { district: districtId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTowns(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch towns", err);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (placeId) => {
    setSelectedPlaceId(placeId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedPlaceId(null);
  };

  const handleDeletePlace = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/place/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Place deleted successfully");
        setOpenSnackbar(true);
        fetchPlaces(); // Refresh the list after deleting
      }
    } catch (err) {
      setSnackbarMessage("Failed to delete place");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/place/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedPlace(response.data.data);
        setSelectedPlaceId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setError("Error fetching place details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedPlaceId(null);
    setSelectedPlace(null);
  };

  const handleEditPlace = () => {
    handleCloseEditModal();
    fetchPlaces(); // Refresh the list after editing
    setSnackbarMessage("Place Updated successfully");
    setOpenSnackbar(true);
  };

  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4">Place Listing</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        {/* Filter Dropdowns */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              label="Country"
            >
              <MenuItem value="">All</MenuItem>
              {countries.map((country) => (
                <MenuItem key={country._id} value={country._id}>
                  {country.country_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>State</InputLabel>
            <Select
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
              label="State"
            >
              <MenuItem value="">All</MenuItem>
              {states.map((state) => (
                <MenuItem key={state._id} value={state._id}>
                  {state.state_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>District</InputLabel>
            <Select
              value={selectedDistrict}
              onChange={(e) => setSelectedDistrict(e.target.value)}
              label="District"
            >
              <MenuItem value="">All</MenuItem>
              {districts.map((district) => (
                <MenuItem key={district._id} value={district._id}>
                  {district.district_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Town</InputLabel>
            <Select
              value={selectedTown}
              onChange={(e) => setSelectedTown(e.target.value)}
              label="Town"
            >
              <MenuItem value="">All</MenuItem>
              {towns.map((town) => (
                <MenuItem key={town._id} value={town._id}>
                  {town.Town_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddModal(true)}
        >
          Create
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Place Name</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State</TableCell>
              <TableCell>District</TableCell>
              <TableCell>Town</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {places.length > 0 ? (
              places
                .filter((place) =>
                  place.place_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((place, index) => (
                  <TableRow key={place._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{place.place_name ? place.place_name : "N/A"}</TableCell>
                    <TableCell>{place.country.country_name ? place.country.country_name : "N/A"}</TableCell>
                    <TableCell>{place.state.state_name ? place.state.state_name : "N/A"}</TableCell>
                    <TableCell>{place.district.district_name ? place.district.district_name : "N/A"}</TableCell>
                    <TableCell>{place.town.Town_name ? place.town.Town_name : "N/A"}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleOpenEditModal(place._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleOpenDeleteDialog(place._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      {/* Add Place Modal */}
      <AddPlace
        isOpen={openAddModal}
        onClose={() => setOpenAddModal(false)}
        onAdd={fetchPlaces}
        countries={countries}
        states={states}
        districts={districts}
        towns={towns}
      />
      {/* Edit Place Modal */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <EditPlace
          isOpen={openEditModal}
          onClose={() => setOpenEditModal(false)}
          place={selectedPlace}
          countries={countries}
          states={states}
          districts={districts}
          towns={towns}
          onEditPlace={fetchPlaces}
        />
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this place?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeletePlace(selectedPlaceId)}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PlaceList;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

function EditTeacherModal({ open, onClose, category, onUpdate }) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  useEffect(() => {
    if (category) {
      setName(category.teacher_name);
    }
  }, [category]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/teachers/category/${category._id}`,
          { teacher_name: name }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          onUpdate(); // Callback to refresh the list
          setSnackbarMessage('Category updated successfully');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage('Failed to update category');
          setSnackbarSeverity('error');
        }
      }
    } catch (err) {
      console.error(err); // Log the error for debugging
      setSnackbarMessage('Failed to update category');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setOpenSnackbar(true); // Open the Snackbar
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const token = getToken();
  //     if (token) {
  //       await axios.put(
  //         `${process.env.REACT_APP_BACKEND}/api/teachers/category/${category._id}`,
  //         { teacher_name: name },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         onUpdate(); // Callback to refresh the list
  //         setSnackbarMessage('Category updated successfully');
  //         setSnackbarSeverity('success');
  //       } else {
  //         setSnackbarMessage('Failed to update category');
  //         setSnackbarSeverity('error');
  //       }
  //     } }catch (err) {
  //       console.error(err); // Log the error for debugging
  //       setSnackbarMessage('Failed to update category');
  //       setSnackbarSeverity('error');
  //     } finally {
  //       setLoading(false);
  //       setOpenSnackbar(true); // Open the Snackbar
  //     }
  // };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarMessage("");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Category</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Category Name"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Update"}
        </Button>
      </DialogActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default EditTeacherModal;
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  Avatar,
  Alert,
} from "@mui/material";
import axios, { Axios } from "axios";

const EditProfile = () => {
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("info"); // 'success' or 'error'
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    user_name: "",
    mobile: "",
    email: "",
    profile_url: "",
  });

  useEffect(() => {
    const fetchCompanyNews = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token_key");
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/profile/admin`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          const newsData = response.data.data;
          setFormData({
            user_name: newsData.user_name,
            mobile: newsData.mobile,
            email: newsData.email,
            profile_url: newsData.profile_url || "",
          });
          setProfilePicPreview(newsData.profile_url); // Set initial image preview
        }
      } catch (err) {
        setError("Error fetching company news details");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchCompanyNews();
  }, []);

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(file);
      // Store the actual file in formData, not just the path
      setFormData((prev) => ({ ...prev, profile_url: file }));
      setProfilePicPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create FormData object
    const data = new FormData();
    data.append("user_name", formData.user_name);
    data.append("mobile", formData.mobile);
    data.append("email", formData.email);

    // Append the file if it exists
    if (formData.profile_url instanceof File) {
      data.append("profile_url", formData.profile_url);
    }

    try {
      const token = localStorage.getItem("token_key");
      if (token) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/profile/admin/update`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setMessage("Profile updated successfully");
        setMessageType("success");
      }
    } catch (err) {
      setError("Error updating profile");
      setMessageType("error");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // minHeight: "100vh",
        padding: "20px",
        width: "100%",
      }}
    >
      <Card sx={{ width: "600px", boxShadow: 3, borderRadius: "8px" }}>
        <CardContent>
          <Typography variant="h5" gutterBottom align="center">
            Edit Profile
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
                marginTop: 2,
              }}
            >
              {/* Profile Picture */}
              <Avatar
                src={profilePicPreview}
                alt="Profile Picture"
                sx={{ width: 100, height: 100, marginBottom: 2 }}
              />
              <Button variant="contained" component="label">
                Upload Picture
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleProfilePicChange}
                />
              </Button>

              {/* Name Input */}
              <TextField
                label="Name"
                value={formData.user_name}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, user_name: e.target.value }))
                }
                fullWidth
              />
              {/* Name Input */}
              <TextField
                label="Mobile"
                value={formData.mobile}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, mobile: e.target.value }))
                }
                fullWidth
              />
              <TextField
                label="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, email: e.target.value }))
                }
                fullWidth
              />
              {/* Save Button */}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                sx={{
                  textTransform: "none",
                  padding: "10px 20px",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {loading ? "Saving..." : "Save Changes"}
              </Button>

              {/* Feedback Message */}
              {message && (
                <Alert severity={messageType} sx={{ marginTop: 2 }}>
                  {message}
                </Alert>
              )}
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EditProfile;
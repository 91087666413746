import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Switch,
  CircularProgress,
  Container,
  Typography,
  Box,
  TablePagination,
  Grid,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";

const DepartmentCategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, [page, rowsPerPage, searchTerm]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
          },
        }
      );
      setCategories(response.data.data || []);
      setTotalItems(response.data.pagination.totalItems);
    } catch (error) {
      console.error("Error fetching categories", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleSave = async () => {
    try {
      if (isEdit) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/department_category/${currentCategory._id}`,
          currentCategory
        );
        setSnackbarMessage("Category Updated successfully");
        setOpenSnackbar(true);
      } else {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/department_category`,
          currentCategory
        );
      setSnackbarMessage("Category added successfully");
      setOpenSnackbar(true);
      }
      fetchCategories();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving category", error);
      setError("Error saving categories");
      setOpenSnackbar(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/department_category/${deleteId}`
      );
      setSnackbarMessage("Category deleted successfully");
      setOpenSnackbar(true);
      fetchCategories();
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error deleting category", error);
      setError("Error deleting categories");
      setOpenSnackbar(true);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/department_category/${id}`,
        { status: status === 1 ? 0 : 1 }
      );
      setSnackbarMessage("Category status updated successfully");
      setOpenSnackbar(true);
      fetchCategories();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleOpenDialog = (category) => {
    setCurrentCategory(category || {});
    setIsEdit(!!category);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentCategory({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredCategories = categories.filter((category) =>
    (category.department_category_name || "")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <Container maxWidth="100%">
      <Box mb={3} p={1} sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4" gutterBottom>
          Department Category
        </Typography>
        {/* <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
        Create
        </Button> */}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Box>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "300px" }}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
          >
            Create
          </Button>
        </Box>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Department Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCategories.length > 0 ? (
                filteredCategories.map((category, index) => (
                  <TableRow key={category._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{category.department_category_name}</TableCell>
                    <TableCell>
                      <Switch
                        checked={category.status === 1}
                        onChange={() =>
                          handleToggleStatus(category._id, category.status)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenDialog(category)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleOpenDeleteDialog(category._id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No categories found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems > 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEdit ? 'Edit  Category' : 'Add  Category'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Department Category Name"
            fullWidth
            margin="normal"
            value={currentCategory.department_category_name || ''}
            required
            onChange={(e) =>
              setCurrentCategory({
                ...currentCategory,
                department_category_name: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            {isEdit ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default DepartmentCategoryList;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AddSchoolMainPartner.css */
.error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/partners_route/AddSchoolMainPartner.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,UAAU;IACV,eAAe;IACf,eAAe;EACjB","sourcesContent":["/* AddSchoolMainPartner.css */\n.error-message {\n    color: red;\n    font-size: 12px;\n    margin-top: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  Typography,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import EditSchoolSub from "./EditSchoolSub";
import AddSchoolSub from "./AddSchoolSub";
import { getToken } from "../../../utils/AuthUtils";

function SchoolSubList() {
  const [subjects, setSubjects] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const fetchSchoolCategories = async () => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/school_category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSchoolCategories(response.data.data);
    } }catch (err) {
      console.error("Error fetching school categories", err);
    }
  };

  const fetchSubjects = async () => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/sub_school_category`,
        {
          params: { page: page + 1, limit: rowsPerPage },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubjects(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } }catch (err) {
      setError("Error fetching subjects");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchSchoolCategories();
    fetchSubjects();
  }, [page, rowsPerPage]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (subjectId) => {
    setSelectedSubjectId(subjectId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSubjectId(null);
  };

  const handleDeleteSubject = async (id) => {
    try {
      const token = getToken();
      if (token) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/sub_school_category/delete/${id}`,{},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSnackbarMessage("Sub-Category deleted successfully");
      setOpenSnackbar(true);
      fetchSubjects();
    } }catch (err) {
      setSnackbarMessage("Failed to delete Category");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/sub_school_category/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedSubject(response.data.data);
      setSelectedSubjectId(id);
      setOpenEditModal(true);
    } }catch (err) {
      setError("Error fetching Sub-Category details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedSubjectId(null);
    setSelectedSubject(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddSubject = () => {
    setSnackbarMessage("Sub-Category  added successfully");
    setOpenSnackbar(true);
    handleCloseAddModal();
    fetchSubjects();
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateSubjectStatus(id, newStatus);
  };

  const updateSubjectStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/sub_school_category/${id}/status_subschool_category`,
        {
          status,
        }
        ,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );
      setSnackbarMessage("Sub-Category status updated successfully");
      setOpenSnackbar(true);
      fetchSubjects();
    } }catch (err) {
      setError("Failed to update Sub-Category status");
      setOpenSnackbar(true);
    }
  };

  // Filter subjects by search term and selected category
  const filteredSubjects = subjects.filter(subject =>
    (subject.SubCategory_name || '').toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedCategoryId ? subject.School_category_id === selectedCategoryId : true)
  );

  // Function to map category ID to name
  const getCategoryName = (id) => {
    const category = schoolCategories.find((cat) => cat._id === id);
    return category ? category.school_name : "Unknown";
  };

  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4" gutterBottom>
          School Subcategory
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          select
          label="Filter by Category"
          variant="outlined"
          size="small"
          value={selectedCategoryId}
          onChange={(e) => {
            setSelectedCategoryId(e.target.value);
            setPage(0); // Reset to first page when category changes
          }}
          sx={{ width: '300px', marginRight: '10px' }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {schoolCategories.map(category => (
            <MenuItem key={category._id} value={category._id}>
              {category.school_name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Create
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Subcategory Name</TableCell>
              <TableCell>Category Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSubjects.length > 0 ? (
              filteredSubjects.map((subject, index) => (
                <TableRow key={subject._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{subject.SubCategory_name}</TableCell>
                  <TableCell>
                    {getCategoryName(subject.School_category_id)}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={subject.status}
                      onChange={() =>
                        handleStatusToggle(subject._id, subject.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenEditModal(subject._id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleOpenDeleteDialog(subject._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Sub-category found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddSchoolSub
        open={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddSubject}
      />
      {selectedSubjectId && (
        <EditSchoolSub
          open={openEditModal}
          onClose={handleCloseEditModal}
          subjectId={selectedSubjectId}
          subject={selectedSubject}
          onUpdate={() => {
            setSnackbarMessage("Sub-Category  updated successfully");
            setOpenSnackbar(true);
            handleCloseEditModal();
            fetchSubjects();
          }}
        />
      )}
      {selectedSubjectId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this category?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteSubject(selectedSubjectId)}
              color="primary" variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default SchoolSubList;

import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import { fetchPartner, addPartner, updateCompany, softDeleteCompany } from '../../../server/CompanyServices';

const CompanyPage = () => {
    const [companies, setCompanies] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [formData, setFormData] = useState({
        partner_name: '',
        mobile: '',
        email: '',
        password: ''
    });

    useEffect(() => {
        loadCompanies();
    }, []);

    const loadCompanies = async () => {
        try {
            const data = await fetchPartner();
            setCompanies(data);
        } catch (error) {
            console.error('Error loading companies:', error);
        }
    };
    const handleAddCompany = async () => {
        try {
            await addPartner(formData);
            setShowAddModal(false);
            setFormData({
                partner_name: '',
                mobile: '',
                email: '',
                password: '',
            });
            loadCompanies();
        } catch (error) {
            console.error('Error adding company:', error);
        }
    };

    const handleUpdateCompany = async () => {
        try {
            await updateCompany(selectedCompany._id, formData);
            setShowUpdateModal(false);
            setSelectedCompany(null);
            setFormData({
                company_name: '',
                address: '',
                mobile: '',
                email: '',
                company_website: '',
                established_year: '',
                industry_type: ''
            });
            loadCompanies();
        } catch (error) {
            console.error('Error updating company:', error);
        }
    };

    // const handleDeleteCompany = async (id) => {
    //     try {
    //         await deleteCompany(id);
    //         loadCompanies();
    //     } catch (error) {
    //         console.error('Error deleting company:', error);
    //     }
    // };
    const handleSoftDelete = async (id) => {
        try {
            await softDeleteCompany(id); // Call the soft delete API
            // Refresh the list or remove the item from the UI
            loadCompanies(); // or however you refresh your list
        } catch (error) {
            console.error('Error soft deleting school:', error);
        }
    };


    const handleShowUpdateModal = (company) => {
        setSelectedCompany(company);
        setFormData({
            company_name: company.company_name,
            address: company.address,
            mobile: company.mobile,
            email: company.email,
            password: company.password,
            company_website: company.company_website,
            established_year: company.established_year,
            industry_type: company.industry_type
        });
        setShowUpdateModal(true);
    };

    
    return (
        <div>
          <h1>Partners</h1>
          <Button
            variant="primary"
            onClick={() => setShowAddModal(true)}
            className="mb-3"
          >
            Add partner
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((partner) => (
                <tr key={partner._id}>
                  <td>{partner.partner_name}</td>
                  <td>{partner.mobile}</td>
                  <td>{partner.email}</td>
                  <td>
                    <Button
                      variant="secondary"
                      onClick={() => handleShowUpdateModal(partner)}
                    >
                      Update
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => handleSoftDelete(partner._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
    
          {/* Add Company Modal */}
          <Modal
            show={showAddModal}
            onHide={() => setShowAddModal(false)}
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.partner_name}
                    onChange={(e) =>
                      setFormData({ ...formData, partner_name: e.target.value })
                    }
                  />
                </Form.Group>
                {/* <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                  />
                </Form.Group> */}
                <Form.Group>
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.mobile}
                    onChange={(e) =>
                      setFormData({ ...formData, mobile: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                </Form.Group>
                {/* <Form.Group>
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.company_website}
                    onChange={(e) =>
                      setFormData({ ...formData, company_website: e.target.value })
                    }
                  /> */}
                {/* </Form.Group>
                <Form.Group>
                  <Form.Label>Established Year</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.established_year}
                    onChange={(e) =>
                      setFormData({ ...formData, established_year: e.target.value })
                    }
                  />
                </Form.Group> */}
                {/* <Form.Group>
                  <Form.Label>Industry Type</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.industry_type}
                    onChange={(e) =>
                      setFormData({ ...formData, industry_type: e.target.value })
                    }
                  />
                </Form.Group> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddCompany}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
    
          {/* Update Company Modal */}
          <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Update Partmer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.partner_name}
                    onChange={(e) =>
                      setFormData({ ...formData, partner_name: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.mobile}
                    onChange={(e) =>
                      setFormData({ ...formData, mobile: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </Form.Group>
                {/* <Form.Group>
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.partner_website}
                    onChange={(e) =>
                      setFormData({ ...formData, company_website: e.target.value })
                    }
                  />
                </Form.Group> */}
                {/* <Form.Group>
                  <Form.Label>Established Year</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.established_year}
                    onChange={(e) =>
                      setFormData({ ...formData, established_year: e.target.value })
                    }
                  />
                </Form.Group> */}
                {/* <Form.Group>
                  <Form.Label>Industry Type</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.industry_type}
                    onChange={(e) =>
                      setFormData({ ...formData, industry_type: e.target.value })
                    }
                  />
                </Form.Group> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleUpdateCompany}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
};

export default CompanyPage;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Switch,
  CircularProgress,
  Container,
  Typography,
  Box,
  MenuItem,
  TablePagination,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/system";

const DepartmentSubCategoryList = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [subCategoryToDelete, setSubCategoryToDelete] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    category: "",
  });

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchSubCategories();
    fetchDepartmentCategories();
  }, [page, rowsPerPage, searchTerm, selectedCategory]); // Add selectedCategory

  const fetchSubCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
            department_category_id: selectedCategory, // Include selected category
          },
        }
      );
      setSubCategories(response.data.data);
      setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Subcategory", error);
      setLoading(false);
    }
  };

  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category`
      );
      setCategories(response.data.data.filter((cat) => cat.status === 1)); // Filter categories based on status
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const validateFields = () => {
    const newErrors = {
      name: "",
      category: "",
    };
    let isValid = true;

    if (!currentSubCategory.department_sub_category_name) {
      newErrors.name = "Subcategory Name is required";
      isValid = false;
    }

    if (!currentSubCategory.department_category_id) {
      newErrors.category = "Department Category is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateFields()) {
      return;
    }
  
    try {
      let response;
      if (isEdit) {
        response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${currentSubCategory._id}`,
          currentSubCategory
        );
        setSnackbarMessage("Sub-Category updated successfully");
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/department_category_sub`,
          currentSubCategory
        );
        setSubCategories([...subCategories, response.data.data]);
        setSnackbarMessage("Sub-Category added successfully");
      }
      fetchSubCategories();
      handleCloseDialog();
      setOpenSnackbar(true); // Show Snackbar after the save operation
    } catch (error) {
      console.error("Error saving Sub-Category", error);
      setSnackbarMessage("Error saving Sub-Category");
      setOpenSnackbar(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${id}`
      );
      setSnackbarMessage("Sub-Category deleted successfully");
      setOpenSnackbar(true);
      fetchSubCategories();
      handleCloseConfirmDialog();
    } catch (error) {
      console.error("Error deleting Sub-Category", error);
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${id}`,
        { status: newStatus }
      );
      setSnackbarMessage("Sub-Category status updated successfully");
      setOpenSnackbar(true);
      fetchSubCategories();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleOpenDialog = (subCategory) => {
    setCurrentSubCategory(
      subCategory
        ? { ...subCategory }
        : { department_sub_category_name: "", department_category_id: "" }
    );
    setIsEdit(!!subCategory);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentSubCategory({});
    setOpenDialog(false);
  };

  const handleOpenConfirmDialog = (subCategory) => {
    setSubCategoryToDelete(subCategory);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setSubCategoryToDelete(null);
    setOpenConfirmDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const filteredCategories = subCategories
    .filter((subCategory) =>
      subCategory.department_sub_category_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .filter((subCategory) =>
      selectedCategory ? subCategory.department_category_id === selectedCategory : true
    )

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}
      >
        <Typography variant="h4" gutterBottom>
          Department Subcategory
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          select
          label="Department Category"
          variant="outlined"
          size="small"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setPage(0); // Reset to first page when category changes
          }}
          style={{ width: "300px", marginRight: "10px" }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              {category.department_category_name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: "300px" }}
        />
        
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Create
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Subcategory Name</TableCell>
                  <TableCell>Department Category</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCategories.length > 0 ? (
                  filteredCategories
                    .map((subCategory, index) => (
                      <TableRow key={subCategory._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{subCategory.department_sub_category_name}</TableCell>
                        <TableCell>
                          {categories.find(
                            (cat) =>
                              cat._id === subCategory.department_category_id
                          )?.department_category_name || "N/A"}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={subCategory.status === 1}
                            onChange={() =>
                              handleToggleStatus(subCategory._id, subCategory.status)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleOpenDialog(subCategory)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenConfirmDialog(subCategory)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Sub-category found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
        </>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {isEdit ? "Edit Subcategory" : "Add Subcategory"}
        </DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Department Category"
            fullWidth
            margin="normal"
            value={currentSubCategory.department_category_id || ""}
            required
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                department_category_id: e.target.value,
              })
            }
            error={!!errors.category}
            helperText={errors.category}
          >
            {categories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.department_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Subcategory Name"
            fullWidth
            margin="normal"
            value={currentSubCategory.department_sub_category_name || ""}
            required
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                department_sub_category_name: e.target.value,
              })
            }
            error={!!errors.name}
            helperText={errors.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            // sx={{ backgroundColor: "#e0e0e0" }}
          >
            {isEdit ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this Subcategory?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (subCategoryToDelete) {
                handleDelete(subCategoryToDelete._id);
              }
            }}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
  open={openSnackbar}
  autoHideDuration={3000}
  onClose={handleSnackbarClose}
>
  <Alert
    onClose={handleSnackbarClose}
    severity={snackbarMessage ? "success" : "error"} // Set severity based on the snackbarMessage
  >
    {snackbarMessage || (errors.name || errors.category) || "Something went wrong."}
  </Alert>
</Snackbar>

    </Container>
  );
};

export default DepartmentSubCategoryList;

import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Signup";
import Home from "./components/Home";
import Dash from "./components/Dash";
import AddPartner from "./components/pages/category/AddPartnerModal";
import PartnerList from "./components/pages/category/PartnerList";
import Layout from "./components/Layout";
import SubCategoryPartnerList from "./components/pages/category/SubCategoryPartnerList";
import SchoolCategoryList from "./components/pages/category/SchoolCategoryList";
import AddSchools from "./components/pages/category/AddSchools";
import SchoolSubList from "./components/pages/category/SchoolSubList";
import TeacherCategoryList from "./components/pages/category/TeacherCategoryList";
import TeacherSubList from "./components/pages/category/TeacherSubList";
import StudentCategoryList from "./components/pages/category/StudentCategoryList";
import StudentSubList from "./components/pages/category/StudentSubList";
import ProductCategory from "./components/pages/category/ProductCategory";
import ProductSubList from "./components/pages/category/ProductSubList";
import ProjectList from "./components/pages/category/ProjectList";
import ProjectSubList from "./components/pages/category/ProjectSubList";
import QuizzCategory from "./components/pages/category/QuizzCategory";
import QuizzSubCategory from "./components/pages/category/QuizzSubCategory";

import CoursesCategoryList from "./components/pages/category/CourseCategoryList";
import CoursesSubList from "./components/pages/category/CoursesSubList";
import CountryList from "./components/CountryList";
import StateList from "./components/StateList";
import DistrictList from "./components/DistrictListing";
import TownList from "./components/TownList";
import PlaceList from "./components/PlaceList";

// import Login from "./components/Login";
// import Register from "./components/Signup";
// import Home from "./components/Home";
// import Cred from "./components/cred";
// import Layout from "./components/Layout";

import ProtectedAdminRoute from "./components/route_protection/ProtectedSuAndCompanyRoute";
import ProtectedCompanyRoute from "./components/route_protection/ProtectedCompanyRoute";
import ProtectedPartnerRoute from "./components/route_protection/ProtectedPartnerRoute";
import ProtectedSuAndCompanyRoute from "./components/route_protection/ProtectedSuAndCompanyRoute";
import ProtectedSuAndCompanyAndPartnerRoute from "./components/route_protection/ProtectedSuAndCompanyAndPartnerRoute";

import StudentTable from "./components/pages/category/Students";
import PartnerPage from "./components/pages/category/PartnerPage";
import AddSchool from "./components/pages/category/AddSchool";
import UpdateSchool from "./components/pages/category/UpdateSchool";

import DisplayUnit from "./components/pages/units/DisplayUnits";
import EditUnit from "./components/pages/units/EditUnit";
import UnitBack from "./components/pages/units/unit-editor-bck";

import SchoolList from "./components/pages/SchoolList";
import AddSchoolMain from "./components/pages/AddSchoolMain";
import EditSchoolMain from "./components/pages/EditSchoolMain";
import ViewSchoolPage from "./components/pages/ViewSchoolPage";
import DepartmentList from "./components/pages/DepartmentList";
import AddDepartment from "./components/pages/AddDepartment";
import EditDepartment from "./components/pages/EditDepartment";
import ProductsList from "./components/pages/ProductsList";
import AddProducts from "./components/pages/AddProductsList";
import EditProducts from "./components/pages/EditProducts";
import CoursesList from "./components/pages/category/CoursesList";
import AddCoursePage from "./components/pages/AddCoursePage";
import EditCoursePage from "./components/pages/EditCoursePage";
import CourseListMain from "./components/pages/CoursesListMain";
import ViewCoursePage from "./components/pages/CoursesViewPage";
import NewsLetterList from "./components/pages/NewsLetterPage";
import PartnerListPage from "./components/pages/PartnerLListPage";
import AddPartnerPage from "./components/pages/AddPartnerPage";
import EditPartnerPage from "./components/pages/EditPartnerPage";
import PartnerViewPage from "./components/pages/PartnerViewPage";
import AddNewsletterPage from "./components/pages/AddNewsletterPage";
import EditNewsletterPage from "./components/pages/EditNewsletterPage";
import SliderListPage from "./components/pages/SliderListPage";
import EditSliderPage from "./components/pages/EditSliderPage";
import AddSliderPage from "./components/pages/AddSliderPage";
import EventListPage from "./components/pages/EventListPage";
import AddEvent from "./components/pages/AddEvent";
import EditEvent from "./components/pages/EditEvent";
import DepartmentCategoryList from "./components/pages/category/DepartmentCategoryList";
import DepartmentSubCategory from "./components/pages/category/DepartmentSubCategory";
import UploadContent from "./components/UploadContent";
import List from "./components/List";
import Chapters from "./components/pages/Chapters";
import ChaptersTable from "./components/pages/ChaptersTable";
import EditChapter from "./components/pages/EditChapter";
import Dashboard from "./components/pages/Dashboard";
import AssessmentForm from "./components/pages/assessment/AssessmentForm";
import Students from "./components/pages/list/Students";
import Teacher from "./components/pages/list/Teachers";
import Parent from "./components/pages/list/Parents";
import Unit from "./components/pages/units/UnitList";
import AssignCourses from "./components/pages/AssignCourses";
import AssignCoursesTable from "./components/pages/AssignCoursesTable";
import AssignCourseEdit from "./components/pages/AssignCourseEdit";
// import UnitUpload from './components/pages/units/UploadContent';
import Uupload from "./components/pages/units/Uunit";
import ResetPassword from "./components/RestPassword";

import Role from "./components/pages/roles/RoleListing";
import RolePermissions from "./components/pages/roles/RolePermission";

import CarrierPlannerPage from "./components/pages/CarrierPlannerPage";
import AddCarrierPlanner from "./components/pages/AddCarrierPlanner";
import EditCarrierPlanner from "./components/pages/EditCarrierPlanner";
import QuestionsList from "./components/pages/assessment/QuestionsList";
import UnitAssessmentsList from "./components/pages/UnitAssessmentList";
import AssessmentsList from "./components/pages/assessment/AssessmentList";
// import AssessmentQuestionList from './components/pages/AssessmentQuestionList';
import EditUnitAssessmentList from "./components/pages/EditUnitAssessmentList";
import CompanyNewsPage from "./components/pages/CompanyNewsPage";
import AddCompanyNews from "./components/pages/AddCompanyNews";
import EditCompanyNewsPage from "./components/pages/EditCompanyNewsPage";
import QuestionsPage from "./components/pages/AssessmentQuestionList";
import LabnamesList from "./components/pages/LabnamesList";
import AddLabnames from "./components/pages/AddLabnames";
import EditLabnames from "./components/pages/EditLabnames";

// partner route
import DashboardPartner from "./components/partners_route/DashboardPartner";
import SchoolListPartner from "./components/partners_route/SchoolListPartner";
import CourseListMainpartner from "./components/partners_route/CoursesListMainPartner";
import AddCoursePagePartner from "./components/partners_route/AddCoursePagePartner";
import AssignCoursesPartner from "./components/partners_route/AssignCoursesPartner";
import AssignCoursesTablePartner from "./components/partners_route/AssignCoursesTablePartner";
import AssignCourseEditPartner from "./components/partners_route/AssignCourseEditPartner";
import EditCoursePagePartner from "./components/partners_route/EditCoursePagePartner";
import AddSchoolPartner from "./components/partners_route/AddSchoolPartner";
import AddSchoolMainPartner from "./components/partners_route/AddSchoolMainPartner";
import ChaptersTablePartner from "./components/partners_route/ChaptersTablePartner";
import DepartmentListPartner from "./components/partners_route/DepartmentListPartner";
import UnitListPartner from "./components/partners_route/UnitListPartner";
import AssignCoursesToPartner from "./components/pages/AssignCoursesToPartner";
import AddAssignCourseToPartner from "./components/pages/AddAssignCourseToPartner";
import EditAssignCourseToPartner from "./components/pages/EditAssignedCourseToPartner";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          {/* <Route path="/uunit" element={<Uupload />} />
          <Route path="/unitlist" element={<Unit />} />
          <Route path="/studlist" element={<Students />} />
          <Route path="/teachlist" element={<Teacher />} />
          <Route path="/parlist" element={<Parent />} /> */}
          <Route path="/uploadunit" element={<Uupload />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          {/* <Route path="/unitupload" element={<UnitUpload />} /> */}
          {/* <Route path="/listhi" element={<List />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register" element={<Register />} /> */}
          {/* <Route path="/home" element={<Home />} /> */}
          {/* <Route path="/home_ms" element={<Dash />} /> */}
          {/* <Route path="/add_partner" element={<AddPartner />} />
          <Route path="/partner_category" element={<PartnerList />} />
          <Route path="/sub_category" element={<SubCategoryPartnerList />} />
          <Route path="/school_category" element={<SchoolCategoryList />} />
          <Route path="/add_school_category" element={<AddSchools />} />
          <Route path="/school_sub_category" element={<SchoolSubList />} />
          <Route path="/teachers_category" element={<TeacherCategoryList />} />
          <Route path="/teachers_sub_category" element={<TeacherSubList />} />
          <Route path="/students_category" element={<StudentCategoryList />} />
          <Route path="/students_sub_category" element={<StudentSubList />} />
          <Route path="/product/category" element={<ProductCategory />} />
          <Route path="/product/sub_category" element={<ProductSubList />} />
          <Route path="/project/category" element={<ProjectList />} />
          <Route path="/project/sub_category" element={<ProjectSubList />} />
          <Route path="/courses/category" element={<CoursesCategoryList />} />
          <Route path="/courses/sub_category" element={<CoursesSubList />} />
          <Route path="/department/category" element={<DepartmentCategoryList />} />
          <Route path="/department/sub_category" element={<DepartmentSubCategory />} /> */}
          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/*  country */}
          {/* <Route path="/country_list" element={<CountryList />} />
          <Route path="/state_list" element={<StateList />} />
          <Route path="/district_list" element={<DistrictList />} />
          <Route path="/town_list" element={<TownList />} />
          <Route path="/place_list" element={<PlaceList />} /> */}
          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* School Listing */}
          <Route path="/school_list" element={<SchoolList />} />
          <Route path="/add-school" element={<AddSchoolMain />} />
          <Route path="/edit-school/:id" element={<EditSchoolMain />} />
          <Route path="/view-school/:id" element={<ViewSchoolPage />} />
          {/* Department */}
          {/* <Route path="/department_list" element={<DepartmentList />} /> */}
          {/* add not working */}
          {/* <Route path="/departments/add" element={<AddDepartment />} />

          <Route path="/departments/edit/:id" element={<EditDepartment />} /> */}
          {/* Product creation */}
          <Route path="/product_list" element={<ProductsList />} />
          <Route path="/products/add" element={<AddProducts />} />
          <Route path="/products/edit/:id" element={<EditProducts />} />
          {/* Courses crreation */}
          <Route path="/courses_list" element={<CourseListMain />} />
          <Route path="/courses/add" element={<AddCoursePage />} />
          <Route path="/courses/edit/:id" element={<EditCoursePage />} />
          {/* NewsLetter Creation */}
          {/* <Route path="/newsletter_list" element={<NewsLetterList />} />
          <Route path="/newsletters/add" element={<AddNewsletterPage />} />
          <Route path="/newsletters/edit/:id" element={<EditNewsletterPage />} /> */}
          {/* Partner Creation */}
          {/* <Route path="/partner_list" element={<PartnerListPage />} />
          <Route path="/partners/add" element={<AddPartnerPage />} />
          <Route path="/partners/edit/:id" element={<EditPartnerPage />} /> */}
          {/* Slider Creation */}
          {/* <Route path="/slider_list" element={<SliderListPage />} />
          <Route path="/sliders/add" element={<AddSliderPage />} />
          <Route path="/sliders/edit/:id" element={<EditSliderPage />} /> */}
          {/* chapter */}
          <Route path="/chapters_add" element={<Chapters />} />
          <Route path="/chapters" element={<ChaptersTable />} />
          {/* <Route path="/edit_chapter/:id" element={<EditChapter />} /> */}
          {/* Event Creation */}
          {/* <Route path="/event_list" element={<EventListPage />} />
          <Route path="/events/add" element={<AddEvent />} />
          <Route path="/events/edit/:id" element={<EditEvent />} /> */}
          {/* Assesment */}
          {/* <Route path="/assessment" element={<AssessmentForm />} /> */}
          <Route path="/edit_chapter/:id" element={<EditChapter />} />
          {/* Assigning Courses to school */}
          <Route path="/assign_courses" element={<AssignCourses />} />
          <Route path="/assign_courses_list" element={<AssignCoursesTable />} />
          <Route
            path="/assign_courses/edit/:id"
            element={<AssignCourseEdit />}
          />
          {/* Event Creation */}
          <Route path="/event_list" element={<EventListPage />} />
          <Route path="/events/add" element={<AddEvent />} />
          <Route path="/events/edit/:id" element={<EditEvent />} />
          {/* Assesment */}
          {/* <Route path="/assessment" element={<AssessmentForm />} /> */}
          <Route path="/assessment/add" element={<AssessmentForm />} />
          <Route
            path="/partner/assessment_list"
            element={<UnitAssessmentsList />}
          />
          <Route
            path="/partner/assessment/edit/:id"
            element={<EditUnitAssessmentList />}
          />
          <Route
            path="/partner/assessment_list_page"
            element={<AssessmentsList />}
          />
          {/* Assessment Question List  */}
          <Route
            path="/partner/questions/assessment/:assessmentId"
            element={<QuestionsPage />}
          />
          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        </Route>
        <Route path="/" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route element={<Layout />}>
          {/* ----------------------------------------Admin routes---------------------------------------- */}
          <Route
            path="super_admin"
            element={
              <ProtectedSuAndCompanyRoute>
                <Dashboard />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/unitinterface/:id"
            element={
              <ProtectedSuAndCompanyAndPartnerRoute>
                <DisplayUnit />
              </ProtectedSuAndCompanyAndPartnerRoute>
            }
          />
          <Route
            path="/editunit/:id/:coursesname/:chaptername/:unitname"
            element={
              <ProtectedSuAndCompanyAndPartnerRoute>
                <EditUnit />
              </ProtectedSuAndCompanyAndPartnerRoute>
            }
          />
          <Route
            path="/role"
            element={
              <ProtectedSuAndCompanyRoute>
                <Role />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/rolepermission"
            element={
              <ProtectedSuAndCompanyRoute>
                <RolePermissions />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedSuAndCompanyRoute>
                <Dashboard />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/uploadunit"
            element={
              <ProtectedSuAndCompanyRoute>
                <Uupload />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/add_partner"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddPartner />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/partner_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <PartnerList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <SubCategoryPartnerList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/school_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <SchoolCategoryList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/add_school_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddSchools />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/school_sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <SchoolSubList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/teachers_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <TeacherCategoryList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/teachers_sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <TeacherSubList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/students_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <StudentCategoryList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/students_sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <StudentSubList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/product/category"
            element={
              <ProtectedSuAndCompanyRoute>
                <ProductCategory />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/product/sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <ProductSubList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/project/category"
            element={
              <ProtectedSuAndCompanyRoute>
                <ProjectList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/project/sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <ProjectSubList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* quizz category and quizz sub category */}
          <Route
            path="/quiz/category"
            element={
              <ProtectedSuAndCompanyRoute>
                <QuizzCategory/>
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/quiz/sub-category"
            element={
              <ProtectedSuAndCompanyRoute>
                <QuizzSubCategory />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/courses/category"
            element={
              <ProtectedSuAndCompanyRoute>
                <CoursesCategoryList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/courses/sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <CoursesSubList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/department/category"
            element={
              <ProtectedSuAndCompanyRoute>
                <DepartmentCategoryList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/department/sub_category"
            element={
              <ProtectedSuAndCompanyRoute>
                <DepartmentSubCategory />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/*  country */}
          <Route
            path="/country_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <CountryList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/state_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <StateList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/district_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <DistrictList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/town_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <TownList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/place_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <PlaceList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* School Listing */}
          {/* <Route path="/school_list" element={<ProtectedSuAndCompanyRoute><SchoolList /></ProtectedSuAndCompanyRoute>} />
          <Route path="/add-school" element={<ProtectedSuAndCompanyRoute><AddSchoolMain /></ProtectedSuAndCompanyRoute>} />
          <Route path="/edit-school/:id" element={<ProtectedSuAndCompanyRoute><EditSchoolMain /></ProtectedSuAndCompanyRoute>} />
          <Route path="/view-school/:id" element={<ProtectedSuAndCompanyRoute><ViewSchoolPage /></ProtectedSuAndCompanyRoute>} /> */}
          {/* Department */}
          <Route
            path="/department_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <DepartmentList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* add not working */}
          <Route
            path="/departments/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddDepartment />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* AssignCoursesto partner */}
          <Route
            path="/assign_courses_to_partner"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddAssignCourseToPartner />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/assign_courses_to_partner_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <AssignCoursesToPartner />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/assign_courses_to_partner/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditAssignCourseToPartner />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/departments/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditDepartment />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Product creation */}
          <Route
            path="/product_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <ProductsList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/products/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddProducts />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/products/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditProducts />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Courses crreation */}
          <Route
            path="/courses_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <CourseListMain />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/courses/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddCoursePage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/courses/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditCoursePage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/courses/view/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <ViewCoursePage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* NewsLetter Creation */}
          <Route
            path="/newsletter_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <NewsLetterList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/newsletters/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddNewsletterPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          \
          <Route
            path="/newsletters/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditNewsletterPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          \{/* Partner Creation */}
          <Route
            path="/partner_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <PartnerListPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/partners/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddPartnerPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/partners/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditPartnerPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/partners/view/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <PartnerViewPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Slider Creation */}
          <Route
            path="/slider_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <SliderListPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/sliders/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddSliderPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/sliders/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditSliderPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Carrier Planner Creation */}
          <Route
            path="/career_planner_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <CarrierPlannerPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/career_planner/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddCarrierPlanner />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/career_planner/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditCarrierPlanner />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* chapter */}
          <Route
            path="/chapters_add"
            element={
              <ProtectedAdminRoute>
                <Chapters />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/chapters"
            element={
              <ProtectedAdminRoute>
                <ChaptersTable />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/edit_chapter/:id"
            element={
              <ProtectedAdminRoute>
                <EditChapter />
              </ProtectedAdminRoute>
            }
          />
          {/* Event Creation */}
          <Route
            path="/event_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <EventListPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/events/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddEvent />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/events/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditEvent />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Company News */}
          <Route
            path="/company_news_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <CompanyNewsPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/company_news/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddCompanyNews />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/company_news/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditCompanyNewsPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Assesment */}
          <Route
            path="/assessment/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AssessmentForm />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Question List */}
          {/* <Route path="/assessments/questions/:id" element={<ProtectedSuAndCompanyRoute><QuestionsList /></ProtectedSuAndCompanyRoute>} /> */}
          {/* Assessment List */}
          <Route
            path="/assessment_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <UnitAssessmentsList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/assessment/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditUnitAssessmentList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/assessment_list_page"
            element={
              <ProtectedSuAndCompanyRoute>
                <AssessmentsList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Assessment Question List  */}
          <Route
            path="/questions/assessment/:assessmentId"
            element={
              <ProtectedSuAndCompanyRoute>
                <QuestionsPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Lab Names List */}
          <Route
            path="/labnames"
            element={
              <ProtectedSuAndCompanyRoute>
                <LabnamesList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/labnames/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AddLabnames />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/labnames/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditLabnames />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* ------ LIst ------ */}
          <Route
            path="/uunit"
            element={
              <ProtectedSuAndCompanyRoute>
                <Uupload />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route path="/unitback" element={<UnitBack />} />
          <Route
            path="/unitlist"
            element={
              <ProtectedSuAndCompanyAndPartnerRoute>
                <Unit />
              </ProtectedSuAndCompanyAndPartnerRoute>
            }
          />
          <Route
            path="/studlist"
            element={
              <ProtectedSuAndCompanyRoute>
                <Students />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/teachlist"
            element={
              <ProtectedSuAndCompanyRoute>
                <Teacher />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/parlist"
            element={
              <ProtectedSuAndCompanyRoute>
                <Parent />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* ----------------------------------------Company routes---------------------------------------- */}
          <Route
            path="company"
            element={
              <ProtectedCompanyRoute>
                <StudentTable />
              </ProtectedCompanyRoute>
            }
          />
          <Route
            path="partner/view"
            element={
              <ProtectedPartnerRoute>
                <PartnerPage />
              </ProtectedPartnerRoute>
            }
          />
          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* ----------------------------------------Partner routes---------------------------------------- */}
          <Route
            path="partner"
            element={
              <ProtectedPartnerRoute>
                <DashboardPartner />
              </ProtectedPartnerRoute>
            }
          />
          {/* ******************************************************* */}
          <Route
            path="partner/teachlist"
            element={
              <ProtectedPartnerRoute>
                <Teacher />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/studlist"
            element={
              <ProtectedPartnerRoute>
                <Students />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/parlist"
            element={
              <ProtectedPartnerRoute>
                <Parent />
              </ProtectedPartnerRoute>
            }
          />
          {/* ************************************************************ */}
          <Route
            path="partner/school_list"
            element={
              <ProtectedPartnerRoute>
                <SchoolListPartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/add-school"
            element={
              <ProtectedPartnerRoute>
                <AddSchoolMainPartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/edit-school/:id"
            element={
              <ProtectedAdminRoute>
                <EditSchoolMain />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="partner/view-school/:id"
            element={
              <ProtectedAdminRoute>
                <ViewSchoolPage />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="partner/department_list"
            element={
              <ProtectedPartnerRoute>
                <DepartmentListPartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/courses_list"
            element={
              <ProtectedPartnerRoute>
                <CourseListMainpartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/courses/add"
            element={
              <ProtectedPartnerRoute>
                <AddCoursePagePartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/courses/edit/:id"
            element={
              <ProtectedPartnerRoute>
                <EditCoursePagePartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/unitlist"
            element={
              <ProtectedPartnerRoute>
                <UnitListPartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/uploadunit"
            element={
              <ProtectedPartnerRoute>
                <Uupload />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="/partner/chapters"
            element={
              <ProtectedPartnerRoute>
                <ChaptersTablePartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="partner/product_list"
            element={
              <ProtectedPartnerRoute>
                <ProductsList />
              </ProtectedPartnerRoute>
            }
          />
          {/* <Route path="/products/add" element={<ProtectedPartnerRoute><AddProducts /></ProtectedPartnerRoute>} />
          <Route path="/products/edit/:id" element={<ProtectedPartnerRoute><EditProducts /></ProtectedPartnerRoute>} /> */}
          {/* Assigning Courses to school */}
          <Route
            path="/partner/assign_courses"
            element={
              <ProtectedPartnerRoute>
                <AssignCoursesPartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="/partner/assign_courses_list"
            element={
              <ProtectedPartnerRoute>
                <AssignCoursesTablePartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="/partner/assign_courses/edit/:id"
            element={
              <ProtectedPartnerRoute>
                <AssignCourseEditPartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="/partner/add-school"
            element={
              <ProtectedPartnerRoute>
                <AddSchoolPartner />
              </ProtectedPartnerRoute>
            }
          />
          <Route
            path="/partner/updateshool"
            element={
              <ProtectedPartnerRoute>
                <UpdateSchool />
              </ProtectedPartnerRoute>
            }
          />
          {/* Assesment */}
          <Route
            path="/assessment/add"
            element={
              <ProtectedSuAndCompanyRoute>
                <AssessmentForm />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Question List */}
          {/* <Route path="/assessments/questions/:id" element={<ProtectedSuAndCompanyRoute><QuestionsList /></ProtectedSuAndCompanyRoute>} /> */}
          {/* Assessment List */}
          <Route
            path="/partner/assessment_list"
            element={
              <ProtectedSuAndCompanyRoute>
                <UnitAssessmentsList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/partner/assessment/edit/:id"
            element={
              <ProtectedSuAndCompanyRoute>
                <EditUnitAssessmentList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          <Route
            path="/partner/assessment_list_page"
            element={
              <ProtectedSuAndCompanyRoute>
                <AssessmentsList />
              </ProtectedSuAndCompanyRoute>
            }
          />
          {/* Assessment Question List  */}
          <Route
            path="/partner/questions/assessment/:assessmentId"
            element={
              <ProtectedSuAndCompanyRoute>
                <QuestionsPage />
              </ProtectedSuAndCompanyRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

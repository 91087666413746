import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container, Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import EditState from './EditState';
import AddState from './AddState';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/AuthUtils';

function StateList() {
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState(""); // Country filter state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
    fetchCategories();
  }, [page, rowsPerPage, searchTerm, selectedCountryId]); // Added selectedCountryId dependency

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setCountries(response.data.data);
      }
    } catch (err) {
      setError("Error fetching countries");
      setOpenSnackbar(true);
    }
  };

  const fetchCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchTerm,
            country: selectedCountryId,  // Sending country filter as a query param
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      setError("Error fetching categories");
      setOpenSnackbar(true);
    }
  };

  const getCountryNameById = (id) => {
    const country = countries.find(country => country._id === id);
    return country ? country.country_name : '';
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleCountryChange = (event) => {
    setSelectedCountryId(event.target.value);
    setPage(0);  // Reset to page 0 when country is changed
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCategoryId(null);
  };

  const handleDeleteCategory = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/state/delete/${id}`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSnackbarMessage('State deleted successfully');
        setOpenSnackbar(true);
        fetchCategories();
      }
    } catch (err) {
      setError('Failed to delete state');
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSelectedCategory(response.data.data);
        setSelectedCategoryId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setError('Error fetching state details');
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategoryId(null);
    setSelectedCategory(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddState = () => {
    setSnackbarMessage("State added successfully");
    setOpenSnackbar(true);
    handleCloseAddModal();
    fetchCategories();
  };

  const filteredstate = categories.filter((category) =>
    (category?.state_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    category?.state_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    getCountryNameById(category.country)?.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedCountryId ? category.country === selectedCountryId : true) // Apply country filter
  );

  const paginatedStates = filteredstate.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container maxWidth='100%'>
      <Box p={1} sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4">State Listing</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Filter by Country</InputLabel>
          <Select
            value={selectedCountryId}
            onChange={handleCountryChange}
            label="Filter by Country"
            size="small"
          >
            <MenuItem value="">
              <em>All Countries</em>
            </MenuItem>
            {countries.map((country) => (
              <MenuItem key={country._id} value={country._id}>
                {country.country_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State Name</TableCell>
              <TableCell>State Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredstate.length > 0 ? (
              filteredstate.map((category, index) => (
                <TableRow key={category._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{getCountryNameById(category.country)}</TableCell>
                  <TableCell>{category.state_name}</TableCell>
                  <TableCell>{category.state_code}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenEditModal(category._id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleOpenDeleteDialog(category._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No States found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems >= 25 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddState isOpen={openAddModal} onClose={handleCloseAddModal} onAdd={handleAddState} />
      <EditState
        isOpen={openEditModal}
        onClose={handleCloseEditModal}
        stateId={selectedCategoryId}
        onUpdate={() => {
          setSnackbarMessage("State updated successfully");
          setOpenSnackbar(true);
          handleCloseEditModal();
          fetchCategories();
        }}
      />
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this state?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={() => handleDeleteCategory(selectedCategoryId)} color="primary" variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default StateList;

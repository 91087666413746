import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Switch,
  CircularProgress,
  Container,
  Typography,
  Box,
  TablePagination,
  Grid,
  Paper,
  Snackbar,
  Alert,
  InputAdornment,
  Backdrop,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";

const DepartmentCategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    fetchCategories();
  }, [page, rowsPerPage, searchTerm]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
          },
        }
      );
      setCategories(response.data.data || []);
      setTotalItems(response.data.pagination.totalItems);
    } catch (error) {
      console.error("Error fetching categories", error);
      setError("Error fetching categories");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
    setSnackbarMessage("");
    };
  const handleSave = async (event) => {
    event.preventDefault();
    setError(null); // Reset error state

    setSaveLoading(true);
    setIsLoading(true);
    if (!currentCategory.department_category_name?.trim()) {
      setValidationError("Category name is required");
    setIsLoading(true);
      setSaveLoading(false);
      return;
    }
    setValidationError("");

    try {
      if (isEdit) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/department_category/${currentCategory._id}`,
          currentCategory
        );
        setSnackbarMessage("Category Updated successfully");
        setOpenSnackbar(true);
      } else {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/department_category`,
          currentCategory
        );
        setSnackbarMessage("Category added successfully");
        // setOpenSnackbar(true);
      }
      setOpenSnackbar(true);
      fetchCategories();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving category", error);
      setIsLoading(true);
      setError(error.response?.data?.message || "Error saving category");
      setOpenSnackbar(true);
    }finally {
      setIsLoading(false);
      setSaveLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/department_category/${deleteId}`
      );
      setSnackbarMessage("Category deleted successfully");
      setOpenSnackbar(true);
      fetchCategories();
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error deleting category", error);
      setError("Error deleting categories");
      setOpenSnackbar(true);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/department_category/${id}`,
        { status: status === 1 ? 0 : 1 }
      );
      setSnackbarMessage("Category status updated successfully");
      setOpenSnackbar(true);
      fetchCategories();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleOpenDialog = (category) => {
    setCurrentCategory(category || {});
    setIsEdit(!!category);
    setValidationError("");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentCategory({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredCategories = categories.filter((category) =>
    (category.department_category_name || "")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4"> Department Category</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Box></Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
          >
            Create
          </Button>
        </Box>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20px" }}> No.</TableCell>
                <TableCell> Category</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCategories.length > 0 ? (
                filteredCategories.map((category, index) => (
                  <TableRow key={category._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{category.department_category_name}</TableCell>
                    <TableCell>{new Date(category?.createdAt).toLocaleDateString()} </TableCell>
                    <TableCell sx={{ textAlign: "center !important" }}>
                      <Switch
                        checked={category.status === 1}
                        onChange={() =>
                          handleToggleStatus(category._id, category.status)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                      >
                        <IconButton onClick={() => handleOpenDialog(category)}>
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDeleteDialog(category._id)}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No categories found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems > 100 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            rowsPerPageOptions={[10, 50, 100, 200]} // Custom pagination options
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{isEdit ? "Edit  Category" : "Add  Category"}</DialogTitle>
        <form onSubmit={handleSave}>
        <DialogContent>
          <TextField
            label=" Category Name"
            fullWidth
            margin="normal"
            value={currentCategory.department_category_name || ""}
            required
            // error={!!validationError}
            // helperText={validationError}
            onChange={(e) =>
              setCurrentCategory({
                ...currentCategory,
                department_category_name: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button 
            // onClick={handleSave} 
            type="submit"
            color="primary" 
            variant="contained"
            disabled={isLoading}
            >
            {isLoading ? 'Adding...' : 'Add'}
          </Button>
        </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        open={saveLoading}
        style={{
          zIndex: 9999,
          color: "#fff",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DepartmentCategoryList;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Switch,
  CircularProgress,
  Container,
  Typography,
  Box,
  MenuItem,
  Grid,
  TablePagination,
  Paper,
  Snackbar,
  Alert,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { getToken } from "../../../utils/AuthUtils";

const QuizzSubCategoryList = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  // Fetch teacher categories
  const fetchTeacherCategories = useCallback(async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/quizz/category/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeacherCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching teacher categories", error);
    }
  }, []);

  // Fetch Subcategory
  const fetchSubCategories = useCallback(async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/quizz/sub-category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
              search: searchTerm,
            },
          }
        );
        setSubCategories(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (error) {
      console.error("Error fetching Subcategory", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, searchTerm]);

  useEffect(() => {
    fetchTeacherCategories();
    fetchSubCategories();
  }, [page, rowsPerPage, searchTerm]);

  // Handlers for dialog and form operations
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategoryId(newValue ? newValue._id : '');
    setPage(0);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setError(null); // Reset error state
      const token = getToken();
      if (token) {
        if (isEdit) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/quizz/sub-category/${currentSubCategory._id}`,
            currentSubCategory,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("Subcategory updated successfully");
        } else {
          await axios.post(
            `${process.env.REACT_APP_BACKEND}/api/quizz/sub-category`,
            currentSubCategory,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("Subcategory added successfully");
        }
        setOpenSnackbar(true);
        fetchSubCategories();
        handleCloseDialog();
      }
    } catch (error) {
      console.error("Error saving Subcategory", error);
      setError(error.response?.data?.message || "Error saving Subcategory");
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/quizz/sub-category/delete/${deleteId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("subcategory deleted successfully");
        setOpenSnackbar(true);
        fetchSubCategories();
        handleCloseDeleteDialog();
      }
    } catch (error) {
      console.error("Error deleting Subcategory", error);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/quizz/sub-category/${id}/status_quizz_sub_category`,
          { status: status === 1 ? 0 : 1 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("subcategory status added successfully");
        setOpenSnackbar(true);
        fetchSubCategories();
      }
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleOpenDialog = (subCategory) => {
    setCurrentSubCategory(subCategory || {});
    setIsEdit(!!subCategory);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentSubCategory({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredSubCategories = useMemo(
    () =>
      subCategories.filter(
        (subCategory) =>
          (!selectedCategoryId ||
            subCategory.quiz_category_id === selectedCategoryId) && // Filter by selected category
          subCategory.quiz_sub_category_name &&
          subCategory.quiz_sub_category_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      ),
    [subCategories, searchTerm, selectedCategoryId]
  );
  // Filter teacher categories to include only those with status === 1
  const activeTeacherCategories = useMemo(
    () => teacherCategories.filter((category) => category.status === 1),
    [teacherCategories]
  );
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Quiz Subcategory</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // mb={3}
        p={3}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Autocomplete
          options={activeTeacherCategories.sort((a, b) =>
            a.quiz_category_name.localeCompare(b.quiz_category_name, "en", {
              sensitivity: "base",
            })
          )}
          getOptionLabel={(option) => option.quiz_category_name || ''}
          value={activeTeacherCategories.find(cat => cat._id === selectedCategoryId) || null}
          onChange={handleCategoryChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Category"
              variant="outlined"
              size="small"
            />
          )}
          sx={{ width: "300px", marginRight: "10px" }}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Create
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20px" }}> No.</TableCell>
                <TableCell> Subcategory</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubCategories.length > 0 ? (
                filteredSubCategories.map((subCategory, index) => (
                  <TableRow key={subCategory._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{subCategory.quiz_sub_category_name}</TableCell>
                    <TableCell>
                      {activeTeacherCategories.find(
                        (cat) => cat._id === subCategory.quiz_category_id
                      )?.quiz_category_name || "Unknown"}
                    </TableCell>
                    <TableCell>{new Date(subCategory?.createdAt).toLocaleDateString()} </TableCell>
                    <TableCell sx={{ textAlign: "center !important" }}>
                      <Switch
                        checked={subCategory.status === 1}
                        onChange={() =>
                          handleToggleStatus(
                            subCategory._id,
                            subCategory.status
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                      >
                        <IconButton
                          onClick={() => handleOpenDialog(subCategory)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleOpenDeleteDialog(subCategory._id)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Subcategory found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems > 100 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}  maxWidth="sm" fullWidth>
        <DialogTitle>
          {isEdit ? "Edit Subcategory" : "Add Subcategory"}
        </DialogTitle>
        <form onSubmit={handleSave}>
        <DialogContent>
          <TextField
            select
            label=" Category"
            fullWidth
            margin="normal"
            required
            value={currentSubCategory.quiz_category_id || ""}
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                quiz_category_id: e.target.value,
              })
            }
          >
            {activeTeacherCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.quiz_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="  Name"
            fullWidth
            margin="normal"
            value={currentSubCategory.quiz_sub_category_name || ""}
            required
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                quiz_sub_category_name: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
            {isEdit ? "Update" : "Add"}
          </Button>
        </DialogActions>
        </form>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this Quiz sub category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default QuizzSubCategoryList;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  Box,
  TablePagination,
  Switch,
  TextField,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getToken } from "../../utils/AuthUtils";
import axios from "axios";

const ChaptersTable = () => {
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteChapterId, setDeleteChapterId] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const location = useLocation(); // Get location
  const navigate = useNavigate();

  useEffect(() => {
    const message = sessionStorage.getItem("labnameSuccessMessage");
    if (message) {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      sessionStorage.removeItem("labnameSuccessMessage"); // Remove the message after showing
    }
    fetchChapters();
    fetchCourseCategories();
    fetchCourseSubCategories();
    fetchCourses();
  }, [currentPage, pageSize, searchTerm, selectedCategory, selectedSubCategory, selectedCourse]);

 const fetchChapters = async () => {
  try {
    const token = getToken();
    if (token) {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/chapters`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage + 1, // Convert to 1-based index
            limit: pageSize,
            search: searchTerm,
            courseCategory: selectedCategory,
            courseSubCategory: selectedSubCategory,
            course: selectedCourse,
          },
        }
      );
      const data = response.data;
      if (data?.data) {
        setChapters(data.data);
        setTotalItems(data.pagination.totalItems);
        setError(null);
      } else {
        setError("No chapters found");
      }
    }
  } catch (err) {
    setError("Failed to fetch chapters");
  } finally {
    setLoading(false);
  }
};

  

  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchCourseSubCategories = async () => {
    try {
if (!selectedCategory){
  return;
}
      const token = getToken();

      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };

useEffect(()=>{
  fetchCourseSubCategories();
},[selectedCategory]);

  const fetchCourses = async () => {
    try {
      if (!selectedSubCategory){
        return;
      }
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses/getcourseby/${selectedSubCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourses(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch courses");
    }
  };

  useEffect(()=>{
    fetchCourses();
  },[selectedSubCategory]);
  

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateChapterStatus(id, newStatus);
  };
  

  const updateChapterStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/chapters/${id}/status_chapter`,
          { status },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setSnackbarMessage("Chapter status updated successfully");
        setOpenSnackbar(true);
        fetchChapters();
      }
    } catch (err) {
      setError("Failed to update chapter status");
    }
  };

  const handleOpenDialog = (id) => {
    setDeleteChapterId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteChapterId(null);
  };

  const handleDelete = async () => {
    try {
      const token = getToken();
      if (!token) {
        setError("No authorization token found");
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/chapters/delete/${deleteChapterId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.status === 200) {
        throw new Error("Failed to delete chapter");
      }

      setSnackbarMessage("Chapter deleted successfully");
      setOpenSnackbar(true);
      fetchChapters();
    } catch (err) {
      console.error(err);
      setError("Failed to delete chapter");
    } finally {
      handleCloseDialog();
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page
    console.log("Selected Category: ", event.target.value);  // Debugging line
  };
  

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderDescription = (chapter) => {
    const isExpanded = expandedDescriptions[chapter._id];
    const shortDescription =
      chapter.chapter_description.substring(0, 100) +
      (chapter.chapter_description.length > 100 ? "..." : "");

    return (
      <div>
        {isExpanded ? chapter.chapter_description : shortDescription}
        {chapter.chapter_description.length > 100 && (
          <Button onClick={() => toggleDescription(chapter._id)} size="small">
            {isExpanded ? "Read Less" : "Read More"}
          </Button>
        )}
      </div>
    );
  };

  const getCategoryName = (id) => {
    const category = courseCategories.find((cat) => cat._id === id);
    return category ? category.courses_name : "Unknown";
  };

  const getSubCategoryName = (id) => {
    const subCategory = courseSubCategories.find((sub) => sub._id === id);
    return subCategory ? subCategory.courses_sub_category_name : "Unknown";
  };

  const getCourseName = (id) => {
    const course = courses.find((c) => c._id === id);
    return course ? course.courses_name : "Unknown";
  };

  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0", mb: 2 }}>
        <Typography variant="h4">Chapters</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            label="Category"
            onChange={handleCategoryChange}>
            <MenuItem value="">All Categories</MenuItem>
            {courseCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.courses_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Subcategory</InputLabel>
          <Select
            value={selectedSubCategory}
            label="Subcategory"
            onChange={handleSubCategoryChange}>
            <MenuItem value="">All Subcategories</MenuItem>
            {courseSubCategories.map((subCategory) => (
              <MenuItem key={subCategory._id} value={subCategory._id}>
                {subCategory.courses_sub_category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Course</InputLabel>
          <Select
            value={selectedCourse}
            label="Course"
            onChange={handleCourseChange}>
            <MenuItem value="">All Courses</MenuItem>
            {courses.map((course) => (
              <MenuItem key={course._id} value={course._id}>
                {course.courses_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No</TableCell>
              <TableCell>Chapter Name</TableCell>
              <TableCell>Chapter Description</TableCell>
              <TableCell>Course Category</TableCell>
              <TableCell>Course Subcategory</TableCell>
              <TableCell>Course</TableCell>
           
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Alert severity="error">{error}</Alert>
                </TableCell>
              </TableRow>
            ) : chapters.length > 0 ? (
              chapters
                .map((chapter, index) => (
                  <TableRow key={chapter._id}>
                    <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                    <TableCell>{chapter.chapter_name}</TableCell>
                    <TableCell>{renderDescription(chapter)}</TableCell>
                    <TableCell>{getCategoryName(chapter.course_category_id)}</TableCell>
                    <TableCell>{chapter.course_sub_category_id.courses_sub_category_name}</TableCell>
                    <TableCell>{chapter.course_id.courses_name}</TableCell>
                    
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No chapters available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems >= 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
        />
      )}

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this chapter? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ChaptersTable;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Switch,
  Container,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const PartnerListPage = () => {
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [partnerToDelete, setPartnerToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [isDeleting, setIsDeleting] = useState(false);
  const [categories, setCategories] = useState([]);  // List of categories fetched from backend
  const [subcategories, setSubcategories] = useState([]);  // List of subcategories

  const [category, setCategory] = useState("");  // Selected category
  const [subcategory, setSubcategory] = useState(""); // Selected subcategory

  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);


  const navigate = useNavigate();
  const location = useLocation(); // Get location

  useEffect(() => {
    // Check for snackbar message in the location state and set it
    if (location.state?.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarOpen(true);

      // Clear the location state snackbar message after it has been set
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  const fetchPartners = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner`,
          {
            params: { searchTerm ,
              page: currentPage,
              limit: itemsPerPage,
              category,  // Pass the selected category filter
              subcategory, 
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPartners(response.data.data);
        // setTotalPages(response.data.pagination.totalPages);
      setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching partners");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, [searchTerm]);

  const handleAddPartner = () => navigate("/partners/add");
  const handleEditPartner = (id) => navigate(`/partners/edit/${id}`);
  const handleViewPartner = (id) => navigate(`/partners/view/${id}`);

  const handleDeleteConfirmation = (partner) => {
    setPartnerToDelete(partner);
    setOpenDialog(true);
  };



  //  fetch partners with category and partner subcategory filter 
 
 useEffect(() => {
  const fetchCategoriesAndSubcategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const categoryResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(categoryResponse.data.data);

        if (category) {
          // Fetch subcategories based on selected category
          const subcategoryResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category/getsubcat/${category}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSubcategories(subcategoryResponse.data.data);

          // get list of filter units
          const subcategoryResponse1 = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/partnerfilter/${category}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setPartners(subcategoryResponse1.data.data);
          console.log("filtered units:",subcategoryResponse1.data.data);
          
        }

        if (subcategory) {

          // get list of filter units
          const subcategoryResponse1 = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/partnerfilter/${category}/${subcategory}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setPartners(subcategoryResponse1.data.data);
          console.log("filtered units:",subcategoryResponse1.data.data);
          
        }

      }
    } catch (error) {
      console.error("Error fetching categories or subcategories:", error);
    }
  };

  fetchCategoriesAndSubcategories();
}, [category,subcategory]);  // Run this when the category changes


// handle delete partner

  const handleDeletePartner = async () => {
    setIsDeleting(true);
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/partner/delete/${partnerToDelete._id}`, {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOpenDialog(false);
        fetchPartners();
        setSnackbarMessage("Partner deleted successfully");
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage("Error deleting partner");
      setSnackbarType("error");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/partner/${id}/status_partner`,
          { status: status === 1 ? 0 : 1 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchPartners();
        setSnackbarMessage("Partner Updated successfully");
        setSnackbarType("success");
        setSnackbarOpen(true);
      
      }
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
    setPage(0);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0); // Reset to the first page
  // };

  // Filter partners based on the search term
  const filteredPartners = partners.filter((partner) =>
    partner.partner_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    partner.partner_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    partner.contact_person.toLowerCase().includes(searchTerm.toLowerCase()) ||
    String(partner.mobile).includes(searchTerm) ||
    partner.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    new Date(partner.agreement_start_date).toLocaleDateString().includes(searchTerm.toLowerCase()) ||
    new Date(partner.agreement_end_date).toLocaleDateString().includes(searchTerm.toLowerCase())
  );

  const paginatedPartners = filteredPartners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Partners
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "300px" }}
        />
          {/* Category Dropdown */}
          <TextField
          select
          label="Partner Category"
          value={category || ""}
          onChange={(e) => { setCategory(e.target.value); setPage(0); }}
          sx={{ width: "200px", marginRight: "20px" }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {categories.map((cat) => (
            <MenuItem key={cat._id} value={cat._id}>
              {cat.partner_name}
            </MenuItem>
          ))}
        </TextField>

        {/* Subcategory Dropdown */}
        <TextField
          select
          label="Partner Subcategory"
          value={subcategory || ""}
          onChange={(e) => { setSubcategory(e.target.value);
             setPage(0); }}
          sx={{ width: "200px" }}
          disabled={!category}  // Disable subcategory dropdown if no category is selected
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {subcategories.map((subcat) => (
            <MenuItem key={subcat._id} value={subcat._id}>
              {subcat.SubCategory_name}
            </MenuItem>
          ))}
        </TextField>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddPartner}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Partner Id</TableCell>
                  <TableCell>Partner Name</TableCell>
                  <TableCell>Contact Person</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Agreement Start Date</TableCell>
                  <TableCell>Agreement End Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPartners.length > 0 ? (
                  paginatedPartners.map((partner, index) => (
                    <TableRow key={partner._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{partner.partner_id}</TableCell>
                      <TableCell>{partner.partner_name}</TableCell>
                      <TableCell>{partner.contact_person}</TableCell>
                      <TableCell>{partner.mobile}</TableCell>
                      <TableCell>{partner.email}</TableCell>
                      <TableCell>
                        {new Date(partner.agreement_start_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {new Date(partner.agreement_end_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={partner.status === 1}
                          onChange={() => handleToggleStatus(partner._id, partner.status)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleViewPartner(partner._id)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleEditPartner(partner._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteConfirmation(partner)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      {searchTerm ? "No partners found matching your search." : "No partners available."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {totalItems > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={totalItems}
              rowsPerPage={itemsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this partner?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePartner} color="secondary" disabled={isDeleting}>
            {isDeleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PartnerListPage;

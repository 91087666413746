import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Container,
  DialogTitle,
  TextField,
  Switch,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/Visibility";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/system";
import { getToken } from "../../utils/AuthUtils";

const CompanyNewsPage = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [error, setError] = useState("");
  const [expandedNewsId, setExpandedNewsId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const navigate = useNavigate();
  const location = useLocation(); // Get location

  useEffect(() => {
    // Check for snackbar message in the location state and set it
    if (location.state?.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarOpen(true);

      // Clear the location state snackbar message after it has been set
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  const fetchNews = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/company_news`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNews(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } }catch (err) {
      setError("Error fetching company news");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const handleEditNews = (id) => {
    navigate(`/company_news/edit/${id}`);
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleViewQuestions = (assessmentId) => {
    navigate(`/questions/assessment/${assessmentId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteNews = async () => {
    try {
      const token = getToken();
      if (token) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/company_news/delete/${deleteId}`,{},{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchNews();
      setSnackbarMessage("Company news deleted successfully");
        setSnackbarType("error");
        setSnackbarOpen(true);
    } }catch (err) {
      setError("Error deleting company news");
      console.error(err);
    } finally {
      handleDeleteClose();
    }
  };

  const toggleExpand = (id) => {
    setExpandedNewsId(expandedNewsId === id ? null : id);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusToggle = async (id, currentStatus) => {
    const newStatus = !currentStatus;
    setNews((prev) =>
      prev.map((item) =>
        item._id === id ? { ...item, status: newStatus } : item
      )
    );
    try {
      const token = getToken();
      if (token) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/company_news/${id}/status_company_news`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarMessage("Company news updated successfully");
        setSnackbarType("success");
        setSnackbarOpen(true);
    } }catch {
      setNews((prev) =>
        prev.map((item) =>
          item._id === id ? { ...item, status: currentStatus } : item
        )
      );
      setError("Failed to update company news status");
    }
  };

  const filteredNews = news.filter((item) =>
    item?.company_news_title?.toLowerCase().includes(searchTerm.toLowerCase())||
  item?.company_news_coordinator?.toLowerCase().includes(searchTerm.toLowerCase())||
  item?.company_news_description?.toLowerCase().includes(searchTerm.toLowerCase())||
  new Date(item?.publish_date).toLocaleDateString().includes(searchTerm.toLowerCase()) // Ensure the date is in the same format as expected

  );

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Company News
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/company_news/add")}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>News Title</TableCell>
                <TableCell>Coordinator</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Published Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNews.length > 0 ? (
                filteredNews.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.company_news_title}</TableCell>
                    <TableCell>{item.company_news_coordinator}</TableCell>
                    <TableCell>
                      {item.company_news_image && (
                        <img
                          src={item.company_news_image}
                          alt={item.company_news_title}
                          style={{ width: "100px", height: "auto" }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {expandedNewsId === item._id ? (
                        <Typography>
                          {item.company_news_description}
                          <Button
                            onClick={() => toggleExpand(item._id)}
                            size="small"
                          >
                            Show Less
                          </Button>
                        </Typography>
                      ) : (
                        <Typography>
                          {item.company_news_description.length > 100
                            ? `${item.company_news_description.slice(
                                0,
                                100
                              )}...`
                            : item.company_news_description}
                          {item.company_news_description.length > 100 && (
                            <Button
                              onClick={() => toggleExpand(item._id)}
                              size="small"
                            >
                              Read More
                            </Button>
                          )}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {new Date(item.publish_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={item.status}
                        onChange={() =>
                          handleStatusToggle(item._id, item.status)
                        }
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditNews(item._id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteOpen(item._id)}>
                        <DeleteIcon />
                      </IconButton>
                      {/* <IconButton onClick={() => handleViewQuestions(item._id)}>
                        <ViewIcon />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No news found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this news item?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteNews} color="primary" variant="contained">
            Delete</Button>
        </DialogActions>
      </Dialog>
         {/* Snackbar for notifications */}
   <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
   <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
     {snackbarMessage}
   </Alert>
 </Snackbar>
    </Container>
  );
};

export default CompanyNewsPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const AddPartnerPage = () => {
  const [formData, setFormData] = useState({
    partner_name: "",
    contact_person: "",
    mobile: "",
    email: "",
    agreement_start_date: "",
    agreement_end_date: "",
    Partner_Category_id: "",
    Partner_sub_Category_id: "",
    countryId: "",
    stateId: "",
    districtId: "",
    townId: "",
    branch_name: "", // New field
    account_name: "", // New field
    account_number: "", // New field
    ifsc_code: "", // New field
  });
  const [errorVal, setErrorVal] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success or error

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [loading, setLoading] = useState({
    categories: false,
    subCategories: false,
    countries: false,
    states: false,
    districts: false,
    towns: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading((prev) => ({ ...prev, categories: true }));
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/partner/category`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCategories(response.data.data);
        }
      } finally {
        setLoading((prev) => ({ ...prev, categories: false }));
      }
    };

    const fetchCountries = async () => {
      setLoading((prev) => ({ ...prev, countries: true }));
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/country?limit=300`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCountries(response.data.data);
        }
      } finally {
        setLoading((prev) => ({ ...prev, countries: false }));
      }
    };

    fetchCategories();
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      setLoading((prev) => ({ ...prev, subCategories: true }));
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/partner/sub_category`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSubCategories(response.data.data);
        }
      } finally {
        setLoading((prev) => ({ ...prev, subCategories: false }));
      }
    };

    fetchSubCategories();
  }, []);

  useEffect(() => {
    if (formData.Partner_Category_id) {
      const filtered = subCategories.filter(
        (subcategory) => subcategory.partner_id === formData.Partner_Category_id
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories(subCategories);
    }
  }, [subCategories, formData.Partner_Category_id]);

  const fetchStates = async (countryId) => {
    setLoading((prev) => ({ ...prev, states: true }));
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/by-country/?limit=300`,
          {
            params: { countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch states:",
        err.response?.data?.message || err.message
      );
    } finally {
      setLoading((prev) => ({ ...prev, states: false }));
    }
  };

  const fetchDistricts = async (stateId) => {
    setLoading((prev) => ({ ...prev, districts: true }));
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/by-state?limit=300`,
          {
            params: { stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch districts:",
        err.response?.data?.message || err.message
      );
    } finally {
      setLoading((prev) => ({ ...prev, districts: false }));
    }
  };

  const fetchTowns = async (districtId) => {
    setLoading((prev) => ({ ...prev, towns: true }));
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/by-district`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { districtId },
          }
        );
        setTowns(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch towns:",
        err.response?.data?.message || err.message
      );
    } finally {
      setLoading((prev) => ({ ...prev, towns: false }));
    }
  };

  useEffect(() => {
    if (formData.countryId) {
      fetchStates(formData.countryId);
    } else {
      setStates([]);
      setFormData((prev) => ({
        ...prev,
        stateId: "",
        districtId: "",
        townId: "",
      }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) {
      fetchDistricts(formData.stateId);
    } else {
      setDistricts([]);
      setFormData((prev) => ({ ...prev, districtId: "", townId: "" }));
    }
  }, [formData.stateId]);

  useEffect(() => {
    if (formData.districtId) {
      fetchTowns(formData.districtId);
    } else {
      setTowns([]);
      setFormData((prev) => ({ ...prev, townId: "" }));
    }
  }, [formData.districtId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    console.log("-------------");
    
    e.preventDefault();
    try {
      const token = getToken();
      if (token) {
       const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/partner`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Partner added successfully!");
        setSnackbarType("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/partner_list", { state: { snackbarMessage: 'Partner Created successfully' } }), 2000);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        const errors = err.response.data.errors;

        // Handle errors field by field and set them in the error state
        const formErrors = {};
        errors.forEach((error) => {
          formErrors[error.field] = error.message;
        });

        // Update the error state with the field-specific error messages
        setErrorVal(formErrors);

        // Trigger error snackbar with a generic error message
        setSnackbarMessage(
          "Failed to add Partner. Please check your inputs and try again."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        // If no specific validation errors, show a generic error message
        setSnackbarMessage(
          "An unexpected error occurred. Please try again later."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
      setSnackbarMessage("Error adding partner.");
      setSnackbarType("error");
      setSnackbarOpen(true);
    }
  };

  const handleCancel = () => {
    navigate("/partner_list"); // Navigate to the desired route on cancel
  };

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4" component="h1">
          Add Partner
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* Category */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Category</InputLabel>
              <Select
                name="Partner_Category_id"
                value={formData.Partner_Category_id}
                onChange={handleChange}
                label="Category"
              >
                {loading.categories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : categories.length > 0 ? (
                  categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.partner_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No categories available</MenuItem>
                )}
              </Select>
              {errorVal.Partner_Category_id && (
                    <span className="error-message">{errorVal.Partner_Category_id}</span>
                  )}
            </FormControl>
          </Grid>
          {/* Subcategory */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              required
              disabled={!formData.Partner_Category_id}
            >
              <InputLabel>Subcategory</InputLabel>
              <Select
                name="Partner_sub_Category_id"
                value={formData.Partner_sub_Category_id}
                onChange={handleChange}
                label="Subcategory"
              >
                {loading.subCategories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : filteredSubCategories.length > 0 ? (
                  filteredSubCategories.map((subCategory) => (
                    <MenuItem key={subCategory._id} value={subCategory._id}>
                      {subCategory.SubCategory_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Subcategory available</MenuItem>
                )}
              </Select>
              {errorVal.Partner_Category_id && (
                    <span className="error-message">{errorVal.Partner_Category_id}</span>
                  )}
            </FormControl>
          </Grid>
          {/* Country */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Country</InputLabel>
              <Select
                name="countryId"
                value={formData.countryId}
                onChange={handleChange}
                label="Country"
              >
                {loading.countries ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : countries.length > 0 ? (
                  countries.map((country) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.country_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No countries available</MenuItem>
                )}
              </Select>
              {errorVal.countryId && (
                    <span className="error-message">{errorVal.countryId}</span>
                  )}
            </FormControl>
          </Grid>
          {/* State */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>State</InputLabel>
              <Select
                name="stateId"
                value={formData.stateId}
                onChange={handleChange}
                label="State"
              >
                {loading.states ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : states.length > 0 ? (
                  states.map((state) => (
                    <MenuItem key={state._id} value={state._id}>
                      {state.state_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No states available</MenuItem>
                )}
              </Select>
              {errorVal.stateId && (
                    <span className="error-message">{errorVal.stateId}</span>
                  )}
            </FormControl>
          </Grid>
          {/* District */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>District</InputLabel>
              <Select
                name="districtId"
                value={formData.districtId}
                onChange={handleChange}
                label="District"
              >
                {loading.districts ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : districts.length > 0 ? (
                  districts.map((district) => (
                    <MenuItem key={district._id} value={district._id}>
                      {district.district_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No districts available</MenuItem>
                )}
              </Select>
              {errorVal.districtId && (
                    <span className="error-message">{errorVal.districtId}</span>
                  )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Place"
              name="place"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              sx={{ mb: 2 }}
              error={!!errorVal.place}
              helperText={errorVal.place}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Partner Name"
              name="partner_name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.partner_name}
              helperText={errorVal.partner_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact Person"
              name="contact_person"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.contact_person}
              helperText={errorVal.contact_person}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile"
              name="mobile"
              type="number"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.mobile}
              helperText={errorVal.mobile}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.email}
              helperText={errorVal.email}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Agreement Start Date"
              name="agreement_start_date"
              type="date"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true, // Keeps the label above the input
              }}
              error={!!errorVal.agreement_start_date}
              helperText={errorVal.agreement_start_date}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Agreement End Date"
              name="agreement_end_date"
              type="date"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true, // Keeps the label above the input
              }}
              error={!!errorVal.agreement_end_date}
              helperText={errorVal.agreement_end_date}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="GST Number"
              name="gst_no"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.gst_no}
              helperText={errorVal.gst_no}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Whatsapp Number"
              name="whatsapp_no"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.whatsapp_no}
              helperText={errorVal.whatsapp_no}
            />
          </Grid>

          {/* bank deatils */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Bank Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Bank Name"
              name="bank_name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.bank_name}
              helperText={errorVal.bank_name}
            />
          </Grid>

          {/* Branch Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Branch Name"
              name="branch_name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.branch_name}
              helperText={errorVal.branch_name}
            />
          </Grid>

          {/* Account Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Account Name"
              name="account_name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.account_name}
              helperText={errorVal.account_name}
            />
          </Grid>

          {/* Account Number */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Account Number"
              name="account_number"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.account_number}
              helperText={errorVal.account_number}
            />
          </Grid>

          {/* IFSC Code */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="IFSC Code"
              name="ifsc_code"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              error={!!errorVal.ifsc_code}
              helperText={errorVal.ifsc_code}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          sx={{ mt: 2, backgroundColor: "#e0e0e0" }}
        >
          Create
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancel} // You can define this function to handle the cancel action
          sx={{ mt: 0 }} // No margin-top needed for alignment
        >
          Cancel
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddPartnerPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";

const AddState = ({ isOpen, onClose, onAdd }) => {
  const [countries, setCountries] = useState([]);
  const [stateName, setStateName] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch countries");
    }
  };

  const handleAddState = async () => {
    try {
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/state`,
          {
            state_name: stateName,
            state_code: stateCode,
            country: selectedCountry,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        onAdd();
        onClose();
      }
    } catch (err) {
      console.error("Failed to add state");
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.country_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          label="State Name"
          value={stateName}
          onChange={(e) => setStateName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="State Code"
          value={stateCode}
          onChange={(e) => setStateCode(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddState} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddState;

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TablePagination,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import { useNavigate } from "react-router-dom";

const ListingPage = () => {
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [partners, setPartners] = useState({});
  const [partnerIds, setPartnerIds] = useState({});
  const [courses, setCourses] = useState({});
  const [categories, setCategories] = useState({});
  const [subCategories, setSubCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(""); // Added category filter
  const [subCategoryFilter, setSubCategoryFilter] = useState(""); // Added sub-category filter
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(70); // Set initial rows per page to 50
  const [totalItems, setTotalItems] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [filterSubcat, setFilterSubcat] = useState({});

  const navigate = useNavigate();
  const token = getToken();


 // Fetch Subcategories based on the selected category
 const fetchCourseSubCategories = async () => {
  if (!categoryFilter) {
    return; // Return early if no category is selected
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${categoryFilter}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Assuming the response contains subcategories with a 'courses_sub_category_name' field
    setFilterSubcat(
      response.data.data.reduce((acc, subcategory) => {
        acc[subcategory._id] = subcategory.courses_sub_category_name;
        return acc;
      }, {})
    );
  } catch (err) {
    setError("Failed to fetch course subcategories");
  }
};

useEffect(() => {
  fetchCourseSubCategories();
}, [categoryFilter]); 


  useEffect(() => {
    // Check for the success message in sessionStorage
    const message = sessionStorage.getItem("assignSuccessMessage");
    if (message) {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
      sessionStorage.removeItem("assignSuccessMessage"); // Remove the message after showing
    }
  }, [page, rowsPerPage, searchTerm]);

  const fetchAssignedCourses = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
            },
          }
        );
        setAssignedCourses(data.data);
        setTotalItems(data.pagination.totalItems);
      }
    } catch (error) {
      setError("Failed to fetch assigned courses.");
      console.error("Error fetching assigned courses:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch assigned courses
  useEffect(() => {
    fetchAssignedCourses();
  }, [page, rowsPerPage]);

  // Fetch partners, courses, categories, and Subcategory based on IDs
  useEffect(() => {
    const fetchPartnersCoursesCategories = async () => {
      if (assignedCourses.length === 0) return;

      const partnerIds = [
        ...new Set(assignedCourses.map((assignment) => assignment.partner_id)),
      ];
      const courseIds = [
        ...new Set(assignedCourses.map((assignment) => assignment.course_id)),
      ];

      try {
        const token = getToken();
        if (token) {
          const [
            partnersResponse,
            coursesResponse,
            categoriesResponse,
            subCategoriesResponse,
          ] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BACKEND}/api/partner`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/without_pagination`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(
              `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/nopagenation`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            ),
          ]);


          
          const partnersData = partnersResponse.data.data.reduce(
            (acc, partner) => {
              acc[partner._id] = partner.partner_name;
              return acc;
            },
            {}
          );

          const partnersId = partnersResponse.data.data.reduce(
            (acc, partner) => {
              acc[partner._id] = partner.partner_id;
              return acc;
            },
            {}
          );

          const coursesData = coursesResponse.data.data.reduce(
            (acc, course) => {
              acc[course._id] = course.courses_name;
              return acc;
            },
            {}
          );

          const categoriesData = categoriesResponse.data.data.reduce(
            (acc, category) => {
              acc[category._id] = category.courses_name;
              return acc;
            },
            {}
          );

          const subCategoriesData = subCategoriesResponse.data.data.reduce(
            (acc, subCategory) => {
              acc[subCategory._id] = subCategory.courses_sub_category_name;
              return acc;
            },
            {}
          );

          setPartners(partnersData);
          setCourses(coursesData);
          setCategories(categoriesData);
          setSubCategories(subCategoriesData);
          setPartnerIds(partnersId);
        }
      } catch (error) {
        setError(
          "Failed to fetch partners, courses, categories, and Subcategory."
        );
        console.error(
          "Error fetching partners, courses, categories, and Subcategory:",
          error
        );
      }
    };

    fetchPartnersCoursesCategories();
  }, [assignedCourses, token]);

  const handleSnackbarClose = () => {
    setError(null);
  };

  const handleAddCourse = () => {
    navigate("/assign_courses_to_partner");
  };

  const handleEditCourse = (id) => {
    navigate(`/assign_courses_to_partner/edit/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    console.log("Changing page to:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const newRowsPerPage = parseInt(event.target.value, 10);
    console.log("Changing rows per page to:", newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const openDeleteDialog = (_id) => {
    setCourseToDelete(_id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/delete/${courseToDelete}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          closeDeleteDialog();
          fetchAssignedCourses(); // Call to fetch assigned courses again to refresh the list

          // Show success Snackbar message
          setSnackbarMessage("Course assignment to partner deleted successfully.");
          setSnackbarType("success");
          setSnackbarOpen(true);
        }
      } catch (err) {
        setError("Failed to delete the course.");
        console.error("Error deleting course:", err);
      }
    }
  };

  // Filter and paginate assigned courses
  const filteredCourses = assignedCourses.filter((assignment) => {
    const courseName = courses[assignment.course_id] || "";
    const partnerName = partners[assignment.partner_id] || "";
    const courseCategory = categories[assignment.course_category_id] || "";
    const partnerId = partnerIds[assignment.partner_id] || "";
    const courseSubCategory =
      subCategories[assignment.course_sub_category_id] || "";

    const isCategoryMatch = categoryFilter
      ? assignment.course_category_id === categoryFilter
      : true;
    const isSubCategoryMatch = subCategoryFilter
      ? assignment.course_sub_category_id === subCategoryFilter
      : true;

    return (
      (courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        partnerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        courseCategory.toLowerCase().includes(searchTerm.toLowerCase()) ||
        partnerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        courseSubCategory.toLowerCase().includes(searchTerm.toLowerCase())) &&
      isCategoryMatch &&
      isSubCategoryMatch
    );
  });

  const paginatedCourses = filteredCourses.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.log("Paginated courses:", paginatedCourses);

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4" component="h1">
          Assigned Courses to Partner List
        </Typography>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Snackbar
              open={error !== null}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </Snackbar>
          )}

          <Box
            display="flex"
            flexDirection="column"
            padding={2}
            sx={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
                sx={{ width: "300px" }}
              />

              <Box display="flex" gap={2}>
                <TextField
                  select
                  label="Category"
                  variant="outlined"
                  size="small"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  sx={{ width: "200px" }}
                >
                  <MenuItem value="">All Categories</MenuItem>
                  {Object.entries(categories).map(([id, name]) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  label="Sub-category"
                  variant="outlined"
                  size="small"
                  value={subCategoryFilter}
                  onChange={(e) => setSubCategoryFilter(e.target.value)}
                  sx={{ width: "200px" }}
                >
                  <MenuItem value="">All Sub-categories</MenuItem>
                  {Object.entries(filterSubcat).map(([id, name]) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddCourse}
              >
                Assign Course To Partner
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial No.</TableCell>
                    <TableCell>PartnerID</TableCell>
                    <TableCell>Partner Name</TableCell>
                    <TableCell>Course Category</TableCell>
                    <TableCell>Course Subcategory</TableCell>
                    <TableCell>Assigned Course Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCourses.map((assignment, index) => (
                    <TableRow key={assignment._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>
                        {partnerIds[assignment.partner_id] || "Loading..."}
                      </TableCell>
                      <TableCell>
                        {partners[assignment.partner_id] || "Loading..."}
                      </TableCell>
                      <TableCell>
                        {categories[assignment.course_category_id] || "Loading..."}
                      </TableCell>
                      <TableCell>
                        {subCategories[assignment.course_sub_category_id] || "Loading..."}
                      </TableCell>
                      <TableCell>
                        {courses[assignment.course_id] || "Loading..."}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleEditCourse(assignment._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => openDeleteDialog(assignment._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {assignedCourses.length >= 1 && ( // Show pagination only if there are enough items
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage=""
              showFirstButton
              showLastButton
              disabled={loading} // Disable while loading
            />
          )}

          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this course?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog}>Cancel</Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDeleteCourse}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ListingPage;

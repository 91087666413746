import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { updateSchool } from '../../../server/SchoolServices'; // Adjust import path

const UpdateSchool = ({ show, onHide, schoolId, existingData }) => {
    const [schoolData, setSchoolData] = useState({
        name: '',
        location: '',
        principal: ''
    });

    useEffect(() => {
        if (existingData) {
            setSchoolData(existingData);
        }
    }, [existingData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSchoolData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            await updateSchool(schoolId, schoolData);
            onHide(); // Close modal on success
            // Optionally refresh school list or show success notification
        } catch (error) {
            console.error('Error updating school:', error);
            // Optionally show error notification
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Update School</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formSchoolName">
                        <Form.Label>School Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter school name"
                            name="name"
                            value={schoolData.name}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSchoolLocation">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter location"
                            name="location"
                            value={schoolData.location}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSchoolPrincipal">
                        <Form.Label>Principal</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter principal name"
                            name="principal"
                            value={schoolData.principal}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}> 
                    Update School
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateSchool;

import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

function AddProjectModal({ open, onClose, onAdd }) {
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false); // Ensure this is defined

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!projectName.trim()) {
      setSnackbarMessage("Project name cannot be empty");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    setIsLoading(true);

    try {
      const token = getToken();
      if (token) {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/project/category`,
        {
          method: "POST",
          headers: { 
              Authorization: `Bearer ${token}`,
            "Content-Type": "application/json" },
          body: JSON.stringify({ project_category_name: projectName }),
        }
      );

      if (response.ok) {
       
        onAdd(); // Notify parent to refresh data
        setProjectName(""); // Reset project name
        onClose(); // Close the modal
      } else {
        setSnackbarMessage("Failed to add project");
        setSnackbarSeverity("error");
      }
    } }catch (error) {
      setSnackbarMessage("Error occurred");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false); // Ensure Snackbar closes properly
  };

  return (
    <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth>
      <DialogTitle>Add  Category</DialogTitle>
      <form onSubmit={handleFormSubmit}>
      <DialogContent>
          <TextField
            label="Name"
            value={projectName}
            onChange={handleProjectNameChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            autoFocus
          />
          {/* <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add 
              </Button>
            )}
          </Box> */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <Button color="inherit" onClick={handleSnackbarClose}>
              Close
            </Button>
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary"disabled={isLoading}>
            {isLoading ? 'Adding...' : 'Add'}
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddProjectModal;

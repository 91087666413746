import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Switch,
  CircularProgress,
  Container,
  Typography,
  Box,
  MenuItem,
  TablePagination,
  Paper,
  Snackbar,
  Alert,
  InputAdornment,
  Grid,
  Backdrop,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";

const DepartmentSubCategoryList = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [subCategoryToDelete, setSubCategoryToDelete] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    category: "",
  });

  const [totalCount, setTotalCount] = useState(0);
  const [severity, setSeverity] = useState('success');

  useEffect(() => {
    fetchSubCategories();
    fetchDepartmentCategories();
  }, [page, rowsPerPage, searchTerm, selectedCategory]); // Add selectedCategory

  const fetchSubCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
            department_category_id: selectedCategory, // Include selected category
          },
        }
      );
      setSubCategories(response.data.data);
      setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Subcategory", error);
      setLoading(false);
    }
  };

  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category`
      );
      setCategories(response.data.data.filter((cat) => cat.status === 1)); // Filter categories based on status
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const validateFields = () => {
    const newErrors = {
      name: "",
      category: "",
    };
    let isValid = true;

    if (!currentSubCategory.department_sub_category_name) {
      newErrors.name = "Subcategory Name is required";
      isValid = false;
    }

    if (!currentSubCategory.department_category_id) {
      newErrors.category = "Department Category is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async (e) => {
    e.preventDefault(); // Add this to prevent form default submission
    setSaveLoading(true);
    setIsLoading(true);
  
    if (!validateFields()) {
      setSaveLoading(false);
      setIsLoading(false);
      setSnackbarMessage("Please fill all required fields");
      setSeverity('error');
      setOpenSnackbar(true);
      return;
    }
  
    try {
      let response;
      if (isEdit) {
        response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${currentSubCategory._id}`,
          currentSubCategory
        );
        setSnackbarMessage("Subcategory updated successfully");
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/department_category_sub`,
          currentSubCategory
        );
        setSnackbarMessage("Subcategory added successfully");
      }
      setSeverity('success');
      setOpenSnackbar(true);
      fetchSubCategories();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving subcategory", error);
      setSnackbarMessage(error.response?.data?.message || "Error saving subcategory");
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setSaveLoading(false);
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${id}`
      );
      setSnackbarMessage("subcategory deleted successfully");
      setOpenSnackbar(true);
      fetchSubCategories();
      handleCloseConfirmDialog();
    } catch (error) {
      console.error("Error deleting subcategory", error);
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${id}`,
        { status: newStatus }
      );
      setSnackbarMessage("subcategory status updated successfully");
      setOpenSnackbar(true);
      fetchSubCategories();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleOpenDialog = (subCategory) => {
    setCurrentSubCategory(
      subCategory
        ? { ...subCategory }
        : { department_sub_category_name: "", department_category_id: "" }
    );
    setIsEdit(!!subCategory);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentSubCategory({});
    setOpenDialog(false);
  };

  const handleOpenConfirmDialog = (subCategory) => {
    setSubCategoryToDelete(subCategory);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setSubCategoryToDelete(null);
    setOpenConfirmDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const filteredCategories = subCategories
    .filter((subCategory) =>
      subCategory.department_sub_category_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .filter((subCategory) =>
      selectedCategory
        ? subCategory.department_category_id === selectedCategory
        : true
    );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4"> Department Subcategory</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Grid container spacing={2} mb={2} alignItems="flex-start">
        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={categories.sort((a, b) =>
              a.department_category_name.localeCompare(
                b.department_category_name,
                "en",
                { sensitivity: "base" }
              )
            )}
            getOptionLabel={(option) => option.department_category_name || ''}
            value={categories.find(cat => cat._id === selectedCategory) || null}
            onChange={(event, newValue) => {
              setSelectedCategory(newValue ? newValue._id : '');
              setPage(0);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Category"
                variant="outlined"
                size="small"
              />
            )}
            style={{ width: "300px", marginRight: "10px" }}
            clearOnBlur={false}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent="flex-end"
          sx={{ order: 2, marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}>
            Create
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20px" }}> No.</TableCell>
                  <TableCell>Subcategory </TableCell>
                  <TableCell> Category</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCategories.length > 0 ? (
                  filteredCategories.map((subCategory, index) => (
                    <TableRow key={subCategory._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>
                        {subCategory.department_sub_category_name}
                      </TableCell>
                      <TableCell>
                        {categories.find(
                          (cat) =>
                            cat._id === subCategory.department_category_id
                        )?.department_category_name || "N/A"}
                      </TableCell>
                      <TableCell>{new Date(subCategory?.createdAt).toLocaleDateString()} </TableCell>
                      <TableCell sx={{ textAlign: "center !important" }}>
                        <Switch
                          checked={subCategory.status === 1}
                          onChange={() =>
                            handleToggleStatus(
                              subCategory._id,
                              subCategory.status
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                        justifyContent="center"
                          alignItems="center" // Optional, aligns buttons horizontally in the center
                        >
                          <IconButton
                            onClick={() => handleOpenDialog(subCategory)}>
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleOpenConfirmDialog(subCategory)
                            }>
                            <Delete />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Subcategory found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {totalItems > 100 && (
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage=""
                rowsPerPageOptions={[10, 50, 100, 200]} // Custom pagination options
                showFirstButton
                showLastButton
              />
            </Grid>
          )}
        </>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>
          {isEdit ? "Edit Subcategory" : "Add Subcategory"}
        </DialogTitle>
        <form onSubmit={handleSave}>

        <DialogContent>
          <TextField
            select
            label=" Category"
            fullWidth
            margin="normal"
            value={currentSubCategory.department_category_id || ""}
            required
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                department_category_id: e.target.value,
              })
            }
            // error={!!errors.category}
            // helperText={errors.category}
            >
            {categories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.department_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label=" Name"
            fullWidth
            margin="normal"
            value={currentSubCategory.department_sub_category_name || ""}
            required
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                department_sub_category_name: e.target.value,
              })
            }
            // error={!!errors.name}
            // helperText={errors.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            // onClick={handleSave}
            type="submit"
            color="primary"
            variant="contained"
            // sx={{ backgroundColor: "#e0e0e0" }}
            disabled={isLoading}
          >
            {isEdit ? "Update" : "Add"}
          </Button>
        </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this Subcategory?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (subCategoryToDelete) {
                handleDelete(subCategoryToDelete._id);
              }
            }}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}>
            Delete 
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          // variant="filled"
          // sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        open={saveLoading}
        style={{
          zIndex: 1301, // Higher than Dialog's default zIndex
          color: "#fff",
        }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DepartmentSubCategoryList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddDistrict from "./AddDistrict"; // Adjust the path as needed
import EditDistrict from "./EditDistrict"; // Adjust the path as needed
import { getToken } from "../utils/AuthUtils";

const DistrictList = () => {
  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [countryFilter, setCountryFilter] = useState(""); // Country filter state
  const [stateFilter, setStateFilter] = useState(""); // State filter state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);

  useEffect(() => {
    fetchDistricts();
    fetchCountries();
    // Fetch states when country is selected or updated
    if (countryFilter) {
      fetchStates(countryFilter);
    }
  }, [page, rowsPerPage, searchTerm, countryFilter, stateFilter]);

  const fetchDistricts = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
              country: countryFilter, // Include country filter
              state: stateFilter, // Include state filter
            },
          }
        );
        setDistricts(response.data.data);
        setTotalItems(response.data.pagination.totalItems || 0);
      }
    } catch (err) {
      setError("Error fetching districts");
      setOpenSnackbar(true);
    }
  };

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      setError("Error fetching countries");
      setOpenSnackbar(true);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/without_pagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              country: countryId, // Fetch states based on selected country
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch states");
    }
  };

  const getCountryNameById = (id) => {
    const country = countries.find((country) => country._id === id);
    return country ? country.country_name : "Unknown";
  };

  const getStateNameById = (id) => {
    const state = states.find((state) => state._id === id);
    return state ? state.state_name : "Unknown";
  };

  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleAddDistrict = () => {
    handleCloseAddModal();
    fetchDistricts();
    setSnackbarMessage("District added successfully");
    setOpenSnackbar(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountryFilter(selectedCountry);

    // Reset state filter and states when "All Countries" is selected
    if (!selectedCountry) {
      setStateFilter(""); // Reset state filter
      setStates([]); // Clear the list of states
    } else {
      fetchStates(selectedCountry); // Fetch states for the selected country
    }
  };

  const handleStateChange = (event) => {
    setStateFilter(event.target.value);
  };

  const handleOpenEditModal = (id) => {
    setSelectedDistrictId(id);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleOpenDeleteDialog = (id) => {
    setSelectedDistrictId(id);
    setOpenDeleteDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteDistrict = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/district/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("District deleted successfully");
        setOpenSnackbar(true);
        setOpenDeleteDialog(false);
        fetchDistricts();
      }
    } catch (err) {
      setError("Failed to delete district");
      setOpenSnackbar(true);
    }
  };

  const filteredDistricts = districts.filter(
    (district) =>
      district?.district_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      district?.district_code
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getCountryNameById(district?.country)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      getStateNameById(district?.state)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">District Listing</Typography>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  countryFilter
                    ? countries.find((country) => country._id === countryFilter)
                    : null
                } // Find the selected country by ID
                onChange={(e, newValue) =>
                  setCountryFilter(newValue ? newValue._id : "")
                } // Handle the change to update selected country ID
                options={countries} // List of countries
                getOptionLabel={(option) => option.country_name} // Display the country name in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match by country ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allows clearing the selection if needed
              />
            </FormControl>
          </Grid>
         {countryFilter && 
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  stateFilter
                    ? states.find((state) => state._id === stateFilter)
                    : null
                } // Find the selected state by ID
                onChange={(e, newValue) =>
                  setStateFilter(newValue ? newValue._id : "")
                } // Update stateFilter when a state is selected
                options={states.filter(
                  (state) => state.country === countryFilter
                )} // Filter states by selected country
                getOptionLabel={(option) => option.state_name} // Display the state name in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match by state ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allows clearing the selection if needed
              />
            </FormControl>
          </Grid>}

          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenAddModal}
              sx={{ backgroundColor: "#e0e0e0" }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State </TableCell>
              <TableCell> Name</TableCell>
              <TableCell> Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDistricts.length > 0 ? (
              filteredDistricts.map((district, index) => (
                <TableRow key={district._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{getCountryNameById(district.country)}</TableCell>
                  <TableCell>{district.state.state_name}</TableCell>
                  <TableCell>{district.district_name}</TableCell>
                  <TableCell>{district.district_code}</TableCell>
                  <TableCell>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                    >
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditModal(district._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(district._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No districts found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 100 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      {openEditModal && (
        <EditDistrict
          isOpen={openEditModal}
          onClose={handleCloseEditModal}
          onUpdate={() => {
            setSnackbarMessage("District updated successfully");
            setOpenSnackbar(true);
            handleCloseEditModal();
            fetchDistricts();
          }}
          districtId={selectedDistrictId}
        />
      )}
      {openAddModal && (
        <AddDistrict
          isOpen={openAddModal}
          onClose={handleCloseAddModal}
          onAdd={handleAddDistrict}
        />
      )}
      {openDeleteDialog && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography> Are you sure you want to delete this  district?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteDistrict(selectedDistrictId)}
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default DistrictList;

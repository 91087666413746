import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  CircularProgress,
  Container,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { getToken } from "../../utils/AuthUtils";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const ViewSchoolPage = () => {
  const { id } = useParams();
  const [school, setSchool] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getToken();
        if (!token) {
          setError("No authentication token found");
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category?page=1&limit=50`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCategories(response.data.data);
      } catch (err) {
        console.error(err); // Log error for debugging
        setError("Failed to fetch categories");
      }
    };

    const fetchSubCategories = async () => {
      try {
        const token = getToken();
        if (!token) {
          setError("No authentication token found");
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category?page=1&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSubCategories(response.data.data);
      } catch (err) {
        console.error(err); // Log error for debugging
        setError("Failed to fetch subcategories");
      }
    };

    const fetchSchoolDetails = async () => {
      try {
        const token = getToken();
        if (!token) {
          setError("No authentication token found");
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSchool(response.data.data);
      } catch (err) {
        console.error(err); // Log error for debugging
        setError("Failed to fetch school details");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchSubCategories();
    fetchSchoolDetails();
  }, [id]);

  const getCategoryName = (id) => {
    const category = categories.find((cat) => cat._id === id);
    return category ? category.school_name : "Unknown";
  };

  const getSubCategoryName = (id) => {
    const subCategory = subCategories.find((subCat) => subCat._id === id);
    return subCategory ? subCategory.SubCategory_name : "Unknown";
  };

  return (
    <Container maxWidth="100%" sx={{ padding: { xs: 2, md: 4 } }}>
      {/* Breadcrumbs */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Link underline="hover" color="inherit" href="/partner">
          <HomeIcon sx={{ mr: 0.5, verticalAlign: "middle" }} />
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/partner/school_list">
          Schools
        </Link>
        <Typography color="text.primary">School Details</Typography>
      </Breadcrumbs>

      {/* Header Box */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        padding={2}
        sx={{ backgroundColor: "#f5f5f5", borderRadius: 1, textAlign: "left" }}
      >
        <Typography variant="h5" fontWeight="bold">
          School Details
        </Typography>
      </Box>

      {/* Loading / Error / Content Handling */}
      {loading ? (
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center" mt={3}>
          {error}
        </Typography>
      ) : (
        <Paper
          elevation={3}
          sx={{ padding: { xs: 2, md: 4 }, borderRadius: 2 }}
        >
          <Grid container spacing={3}>
            {/* School Information */}
            {[
              { label: "School Name", value: school.school_name },
              { label: "School Code", value: school.school_code },
              {
                label: "Category",
                value: getCategoryName(school.school_category),
              },
              {
                label: "Subcategory",
                value: getSubCategoryName(school.school_sub_category),
              },
              { label: "Address", value: school.school_address },
              { label: "Pin Code", value: school.school_pin_code },
              { label: "Website", value: school.school_website },
              { label: "Email", value: school.school_email },
              { label: "Principal Name", value: school.principal_name },
              {
                label: "Principal Contact No",
                value: school.principal_contact_no,
              },
              { label: "Principal Email", value: school.principal_email },
              { label: "Admin Name", value: school.admin_name },
              { label: "Admin Contact No", value: school.admin_contact_no },
              { label: "Admin Email", value: school.admin_email },
              {
                label: "Total Number of Students",
                value: school.total_number_of_students,
              },
            ].map(({ label, value }, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ mr: 2, fontSize: "18px", textAlign: "left" }}
                  >
                    {label}:
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "15px", textAlign: "left" }}
                  >
                    {value || "N/A"}
                  </Typography>
                </Box>
              </Grid>
            ))}

            {/* Social Media Links with Icons */}
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ mb: 1, textAlign: "left" }}
              >
                Social Media Links:
              </Typography>
              <Box display="flex" alignItems="center" mb={1}>
                <LinkedInIcon sx={{ mr: 1, color: "#0077b5" }} />
                <Typography variant="body1">
                  {school.social_media_linkedin || "N/A"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <InstagramIcon sx={{ mr: 1, color: "#e1306c" }} />
                <Typography variant="body1">
                  {school.social_media_instagram || "N/A"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <FacebookIcon sx={{ mr: 1, color: "#4267B2" }} />
                <Typography variant="body1">
                  {school.social_media_facebook || "N/A"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Container>
  );
};

export default ViewSchoolPage;

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

function EditProjectSub({ open, onClose, onUpdate, categoryId }) {
  const [projectName, setProjectName] = useState("");
  const [selectedProjectCategory, setSelectedProjectCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [projectCategories, setProjectCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [categoriesError, setCategoriesError] = useState("");
  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/project/category`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token_key')
              }
          }
     
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProjectCategories(data.data);
      } catch (error) {
        setCategoriesError("Error fetching categories");
      } finally {
        setCategoriesLoading(false);
      }
    };

    const fetchCategoryData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/project/category_sub/${categoryId}`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token_key')
              }
          }
     
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCategoryData(data.data);
        setProjectName(data.data.project_sub_category_name);
        setSelectedProjectCategory(data.data.project_category_id);
      } catch (error) {
        setSnackbarMessage("Error fetching category details");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    };

    if (open) {
      fetchCategories();
      if (categoryId) {
        fetchCategoryData();
      }
    }
  }, [open, categoryId]);

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedProjectCategory(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!projectName.trim() || !selectedProjectCategory) {
      setSnackbarMessage("All fields are required");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/project/category_sub/${categoryId}`,
        {
          method: "PUT",
          headers: { 
            Authorization: "Bearer " + localStorage.getItem("token_key"),
            "Content-Type": "application/json" },
          body: JSON.stringify({
            project_sub_category_name: projectName,
            project_category_id: selectedProjectCategory, // Include selected category ID
          }),
        }
      );
      setSnackbarMessage("Project updated successfully");
      setSnackbarSeverity("success");
      onUpdate(); // Notify parent to refresh data
      onClose(); // Close the modal
    } catch (error) {
      setSnackbarMessage("Error occurred");
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Filter categories to include only those with status = 1
  const filteredCategories = projectCategories.filter(
    (category) => category.status === 1
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Subcategory</DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit}>
        <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Project Category*</InputLabel>
            <Select
              value={selectedProjectCategory}
              onChange={handleCategoryChange}
              label="Project Category"
              required
              disabled={categoriesLoading}
            >
              {categoriesLoading ? (
                <MenuItem disabled>Loading categories...</MenuItem>
              ) : categoriesError ? (
                <MenuItem disabled>Error fetching categories</MenuItem>
              ) : filteredCategories.length > 0 ? (
                filteredCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.project_category_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No categories available</MenuItem>
              )}
            </Select>
          </FormControl>

          <TextField
            label="Project Name"
            value={projectName}
            onChange={handleProjectNameChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            autoFocus
          />

          {/* <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            )}
          </Box> */}
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          action={
            <Button color="inherit" onClick={handleSnackbarClose}>
              Close
            </Button>
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" onClick={handleFormSubmit} variant="contained" color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProjectSub;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Container,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import { PhotoCamera } from "@mui/icons-material"; // For the file upload icon
import "./AddSchoolMainPartner.css";

const AddSchoolMain = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    School_id: "",
    school_code: "",
    school_category: "",
    school_sub_category: "",
    countryId: "",
    stateId: "",
    districtId: "",
    townId: "",
    place: "",
    school_official_id: "",
    school_name: "",
    school_address: "",
    school_pin_code: "",
    school_website: "",
    school_email: "",
    principal_name: "",
    principal_contact_no: "",
    principal_email: "",
    principal_photo: "",
    admin_name: "",
    admin_contact_no: "",
    admin_email: "",
    total_number_of_students: "",
    robotics_lab_location: "",
    Rob_lab_coordinator: "",
    rob_lab_coordntr_contact_no: "",
    rob_lab_coordntr_whatsapp_no: "",
    rob_lab_coordntr_email: "",
    school_logo: "",
    social_media_linkedin: "",
    social_media_instagram: "",
    social_media_facebook: "",
    status: 1,
  });
  const [error, setError] = useState("");
  const [errorVal, setErrorVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [principalPhoto, setPrincipalPhoto] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [principalPhotoPreview, setPrincipalPhotoPreview] = useState("");
  const [schoolLogoPreview, setSchoolLogoPreview] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success or error

  useEffect(() => {
    setError([]);
    fetchCountries();
    fetchSchoolCategories();
  }, []);

  useEffect(() => {
    if (formData.countryId) {
      fetchStates(formData.countryId);
    } else {
      setStates([]);
      setFormData((prev) => ({
        ...prev,
        stateId: "",
        districtId: "",
        townId: "",
      }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) {
      fetchDistricts(formData.stateId);
    } else {
      setDistricts([]);
      setFormData((prev) => ({ ...prev, districtId: "", townId: "" }));
    }
  }, [formData.stateId]);

  useEffect(() => {
    if (formData.districtId) {
      fetchTowns(formData.districtId);
    } else {
      setTowns([]);
      setFormData((prev) => ({ ...prev, townId: "" }));
    }
  }, [formData.districtId]);

  useEffect(() => {
    if (formData.school_category) {
      const filtered = schoolSubCategories.filter(
        (subcategory) =>
          subcategory.School_category_id === formData.school_category
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [formData.school_category, schoolSubCategories]);

  useEffect(() => {
    if (principalPhoto) {
      const objectUrl = URL.createObjectURL(principalPhoto);
      setPrincipalPhotoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [principalPhoto]);

  useEffect(() => {
    if (schoolLogo) {
      const objectUrl = URL.createObjectURL(schoolLogo);
      setSchoolLogoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [schoolLogo]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country?limit=300`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch countries:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/by-country/?limit=300`,
          {
            params: { countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch states:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/by-state?limit=300`,
          {
            params: { stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch districts:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchTowns = async (districtId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/by-district?limit=300`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { districtId },
          }
        );
        setTowns(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch towns:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchSchoolCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category/nopagination?limit=3000`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolCategories(response.data.data);
        fetchSchoolSubCategories(); // Fetch Subcategory here for initial population
      }
    } catch (err) {
      console.error(
        "Failed to fetch school categories:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchSchoolSubCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category?limit=3000`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolSubCategories(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch school Subcategory:",
        err.response?.data?.message || err.message
      );
    }
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (name === "principal_photo") {
        setPrincipalPhoto(file);
      } else if (name === "school_logo") {
        setSchoolLogo(file);
      }
    }
  };
  const handleCancel = () => {
    navigate("/partner/school_list", {
      state: { snackbarMessage: "school added successfully" },
    }); // Navigate to the desired route on cancel
  };
  const handleSubmit = async () => {
    setLoading(true);
    setError(""); // Reset error state before making the request
    setSnackbarMessage(""); // Clear any previous snackbar messages
    setSnackbarOpen(false); // Close the snackbar initially

    try {
      const token = getToken();
      if (!token) {
        alert("Please Login");
        return;
      }

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      if (principalPhoto) {
        formDataToSend.append("principal_photo", principalPhoto);
      }
      if (schoolLogo) {
        formDataToSend.append("school_logo", schoolLogo);
      }

      // Make the API request to add the school
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/school`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // On success, trigger success snackbar
      setSnackbarMessage("School added successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Redirect to the school list page after adding
      navigate("/partner/school_list", {
        state: { snackbarMessage: "School added successfully" },
      });
    } catch (err) {
      // Check if the error response contains validation errors
      if (err.response && err.response.data && err.response.data.errors) {
        const errors = err.response.data.errors;

        // Handle errors field by field and set them in the error state
        const formErrors = {};
        errors.forEach((error) => {
          formErrors[error.field] = error.message;
        });

        // Update the error state with the field-specific error messages
        setErrorVal(formErrors);

        // Trigger error snackbar with a generic error message
        setSnackbarMessage(
          "Failed to add school. Please check your inputs and try again."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        // If no specific validation errors, show a generic error message
        setSnackbarMessage(
          "An unexpected error occurred. Please try again later."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ width: "80%", marginLeft: "0px" }}>
      <Typography
        variant="h5"
        align="left"
        gutterBottom
        sx={{ backgroundColor: "#e4e1de", padding: "5px" }}
      >
        Add New School
      </Typography>
      {/* {errorVal && <Alert severity="error">{errorVal}</Alert>} */}
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={2}>
          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Address Information
            </Typography>
            <Grid container spacing={2}>
              {/* Country */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errorVal.countryId}>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="countryId"
                    value={formData.countryId}
                    onChange={handleChange}
                    label="Country"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.countryId && (
                    <span className="error-message">{errorVal.countryId}</span>
                  )}
                </FormControl>
              </Grid>
              {/* State */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errorVal.stateId}>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="stateId"
                    value={formData.stateId}
                    onChange={handleChange}
                    label="State"
                  >
                    {states.map((state) => (
                      <MenuItem key={state._id} value={state._id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.stateId && (
                    <span className="error-message">{errorVal.stateId}</span>
                  )}
                </FormControl>
              </Grid>
              {/* District */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errorVal.districtId}>
                  <InputLabel>District</InputLabel>
                  <Select
                    name="districtId"
                    value={formData.districtId}
                    onChange={handleChange}
                    label="District"
                  >
                    {districts.map((district) => (
                      <MenuItem key={district._id} value={district._id}>
                        {district.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.districtId && (
                    <span className="error-message">{errorVal.districtId}</span>
                  )}
                </FormControl>
              </Grid>
              {/* Town */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errorVal.townId}>
                  <InputLabel>Town</InputLabel>
                  <Select
                    name="townId"
                    value={formData.townId}
                    onChange={handleChange}
                    label="Town"
                  >
                    {towns.map((town) => (
                      <MenuItem key={town._id} value={town._id}>
                        {town.Town_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.townId && (
                    <span className="error-message">{errorVal.townId}</span>
                  )}
                </FormControl>
              </Grid>
              {/* Place */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Place"
                  name="place"
                  value={formData.place}
                  onChange={handleChange}
                  fullWidth
                  error={!!errorVal.place}
                  helperText={errorVal.place && errorVal.place}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* General Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              General Information
            </Typography>
            <Grid container spacing={2}>
              {/* School Code */}
              <Grid item xs={12} md={6}>
              <TextField
                label="School Short Name"
                name="school_code"
                value={formData.school_code}
                onChange={(e) => {
                  const value = e.target.value;

                  if (/^[A-Za-z]*$/.test(value) && value.length <= 5) {
                    handleChange(e);
                  }
                }}
                fullWidth
                error={!!errorVal.school_code}
                helperText={errorVal.school_code || (formData.school_code.length < 3 || formData.school_code.length > 5 ? "Length must be between 3 and 5 characters." : "")}
              />

              </Grid>
              {/* School Category */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errorVal.school_category}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="school_category"
                    value={formData.school_category}
                    onChange={handleChange}
                    label="Category"
                  >
                    {schoolCategories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.school_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.school_category && (
                    <span className="error-message">
                      {errorVal.school_category}
                    </span>
                  )}
                </FormControl>
              </Grid>
              {/* School Subcategory */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errorVal.school_sub_category}>
                  <InputLabel>Subcategory</InputLabel>
                  <Select
                    name="school_sub_category"
                    value={formData.school_sub_category}
                    onChange={handleChange}
                    label="Subcategory"
                  >
                    {filteredSubCategories.map((subCategory) => (
                      <MenuItem key={subCategory._id} value={subCategory._id}>
                        {subCategory.SubCategory_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.school_sub_category && (
                    <span className="error-message">
                      {errorVal.school_sub_category}
                    </span>
                  )}
                </FormControl>
              </Grid>
              {/* School Name */}
              <Grid item xs={12} md={6}>
              <TextField
                label="School Name"
                name="school_name"
                value={formData.school_name}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only alphabetic characters (A-Z, a-z)
                  if (/^[A-Za-z\s]*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                fullWidth
                error={!!errorVal.school_name}
                helperText={errorVal.school_name}
              />

              </Grid>
              {/* School Address */}
              <Grid item xs={12}>
                <TextField
                  label="School Address"
                  name="school_address"
                  value={formData.school_address}
                  onChange={handleChange}
                  fullWidth
                  error={!!errorVal.school_address}
                  helperText={errorVal.school_address && errorVal.school_address}
                />
              </Grid>
              {/* School Pin Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Pin Code"
                  name="school_pin_code"
                  value={formData.school_pin_code}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  error={!!errorVal.school_pin_code}
                  helperText={errorVal.school_pin_code && errorVal.school_pin_code}
                />
              </Grid>
              {/* School Website */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Website"
                  name="school_website"
                  value={formData.school_website}
                  onChange={handleChange}
                  fullWidth
                  error={!!errorVal.school_website}
                  helperText={errorVal.school_website && errorVal.school_website}
                />
              </Grid>
              {/* School Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Email"
                  name="school_email"
                  value={formData.school_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                  error={!!errorVal.school_email}
                  helperText={errorVal.school_email && errorVal.school_email}
                />
              </Grid>
              {/* Principal Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Name"
                  name="principal_name"
                  value={formData.principal_name}
                  onChange={(e) => {
                    const value = e.target.value;
  
                    // Allow only alphabetic characters (A-Z, a-z)
                    if (/^[A-Za-z\s]*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  fullWidth
                  error={!!errorVal.principal_name}
                  helperText={errorVal.principal_name && errorVal.principal_name}
                />
              </Grid>
              {/* Principal Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Contact No"
                  name="principal_contact_no"
                  value={formData.principal_contact_no}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  error={!!errorVal.principal_contact_no}
                  helperText={
                    errorVal.principal_contact_no && errorVal.principal_contact_no
                  }
                />
              </Grid>
              {/* Principal Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Email"
                  name="principal_email"
                  value={formData.principal_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                  error={!!errorVal.principal_email}
                  helperText={errorVal.principal_email && errorVal.principal_email}
                />
              </Grid>
              {/* Principal Photo */}
              <Grid item xs={12} md={6}>
                <Button variant="contained" component="label">
                  Upload Principal Photo
                  <input
                    type="file"
                    name="principal_photo"
                    accept="image/*"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                {principalPhotoPreview && (
                  <Box mt={2}>
                    <img
                      src={principalPhotoPreview}
                      alt="Principal"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  </Box>
                )}
                {errorVal.principal_photo && (
                  <span className="error-message">{errorVal.principal_photo}</span>
                )}
              </Grid>
              {/* Admin Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Name"
                  name="admin_name"
                  value={formData.admin_name}
                  onChange={(e) => {
                    const value = e.target.value;
  
                    // Allow only alphabetic characters (A-Z, a-z)
                    if (/^[A-Za-z\s]*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  fullWidth
                  error={!!errorVal.admin_name}
                  helperText={errorVal.admin_name && errorVal.admin_name}
                />
              </Grid>
              {/* Admin Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Contact No"
                  name="admin_contact_no"
                  value={formData.admin_contact_no}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  error={!!errorVal.admin_contact_no}
                  helperText={errorVal.admin_contact_no && errorVal.admin_contact_no}
                />
              </Grid>
              {/* Admin Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Email"
                  name="admin_email"
                  value={formData.admin_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                  error={!!errorVal.admin_email}
                  helperText={errorVal.admin_email && errorVal.admin_email}
                />
              </Grid>
              {/* Total Number of Students */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Total Number of Students"
                  name="total_number_of_students"
                  value={formData.total_number_of_students}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  error={!!errorVal.total_number_of_students}
                  helperText={
                    errorVal.total_number_of_students &&
                    errorVal.total_number_of_students
                  }
                />
              </Grid>
              {/* School Logo */}
              <Grid item xs={12} md={6}>
                <Button variant="contained" component="label">
                  Upload School Logo
                  <input
                    type="file"
                    name="school_logo"
                    accept="image/*"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                {schoolLogoPreview && (
                  <Box mt={2}>
                    <img
                      src={schoolLogoPreview}
                      alt="School Logo"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  </Box>
                )}
                {errorVal.school_logo && (
                  <span className="error-message">{errorVal.school_logo}</span>
                )}
              </Grid>
              {/* Social Media Links */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media LinkedIn"
                  name="social_media_linkedin"
                  value={formData.social_media_linkedin}
                  onChange={handleChange}
                  fullWidth
                  error={!!errorVal.social_media_linkedin}
                  helperText={
                    errorVal.social_media_linkedin && errorVal.social_media_linkedin
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media Instagram"
                  name="social_media_instagram"
                  value={formData.social_media_instagram}
                  onChange={handleChange}
                  fullWidth
                  error={!!errorVal.social_media_instagram}
                  helperText={
                    errorVal.social_media_instagram && errorVal.social_media_instagram
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media Facebook"
                  name="social_media_facebook"
                  value={formData.social_media_facebook}
                  onChange={handleChange}
                  fullWidth
                  error={!!errorVal.social_media_facebook}
                  helperText={
                    errorVal.social_media_facebook && errorVal.social_media_facebook
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={loading}
            sx={{ mt: 2, mx: 2, backgroundColor: "#e0e0e0" }}
          >
            {loading ? <CircularProgress size={24} /> : "Add School"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel} // You can define this function to handle the cancel action
            sx={{ mt: 2, mx: 2, backgroundColor: "#e0e0e0" }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddSchoolMain;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, TextField, MenuItem, CircularProgress, Typography, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditCoursePage = () => {
  const { id } = useParams(); // Assuming the course ID is passed as a URL parameter
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);
  const [grades, setGrades] = useState([]);
  const [thumbnailImagePreview, setThumbnailImagePreview] = useState('');
  const [courseImagePreview, setCourseImagePreview] = useState('');
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [courseImage, setCourseImage] = useState(null);
  const [course, setCourse] = useState({
    courses_name: "",
    course_details: "",
    no_of_chapters: 0,
    status: 1,
    course_category_id: "",
    course_subcategory_id: "",
    department_id: "",
    course_image: '',
    thumbnail_image: '',
    grade_id: null // Make sure to handle this as optional
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();
      if (token) {
        // Fetching the course details
        const courseRes = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCourse(courseRes.data.data);

        // Fetching other data
        const [
          subcategoriesRes,
          departmentsRes,
          courseCategoriesRes,
          gradesRes
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub?limit=3000`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/department?limit=3000`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category?limit=3000`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/grade`)
        ]);

        setSubcategories(subcategoriesRes.data.data);
        setDepartments(departmentsRes.data.data);
        setCourseCategories(courseCategoriesRes.data.data);
        setGrades(gradesRes.data.data);
      } }catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (course.course_category_id) {
      const filtered = subcategories.filter(subcategory =>
        subcategory.course_category_id === course.course_category_id
      );
      setFilteredSubcategories(filtered);
    }
  }, [course.course_category_id, subcategories]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCourse((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (name === 'course_thumbnail') {
        setThumbnailImage(file);
        setThumbnailImagePreview(URL.createObjectURL(file)); // Update preview for thumbnail
      } else if (name === 'course_image') {
        setCourseImage(file);
        setCourseImagePreview(URL.createObjectURL(file)); // Update preview for course image
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!course.courses_name || !course.course_details || !course.course_category_id || !course.course_subcategory_id || !course.department_id) {
      alert('Please fill out all required fields.');
      return;
    }

    setIsSaving(true);
    const formData = new FormData();
    formData.append('courses_name', course.courses_name);
    formData.append('course_details', course.course_details);
    formData.append('no_of_chapters', course.no_of_chapters);
    formData.append('status', course.status);
    formData.append('course_category_id', course.course_category_id);
    formData.append('course_subcategory_id', course.course_subcategory_id);
    formData.append('department_id', course.department_id);
    formData.append('grade_id', course.grade_id);

    if (thumbnailImage) {
      formData.append('course_thumbnail', thumbnailImage);
    }

    if (courseImage) {
      formData.append('course_image', courseImage);
    }

    try {
      const token = getToken();
      if (token) {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
       
      });
      navigate("/partner/courses_list");
    } }catch (error) {
      console.error("Error saving course:", error);
      alert('Error saving course. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box padding={2}>
      <Typography variant="h4" >
        Edit Course
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSave}>
          <TextField
            label="Course Category"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course_category_id"
            select
            value={course.course_category_id}
            onChange={handleInputChange}
            required
          >
            {courseCategories.map((courseCategory) => (
              <MenuItem key={courseCategory._id} value={courseCategory._id}>
                {courseCategory.courses_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Subcategory"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course_subcategory_id"
            select
            value={course.course_subcategory_id}
            onChange={handleInputChange}
            required
          >
            {filteredSubcategories.map((subcategory) => (
              <MenuItem key={subcategory._id} value={subcategory._id}>
                {subcategory.courses_sub_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Department"
            variant="outlined"
            fullWidth
            margin="normal"
            name="department_id"
            select
            value={course.department_id}
            onChange={handleInputChange}
            required
          >
            {departments.map((department) => (
              <MenuItem key={department._id} value={department._id}>
                {department.department_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Grade"
            variant="outlined"
            fullWidth
            margin="normal"
            name="grade_id"
            select
            value={course.grade_id || ''} // Handle null value
            onChange={handleInputChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {grades.map((grade) => (
              <MenuItem key={grade._id} value={grade._id}>
                {grade.gradename}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Course Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="courses_name"
            value={course.courses_name}
            onChange={handleInputChange}
            required
          />
          <TextField
            label="Course Details"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course_details"
            value={course.course_details}
            onChange={handleInputChange}
            required
          />
          <TextField
            label="Number of Chapters"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            name="no_of_chapters"
            value={course.no_of_chapters}
            onChange={handleInputChange}
          />
       
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Course Thumbnail Image
              </Typography>
              <Box>
                {thumbnailImagePreview ? (
                  <img
                    src={thumbnailImagePreview}
                    alt="thumbnail preview"
                    style={{ width: 100, height: 100, marginRight: 10, objectFit: 'cover' }}
                  />
                ) : course.course_thumbnail ? (
                  <img
                    src={course.course_thumbnail}
                    alt="thumbnail Photo"
                    style={{ width: 100, height: 100, marginRight: 10, objectFit: 'cover' }}
                  />
                ) : (
                  'No Photo'
                )}
                <Button variant="contained" component="label">
                  {thumbnailImage ? 'Change thumbnail Photo' : 'Upload thumbnail Photo'}
                  <input
                    type="file"
                    name="course_thumbnail"
                    accept="image/*"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Course Image
              </Typography>
              <Box>
                {courseImagePreview ? (
                  <img
                    src={courseImagePreview}
                    alt="course preview"
                    style={{ width: 100, height: 100, marginRight: 10, objectFit: 'cover' }}
                  />
                ) : course.course_image ? (
                  <img
                    src={course.course_image}
                    alt="course Photo"
                    style={{ width: 100, height: 100, marginRight: 10, objectFit: 'cover' }}
                  />
                ) : (
                  'No Photo'
                )}
                <Button variant="contained" component="label">
                  {courseImage ? 'Change Course Photo' : 'Upload Course Photo'}
                  <input
                    type="file"
                    name="course_image"
                    accept="image/*"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
       
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            sx={{ mt: 2 }}
          >
            {isSaving ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EditCoursePage;

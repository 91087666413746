import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  Typography,
  MenuItem,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AddCourseSub from "./AddCourseSub";
import EditCourseSub from "./EditCourseSub";
import { getToken } from "../../../utils/AuthUtils";

function CoursesSubList() {
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryIdForEdit, setSelectedCategoryIdForEdit] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        // Fetching subcategories
        const subCategoriesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub`,
          {
            Authorization: `Bearer ${token}`,
            params: {
              page: page + 1,
              limit: rowsPerPage,
              search: searchTerm,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(subCategoriesResponse.data.data);
        setTotalItems(subCategoriesResponse.data.pagination.totalItems);

        // Fetching all categories
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAllCategories(categoriesResponse.data.data);
      }
    } catch (err) {
      setError("Error fetching categories");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [page, rowsPerPage, searchTerm]);

  const handleNavigate = () => {
    navigate("/add_partner");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (categoryId) => {
    setSelectedCategoryIdForEdit(categoryId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCategoryIdForEdit(null);
  };

  const handleDeleteCategory = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Category deleted successfully");
        setOpenSnackbar(true);
        fetchCategories(); // Refresh the list after deleting
      }
    } catch (err) {
      setError("Failed to delete category");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleAddPartner = () => {
    setSnackbarMessage("subcategory  added successfully");
    setOpenSnackbar(true);
    handleCloseAddModal();
    fetchCategories(); // Refresh the list after adding
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedCategory(response.data.data);
        setSelectedCategoryIdForEdit(id);
        // setSelectedCategoryId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setError("Error fetching category details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategoryId(null);
    setSelectedCategory(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };


  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateCourseStatus(id, newStatus); // Update status in the backend
  };

  const updateCourseStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await fetch(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/${id}/status_course_sub_category`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ status }),
          }
        );
        setSnackbarMessage("subcategory status updated successfully");
        setOpenSnackbar(true);
        fetchCategories(); // Refresh the list after status change
      }
    } catch (err) {
      setError("Failed to update Course status");
      setOpenSnackbar(true);
    }
  };

  const getCategoryName = (categoryId) => {
    const category = allCategories.find((cat) => cat._id === categoryId);
    return category ? category.courses_name : "Unknown";
  };
  const filteredCategories = categories
    .filter(
      (subCategory) =>
        subCategory.courses_sub_category_name &&
        subCategory.courses_sub_category_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .filter(
      (subCategory) =>
        !selectedCategoryId ||
        subCategory.course_category_id === selectedCategoryId
    );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4"> Course Subcategory</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <Autocomplete
          options={allCategories.sort((a, b) =>
            a.courses_name.localeCompare(b.courses_name, "en", {
              sensitivity: "base",
            })
          )}
          getOptionLabel={(option) => option.courses_name || ""}
          value={allCategories.find(cat => cat._id === selectedCategoryId) || null}
          onChange={(event, newValue) => {
            setSelectedCategoryId(newValue ? newValue._id : null);
            setPage(0);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Category"
              variant="outlined"
              size="small"
            />
          )}
          sx={{ width: "300px", marginRight: "10px" }}
          clearOnBlur={false}
          disableClearable={false}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Create
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Subcategory </TableCell>
              <TableCell>Category </TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((category, index) => (
                <TableRow key={category._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{category.courses_sub_category_name}</TableCell>
                  <TableCell>
                    {getCategoryName(category.course_category_id)}
                  </TableCell>
                  <TableCell>{new Date(category?.createdAt).toLocaleDateString()} </TableCell>
                  <TableCell sx={{ textAlign: "center !important" }}>
                    <Switch
                      checked={category.status}
                      onChange={() =>
                        handleStatusToggle(category._id, category.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="row"
                        justifyContent="center"
                      alignItems="center" // Optional, aligns buttons horizontally in the center
                    >
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditModal(category._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(category._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>{" "}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Subcategory found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems > 100 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddCourseSub
        open={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddPartner}
      />
      {selectedCategoryIdForEdit && (
        <EditCourseSub
          open={openEditModal}
          onClose={handleCloseEditModal}
          categoryId={selectedCategoryIdForEdit}
          category={selectedCategory}
          onUpdate={() => {
            setSnackbarMessage("subcategory  updated successfully");
            setOpenSnackbar(true);
            handleCloseEditModal();
            fetchCategories(); // Refresh the list after updating
          }}
        />
      )}
      {selectedCategoryIdForEdit && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this category?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteCategory(selectedCategoryIdForEdit)}
              color="secondary"
              sx={{ backgroundColor: "#e0e0e0" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CoursesSubList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Container,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
// import { Select } from "antd";
import SearchIcon from "@mui/icons-material/Search";

const AssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [units, setUnits] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  // state defining for filter
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [courseCategories, setCourseCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [selectedCourses, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);

  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchCourseSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssessments(subcategoryResponse1.data.data);
        console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses/getcourseby/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCourses(subcategoryResponse4.data.data);
        console.log("get courses :", subcategoryResponse4.data.data);

        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssessments(subcategoryResponse2.data.data);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }

      if (selectedCourses) {
        const subcategoryResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${selectedCategory}/${selectedSubCategory}/${selectedCourses}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssessments(subcategoryResponse9.data.data);
        console.log(
          "filtered units with course:",
          subcategoryResponse9.data.data
        );
      }
    } catch (err) {
      setError("Failed to fetch chapters");
    }
  };

  const fetchAssessments = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: currentPage,
              limit: itemsPerPage,
              category,
              subcategory,
              searchTerm,
              ...location.state, // Spread additional location state if needed
            },
          }
        );
        setAssessments(response.data.data || []);
        setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching assessments");
    } finally {
      setIsLoading(false);
    }
  };  

  useEffect(() => {
    fetchAssessments();
    fetchCourseCategories();
    fetchCourseSubCategories();
    // fetchUnits();
  }, [
    category,
    subcategory,
    searchTerm,
    selectedCategory,
    selectedSubCategory,
    selectedCourses,
    searchTerm,
    location.state,
  ]);

  const handleAddAssessment = () => navigate("/assessment/add");
  const handleEditAssessment = (id) =>
    navigate(`/partner/assessment/edit/${id}`);
  const handleViewQuestions = (id) =>
    navigate(`/partner/questions/assessment/${id}`);

  const handleDeleteConfirmation = (assessment) => {
    setAssessmentToDelete(assessment);
    setOpenDialog(true);
  };

  const handleDeleteAssessment = async () => {
    setIsDeleting(true);
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/assessment/delete/${assessmentToDelete._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOpenDialog(false);
        fetchAssessments();
        setSnackbarMessage("Assessment deleted successfully");
        setSnackbarType("success");
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage("Error deleting assessment");
      setSnackbarType("error");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedSubCategory("");
    setSelectedCourse("");
    setCurrentPage(0);
  };

  const handleSubCategoryChange = (subCatId) => {
    setSelectedSubCategory(subCatId);
    setSelectedCourse("");
    setCurrentPage(0);
  };

  const handleCourseChange = (courseId) => {
    setSelectedCourse(courseId);
    setCurrentPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // const getUnitName = (unitId) => {
  //   const unit = units.find((u) => u._id === unitId); // Match unit by ID
  //   return unit ? unit?.unit_name : "Unknown Unit"; // Return unit name or fallback
  // };
  const getUnitName = (unit) => {
    if (unit) {
      return unit.unit_name || "Unknown Unit"; // Use the unit's name or fallback
    }
    return "No Unit"; // Handle the case where unit is null
  };
  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" >
          Unit Assessment List
        </Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(event) => handleSearch(event)} // Use event to get the value
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          {/* Categories Filter */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  courseCategories.find(
                    (category) => category._id === selectedCategory
                  ) || null
                }
                onChange={(event, newValue) =>
                  handleCategoryChange(newValue ? newValue._id : "")
                }
                options={courseCategories}
                getOptionLabel={(option) => option.courses_name}
                isOptionEqualToValue={(option, value) => option._id === value}
                renderInput={(params) => (
                  <TextField {...params} label="Course Category" size="small" />
                )}
                sortComparator={(a, b) =>
                  a.courses_name.localeCompare(b.courses_name)
                } // Sort categories A-Z
                noOptionsText="No categories available"
                // disableClearable
              />
            </FormControl>
          </Grid>

          {/* Subcategory Dropdown */}
          {selectedCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courseSubCategories.find(
                      (subCategory) => subCategory._id === selectedSubCategory
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    handleSubCategoryChange(newValue ? newValue._id : "")
                  }
                  options={courseSubCategories}
                  getOptionLabel={(option) => option.courses_sub_category_name}
                  isOptionEqualToValue={(option, value) => option._id === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Subcategory"
                      size="small"
                    />
                  )}
                  sortComparator={(a, b) =>
                    a.courses_sub_category_name.localeCompare(
                      b.courses_sub_category_name
                    )
                  } // Sort subcategories A-Z
                  noOptionsText="No subcategories available"
                  // disableClearable
                />
              </FormControl>
            </Grid>
          )}
          {/* Courses Dropdown */}
          {selectedSubCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courses.find((course) => course._id === selectedCourses) ||
                    null
                  }
                  onChange={(event, newValue) =>
                    handleCourseChange(newValue ? newValue._id : "")
                  }
                  options={courses}
                  getOptionLabel={(option) => option.courses_name}
                  isOptionEqualToValue={(option, value) => option._id === value}
                  renderInput={(params) => (
                    <TextField {...params} label="Course" size="small" />
                  )}
                  sortComparator={(a, b) =>
                    a.courses_name.localeCompare(b.courses_name)
                  } // Sort courses A-Z
                  noOptionsText="No courses available"
                  // disableClearable
                />
              </FormControl>
            </Grid>
          )}
          {/* Create Button */}
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddAssessment}
                sx={{ mx: 2, backgroundColor: "#e0e0e0" }}
              >
                Create
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="left"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="left" alignItems="left" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "left" }}> No.</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    Assessment Title
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>Unit</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    Course Category
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    Course Subcategory
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>Course</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>Chapter</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>Total Score</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    Expire Time (s)
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>Created Date</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>Actions</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    View Questions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessments.length > 0 ? (
                  assessments
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((assessment, index) => (
                      <TableRow key={assessment._id}>
                        <TableCell sx={{ textAlign: "left" }}>
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {assessment.assessment_title}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {assessment.units?.unit_name
                            ? assessment.units.unit_name
                            : "N/A"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {assessment.course_category?.courses_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {
                            assessment.course_sub_category
                              ?.courses_sub_category_name
                          }
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {assessment.course?.courses_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {assessment.chapterData?.chapter_name}
                        </TableCell>

                        <TableCell sx={{ textAlign: "left" }}>
                          {assessment.total_score}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {assessment.expire_time}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                        {new Date(assessment.end_date).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() => handleEditAssessment(assessment._id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteConfirmation(assessment)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="default"
                            onClick={() => handleViewQuestions(assessment._id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No assessments found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {totalItems > 100 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
              showFirstButton
              showLastButton
            />
          )}
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the assessment "
            {assessmentToDelete?.assessment_title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAssessment}
            color="primary"
            variant="contained"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssessmentList;

import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const AddNewsletterPage = () => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    publish_date: "",
    author: "",
    file_url: null, // Store the file directly in the formData state
    status: 1, // Default to active
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file" && files.length > 0) {
      setFormData({
        ...formData,
        file_url: files[0], // Store the uploaded file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create a FormData object to handle the file upload
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/newsletter`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        navigate("/newsletter_list", {
          state: { snackbarMessage: "Newsletter added successfully" },
        });
      }
    } catch (err) {
      setError("Error adding newsletter");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/newsletter_list");
  };

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography Typography variant="h4">
          Add Newsletter
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          name="title"
          label="Title"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.title}
          onChange={handleChange}
          required
        />
        <TextField
          name="content"
          label="Content"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={formData.content}
          onChange={handleChange}
          required
        />
        <TextField
          name="publish_date"
          label="Publish Date"
          variant="outlined"
          fullWidth
          margin="normal"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formData.publish_date}
          onChange={handleChange}
        />
        <TextField
          name="author"
          label="Author"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.author}
          onChange={handleChange}
        />
        <input
          name="file_url"
          type="file"
          accept="application/pdf"
          onChange={handleChange}
          required
        />
        {/* <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            value={formData.status}
            onChange={handleChange}
            label="Status"
          >
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl> */}
        <Button
          type="button"
          color="secondary"
          sx={{ mt: 2, mr: 2 }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, backgroundColor: "#e0e0e0" }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Add "}
        </Button>

        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Container>
  );
};

export default AddNewsletterPage;

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TablePagination,
  MenuItem,
  Grid,
  FormControl,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const ListingPage = () => {
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [partners, setPartners] = useState({});
  const [partnerIds, setPartnerIds] = useState({});
  const [courses, setCourses] = useState({});
  const [categories, setCategories] = useState({});
  const [subCategories, setSubCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(""); // Added category filter
  const [subCategoryFilter, setSubCategoryFilter] = useState(""); // Added sub-category filter
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100); // Set initial rows per page to 50
  const [totalItems, setTotalItems] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [filterSubcat, setFilterSubcat] = useState({});
  const [itemsPerPage, setItemsPerPage] = useState(100);

  const navigate = useNavigate();
  const token = getToken();

  // Fetch Subcategories based on the selected category
  const fetchCourseSubCategories = async () => {
    if (!categoryFilter) {
      return; // Return early if no category is selected
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${categoryFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the response contains subcategories with a 'courses_sub_category_name' field
      setFilterSubcat(
        response.data.data.reduce((acc, subcategory) => {
          acc[subcategory._id] = subcategory.courses_sub_category_name;
          return acc;
        }, {})
      );
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };

  useEffect(() => {
    fetchCourseSubCategories();
  }, [categoryFilter]);

  useEffect(() => {
    // Check for the success message in sessionStorage
    const message = sessionStorage.getItem("assignSuccessMessage");
    if (message) {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
      sessionStorage.removeItem("assignSuccessMessage"); // Remove the message after showing
    }
  }, [page, rowsPerPage, searchTerm]);

  const fetchAssignedCourses = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
              categories: categoryFilter,
              subcategories: subCategoryFilter,
              searchTerm,
            },
          }
        );
        setAssignedCourses(data.data);
        setTotalItems(data.pagination.totalItems);
      }
    } catch (error) {
      setError("Failed to fetch assigned courses.");
      console.error("Error fetching assigned courses:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch assigned courses
  useEffect(() => {
    fetchAssignedCourses();
  }, [page, rowsPerPage, categoryFilter, subCategoryFilter, searchTerm]);

  // Fetch partners, courses, categories, and Subcategory based on IDs
  useEffect(() => {
    const fetchPartnersCoursesCategories = async () => {
      if (assignedCourses.length === 0) return;

      try {
        const token = getToken();
        if (token) {
          const [categoriesResponse, subCategoriesResponse] = await Promise.all(
            [
              axios.get(
                `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              ),
              axios.get(
                `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/nopagenation`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              ),
            ]
          );

          const categoriesData = categoriesResponse.data.data.reduce(
            (acc, category) => {
              acc[category._id] = category.courses_name;
              return acc;
            },
            {}
          );

          const subCategoriesData = subCategoriesResponse.data.data.reduce(
            (acc, subCategory) => {
              acc[subCategory._id] = subCategory.courses_sub_category_name;
              return acc;
            },
            {}
          );

          setCategories(categoriesData);
          setSubCategories(subCategoriesData);
        }
      } catch (error) {
        setError(
          "Failed to fetch partners, courses, categories, and Subcategory."
        );
        console.error(
          "Error fetching partners, courses, categories, and Subcategory:",
          error
        );
      }
    };

    fetchPartnersCoursesCategories();
  }, [assignedCourses, token]);

  const handleSnackbarClose = () => {
    setError(null);
  };

  const handleAddCourse = () => {
    navigate("/assign_courses_to_partner");
  };

  const handleEditCourse = (id) => {
    navigate(`/assign_courses_to_partner/edit/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    console.log("Changing page to:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 100));
    setRowsPerPage(parseInt(event.target.value, 100));
    const newRowsPerPage = parseInt(event.target.value, 100);
    console.log("Changing rows per page to:", newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const openDeleteDialog = (_id) => {
    setCourseToDelete(_id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/delete/${courseToDelete}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          closeDeleteDialog();
          fetchAssignedCourses(); // Call to fetch assigned courses again to refresh the list

          // Show success Snackbar message
          setSnackbarMessage(
            "Course assignment to partner deleted successfully."
          );
          setSnackbarType("success");
          setSnackbarOpen(true);
        }
      } catch (err) {
        setError("Failed to delete the course.");
        console.error("Error deleting course:", err);
      }
    }
  };

  // Filter and paginate assigned courses
  const filteredCourses = assignedCourses.filter((assignment) => {
    const courseName = courses[assignment.course_id] || "";
    const partnerName = partners[assignment.partner_id] || "";
    const courseCategory = categories[assignment.course_category_id] || "";
    const partnerId = partnerIds[assignment.partner_id] || "";
    const courseSubCategory =
      subCategories[assignment.course_sub_category_id] || "";

    const isCategoryMatch = categoryFilter
      ? assignment.course_category_id === categoryFilter
      : true;
    const isSubCategoryMatch = subCategoryFilter
      ? assignment.course_sub_category_id === subCategoryFilter
      : true;

    return (
      (courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        partnerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        courseCategory.toLowerCase().includes(searchTerm.toLowerCase()) ||
        partnerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        courseSubCategory.toLowerCase().includes(searchTerm.toLowerCase())) &&
      isCategoryMatch &&
      isSubCategoryMatch
    );
  });

  const paginatedCourses = filteredCourses.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.log("Paginated courses:", paginatedCourses);

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">Assigned Courses to Partner List</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Snackbar
              open={error !== null}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}>
              <Alert
                onClose={handleSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}>
                {error}
              </Alert>
            </Snackbar>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
            sx={{ paddingLeft: "0", paddingRight: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={
                      categories[categoryFilter]
                        ? {
                            id: categoryFilter,
                            name: categories[categoryFilter],
                          }
                        : null
                    } // Find the selected category
                    onChange={(e, newValue) => {
                      setCategoryFilter(newValue ? newValue.id : ""); // Update categoryFilter with the selected category's ID
                      setSubCategoryFilter(""); // Reset subcategory filter when category changes
                    }}
                    options={Object.entries(categories).map(([id, name]) => ({
                      id,
                      name,
                    }))} // Convert categories object to array of { id, name }
                    getOptionLabel={(option) => option.name} // Display the category name in the dropdown
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    } // Match by category ID
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Course Category"
                        size="small"
                        variant="outlined"
                      />
                    )}
                    disableClearable={false} // Allow clearing the selection
                  />
                </FormControl>
              </Grid>
              {categoryFilter && (
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        filterSubcat[subCategoryFilter]
                          ? {
                              id: subCategoryFilter,
                              name: filterSubcat[subCategoryFilter],
                            }
                          : null
                      } // Find the selected sub-category
                      onChange={(e, newValue) => {
                        setSubCategoryFilter(newValue ? newValue.id : "");
                      }}
                      options={Object.entries(filterSubcat)
                        .sort(([idA, nameA], [idB, nameB]) =>
                          nameA.localeCompare(nameB)
                        ) // Sort sub-categories by name
                        .map(([id, name]) => ({ id, name }))} // Convert filterSubcat object to array of { id, name }
                      getOptionLabel={(option) => option.name} // Display the sub-category name in the dropdown
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      } // Match by sub-category ID
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Course Sub-category"
                          size="small"
                          variant="outlined"
                        />
                      )}
                      disableClearable={false} // Allow clearing the selection
                    />
                  </FormControl>
                </Grid>
              )}

              {/* Create Button */}
              <Grid
                item
                xs={12}
                md={3}
                display="flex"
                justifyContent="flex-end"
                sx={{ order: 2, marginLeft: "auto" }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddCourse}
                  sx={{
                    backgroundColor: "#e0e0e0",
                  }}>
                  Create
                </Button>
              </Grid>
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> No.</TableCell>
                  <TableCell>PartnerID</TableCell>
                  <TableCell>Partner Name</TableCell>
                  <TableCell>Course Category</TableCell>
                  <TableCell>Course Subcategory</TableCell>
                  <TableCell>Assigned Course Name</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assignedCourses.length > 0 ? (
                  assignedCourses.map((assignment, index) => (
                    <TableRow key={assignment._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>
                        {assignment?.partnerData?.partner_id}
                      </TableCell>
                      <TableCell>
                        {assignment?.partnerData?.partner_name}
                      </TableCell>
                      <TableCell>
                        {assignment?.course_category?.courses_name}
                      </TableCell>
                      <TableCell>
                        {
                          assignment?.course_sub_category
                            ?.courses_sub_category_name
                        }
                      </TableCell>
                      <TableCell>{assignment?.courses?.courses_name}</TableCell>
                      <TableCell>
                        {new Date(assignment?.createdAt).toLocaleDateString()}{" "}
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center" // Optional, aligns buttons horizontally in the center
                        >
                          <IconButton
                            color="primary"
                            onClick={() => handleEditCourse(assignment._id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => openDeleteDialog(assignment._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {totalItems > 100 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={totalItems}
              rowsPerPage={itemsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this course?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog}>Cancel</Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDeleteCourse}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ListingPage;

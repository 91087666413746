import React from 'react';
import { Navigate } from 'react-router-dom';
import { verifyToken, getRole } from '../../utils/AuthUtils';

function ProtectedCompanyRoute({ children }) {
    console.log('Verifying token...');
    
    if (!verifyToken()) {
        console.log('Token is invalid');
        return <Navigate to="/" replace />;
    }

    const allowedRoles = ['company', 'super_admin'];
    const userRole = getRole();

    console.log('Allowed roles:', allowedRoles);
    console.log('Detected role:', userRole);

    if (!allowedRoles.includes(userRole)) {
        console.log('Roles do not match');
        return <Navigate to="/unauthorized" replace />;
    }

    console.log('Access granted');
    return children;
}

export default ProtectedCompanyRoute;

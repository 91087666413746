import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";

function EditPlace({ isOpen, onClose, place, onEditPlace }) {
  const [placeName, setPlaceName] = useState(place ? place.place_name : "");
  const [country, setCountry] = useState(place ? place.country : "");
  const [state, setState] = useState(place ? place.state : "");
  const [district, setDistrict] = useState(place ? place.district : "");
  const [town, setTown] = useState(place ? place.town : "");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [selectedTown, setSelectedTown] = useState([]);

  useEffect(() => {
    if (place) {
      setPlaceName(place.place_name);
      setCountry(place.country);
      setState(place.state);
      setDistrict(place.district);
      setTown(place.town);
    }
  }, [place]);

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (country) {
      fetchStates(country);
    } else {
      setSelectedState([]);
      setState("");
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      fetchDistricts(state);
    } else {
      setSelectedDistrict([]);
      setDistrict("");
    }
  }, [state]);

  useEffect(() => {
    if (district) {
      fetchTownsByDistrict(district);
    } else {
      setSelectedTown([]);
      setTown("");
    }
  }, [district]);
  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedCountry(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch countries:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/by-country`,
          {
            params: { countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedState(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch states:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/by-state`,
          {
            params: { stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedDistrict(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch districts:",
        err.response?.data?.message || err.message
      );
    }
  };
  const fetchTownsByDistrict = async (districtId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/by-district`,
          {
            params: { districtId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedTown(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch towns:",
        err.response?.data?.message || err.message
      );
    }
  };

  const handleEditPlace = async () => {
    try {
      const token = getToken();
      if (token) {
        const updatedPlace = {
          place_name: placeName,
          country,
          state,
          district,
          town,
        };

        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/place/${place._id}`,
          updatedPlace,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        onEditPlace(); // Callback to refresh the place list
        onClose();
      }
    } catch (error) {
      console.error("Error updating place", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Place</DialogTitle>
      <Box padding={2}>
        <TextField
          label="Country"
          variant="outlined"
          select
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
            setState("");
            setDistrict("");
            setTown("");
          }}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}>
          <option value="">Select Country</option>
          {Array.isArray(selectedCountry) &&
            selectedCountry.map((country) => (
              <option key={country._id} value={country._id}>
                {country.country_name}
              </option>
            ))}
        </TextField>
        <TextField
          label="State"
          variant="outlined"
          select
          value={state}
          onChange={(e) => {
            setState(e.target.value);
            setDistrict("");
            setTown("");
          }}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}>
          <option value="">Select State</option>
          {Array.isArray(selectedState) &&
            selectedState.map((state) => (
              <option key={state._id} value={state._id}>
                {state.state_name}
              </option>
            ))}
        </TextField>
        <TextField
          label="District"
          variant="outlined"
          select
          value={district}
          onChange={(e) => {
            setDistrict(e.target.value);
            setTown("");
          }}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}>
          <option value="">Select District</option>
          {Array.isArray(selectedDistrict) &&
            selectedDistrict.map((district) => (
              <option key={district._id} value={district._id}>
                {district.district_name}
              </option>
            ))}
        </TextField>
        <TextField
          label="Town"
          variant="outlined"
          select
          value={town}
          onChange={(e) => setTown(e.target.value)}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}>
          <option value="">Select Town</option>
          {Array.isArray(selectedTown) &&
            selectedTown.map((town) => (
              <option key={town._id} value={town._id}>
                {town.Town_name}
              </option>
            ))}
        </TextField>
        <TextField
          label="Place Name"
          variant="outlined"
          value={placeName}
          onChange={(e) => setPlaceName(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEditPlace} color="primary" variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPlace;

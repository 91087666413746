import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";

const AddState = ({ isOpen, onClose, onAdd }) => {
  const [countries, setCountries] = useState([]);
  const [stateName, setStateName] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [errors, setErrors] = useState({
    stateName: "",
    stateCode: "",
    selectedCountry: "",
  });

  const resetForm = () => {
    setStateName("");
    setStateCode("");
    setSelectedCountry("");
    setErrors({
      stateName: "",
      stateCode: "",
      selectedCountry: "",
    });
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch countries");
    }
  };

  const validateForm = () => {
    const newErrors = {
      stateName: stateName ? "" : "State name is required",
      stateCode: stateCode ? "" : "State code is required",
      selectedCountry: selectedCountry ? "" : "Country is required",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error); // If no errors, return true
  };

  const handleAddState = async () => {
    if (!validateForm()) {
      return; // If form is not valid, do not submit
    }
    try {
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/state`,
          {
            state_name: stateName,
            state_code: stateCode,
            country: selectedCountry,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        onAdd();
        onClose();
      }
    } catch (err) {
      console.error("Failed to add state");
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          error={Boolean(errors.selectedCountry)}
        >
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.country_name}
            </MenuItem>
          ))}
        </TextField>
        {errors.selectedCountry && (
          <FormHelperText error>{errors.selectedCountry}</FormHelperText>
        )}

        <TextField
          margin="normal"
          required
          fullWidth
          label="State"
          value={stateName}
          onChange={(e) => setStateName(e.target.value)}
          error={Boolean(errors.stateName)}
        />
        {errors.stateName && (
          <FormHelperText error>{errors.stateName}</FormHelperText>
        )}

        <TextField
          margin="normal"
          required
          fullWidth
          label="State Code"
          value={stateCode}
          onChange={(e) => setStateCode(e.target.value)}
          error={Boolean(errors.stateCode)}
        />
        {errors.stateCode && (
          <FormHelperText error>{errors.stateCode}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddState} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddState;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import { Container } from "@mui/system";

function EditSchool({ open, onClose, category, onUpdate }) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (category) {
      setName(category.school_name);
    }
  }, [category]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/school_category/${category._id}`,
          { school_name: name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Categories updated successfully");
        setOpenSnackbar(true);
        onUpdate(); // Callback to refresh the list
      }
    } catch (err) {
      console.error(err); // Log the error for debugging
      setSnackbarMessage("Failed to update category");
    } finally {
      setLoading(false);
      setOpenSnackbar(true); // Ensure this is reached
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          {error && (
            <Snackbar
              open={Boolean(error)}
              autoHideDuration={6000}
              onClose={() => setError(null)}
            >
              <Alert onClose={() => setError(null)} severity="error">
                {error}
              </Alert>
            </Snackbar>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="School Name"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            disabled={loading}
            variant="contained"
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={error ? "error" : "success"}
          onClose={handleSnackbarClose}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default EditSchool;

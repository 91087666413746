import React, { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCropper = ({ imageSrc }) => {
  const cropperRef = useRef(null); // Reference to the Cropper instance
  const [croppedImages, setCroppedImages] = useState({ web: "", mobile: "" });

  const getCroppedImage = (aspectRatio) => {
    const cropper = cropperRef.current.cropper;
    const canvas = cropper.getCroppedCanvas({
      width: aspectRatio === 16 / 9 ? 1600 : 750, // Width based on ratio (web/mobile)
      height: aspectRatio === 16 / 9 ? 900 : 1334, // Height based on ratio (web/mobile)
    });
    return canvas.toDataURL("image/jpeg");
  };

  const handleCrop = () => {
    // Generate two images with different aspect ratios from the same crop
    const webImage = getCroppedImage(16 / 9); // Web aspect ratio
    const mobileImage = getCroppedImage(9 / 16); // Mobile aspect ratio

    setCroppedImages({ web: webImage, mobile: mobileImage });
  };

  return (
    <div>
      <Cropper
        src={imageSrc}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={16 / 9}
        ref={cropperRef}
        viewMode={1}
        dragMode="move"
        guides={true}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        cropend={handleCrop}
      />

      <div style={{ marginTop: "20px" }}>
        <h6>Preview:</h6>
        {croppedImages.web && (
          <img
            src={croppedImages.web}
            alt="Web Crop"
            style={{ maxWidth: "50%" }}
          />
        )}
      </div>
    </div>
  );
};

export default ImageCropper;

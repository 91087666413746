import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

function EditSchoolSub({ open, onClose, subjectId, subject, onUpdate }) {
  const [categoryName, setCategoryName] = useState(
    subject?.SubCategory_name || ""
  );
  const [selectedSchool, setSelectedSchool] = useState(
    subject?.School_category_id || ""
  );
  const [loading, setLoading] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);

  // Fetch school categories for the dropdown
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const token = getToken();
        if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Filter out schools with status = 1
        const activeSchools = response.data.data.filter(
          (school) => school.status === 1
        );
        setSchoolOptions(activeSchools);
      }} catch (err) {
        console.error("Error fetching school categories", err);
      }
    };

    fetchSchools();
  }, []);

  // Handle input changes
  const handleCategoryChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleSchoolChange = (e) => {
    setSelectedSchool(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = getToken();
      if (token) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/sub_school_category/${subjectId}`,
        {
          SubCategory_name: categoryName,
          School_category_id: selectedSchool,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarMessage("Subject updated successfully");
      onUpdate(); // Notify parent to refresh data
      onClose(); // Close the modal
    }} catch (err) {
      setSnackbarMessage("Failed to update subject");
      setError("Failed to update subject");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  // Handle Snackbar close event
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth>
      <DialogTitle>Edit Subcategory</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel sx={{ backgroundColor: "#fff" }}> Category Name</InputLabel>
            <Select
              value={selectedSchool}
              onChange={handleSchoolChange}
              label="School Name"
            >
              {schoolOptions.length > 0 ? (
                schoolOptions.map((school) => (
                  <MenuItem key={school._id} value={school._id}>
                    {school.school_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No schools available</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Subcategory Name"
            value={categoryName}
            onChange={handleCategoryChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          {/* <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Update School Subcategory
              </Button>
            )}
          </Box> */}
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={error ? "error" : "success"}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" onClick={handleSubmit} variant="contained" color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSchoolSub;

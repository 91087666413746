import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditAssessment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [assessment, setAssessment] = useState({
    assessment_title: "",
    unit: { _id: "", unit_name: "" },
    total_score: "",
    expire_time: "",
    category_id: { _id: "", courses_name: "" },
    subcategory_id: { _id: "", courses_sub_category_name: "" },
    course_id: { _id: "", courses_name: "" },
    chapter_id: { _id: "", chapter_name: "" },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [units, setUnits] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(true);
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [chapters, setChapters] = useState([]);

  const fetchAssessment = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/getbyid/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const assessmentData = response.data.data;
        setAssessment({
          ...assessmentData,
          unit: assessmentData.unit || { _id: "", unit_name: "" }, // Handle missing unit data
        });
        setSelectedUnitId(assessmentData.unit ? assessmentData.unit._id : ""); // Set initial unit ID
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching assessment details");
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchAssessment();
  // }, [id]);

  const fetchUnits = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUnits(response.data.data);
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching units");
    } finally {
      setUnitsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessment();
    fetchUnits();
    fetchCourses();
    fetchCategories();
    fetchSubcategories();
    fetchChapters();
  }, [id]);

  const fetchCourses = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourses(response.data.data);
      }
    } catch (err) {
      setError("Error fetching courses");
    }
  };

  const fetchCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(response.data.data);
      }
    } catch (err) {
      setError("Error fetching categories");
    }
  };

  const fetchSubcategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubcategories(response.data.data);
      }
    } catch (err) {
      setError("Error fetching subcategories");
    }
  };

  const fetchChapters = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chapters`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setChapters(response.data.data);
      }
    } catch (err) {
      setError("Error fetching chapters");
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setAssessment((prev) => ({ ...prev, [name]: value }));
  // };

  // // Handle form input changes
  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "unit") {
  //     // When unit is changed, update the unit ID and the unit object in assessment
  //     const selectedUnit = units.find((unit) => unit._id === value);
  //     setSelectedUnitId(value); // Update selected unit ID
  //     setAssessment((prev) => ({
  //       ...prev,
  //       unit: selectedUnit || { _id: "", unit_name: "" }, // Update the unit object
  //     }));

  //   } else {
  //     // For other fields, just update normally
  //     setAssessment((prev) => ({ ...prev, [name]: value }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "unit") {
      const selectedUnit = units.find((unit) => unit._id === value);
      setAssessment((prev) => ({
        ...prev,
        unit: selectedUnit || { _id: "", unit_name: "" }, // Update unit object
      }));
      setSelectedUnitId(value); // Update unit ID for tracking
    } else if (name === "category_id") {
      const selectedCategory = categories.find(
        (category) => category._id === value
      );
      setAssessment((prev) => ({
        ...prev,
        category_id: selectedCategory || { _id: "", courses_name: "" }, // Update category object
      }));
    } else if (name === "subcategory_id") {
      const selectedSubcategory = subcategories.find(
        (subcategory) => subcategory._id === value
      );
      setAssessment((prev) => ({
        ...prev,
        subcategory_id: selectedSubcategory || {
          _id: "",
          courses_sub_category_name: "",
        }, // Update subcategory object
      }));
    } else if (name === "course_id") {
      const selectedCourse = courses.find((course) => course._id === value);
      setAssessment((prev) => ({
        ...prev,
        course_id: selectedCourse || { _id: "", courses_name: "" }, // Update course object
      }));
    } else if (name === "chapter_id") {
      const selectedChapter = chapters.find((chapter) => chapter._id === value);
      setAssessment((prev) => ({
        ...prev,
        chapter_id: selectedChapter || { _id: "", chapter_name: "" }, // Update chapter object
      }));
    } else {
      setAssessment((prev) => ({
        ...prev,
        [name]: value, // For other fields, update normally
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/assessment/${id}`,
          assessment,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Assessment updated successfully");
        setSnackbarType("success");
        setSnackbarOpen(true);
        navigate("/partner/assessment_list"); // Redirect to assessment list
      }
    } catch (err) {
      setSnackbarMessage("Error updating assessment");
      setSnackbarType("error");
      setSnackbarOpen(true);
    }
  };
  const handleCancel = () => {
    // Navigate to the assessment list page without saving
    navigate("/partner/assessment_list", {
      state: { snackbarMessage: "Changes discarded." },
    });
  };

  const filteredUnits = units.filter((unit) => unit._id === assessment.unit); // Filter units based on assessment's unit ID
  return (
    <Container maxWidth="100%" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">Edit Assessment</Typography>
      </Box>

      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="assessment_title"
                label="Assessment Title"
                variant="outlined"
                value={assessment.assessment_title}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            {/* Category Field */}
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel sx={{ backgroundColor: "#fff" }}>
                  Category
                </InputLabel>
                <Select
                  name="category_id"
                  value={assessment.category_id._id}
                  onChange={handleChange}>
                  {categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.courses_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Subcategory Field */}
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel sx={{ backgroundColor: "#fff" }}>
                  Subcategory
                </InputLabel>
                <Select
                  name="subcategory_id"
                  value={assessment.subcategory_id._id}
                  onChange={handleChange}>
                  {subcategories.map((subcategory) => (
                    <MenuItem key={subcategory._id} value={subcategory._id}>
                      {subcategory.courses_sub_category_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Course Field */}
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel sx={{ backgroundColor: "#fff" }}>Course</InputLabel>
                <Select
                  name="course_id"
                  value={assessment.course_id._id}
                  onChange={handleChange}>
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course._id}>
                      {course.courses_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Chapter Field */}
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel sx={{ backgroundColor: "#fff" }}>
                  Chapter
                </InputLabel>
                <Select
                  name="chapter_id"
                  value={assessment.chapter_id._id}
                  onChange={handleChange}>
                  {chapters.map((chapter) => (
                    <MenuItem key={chapter._id} value={chapter._id}>
                      {chapter.chapter_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {assessment.unit ? (
                <FormControl fullWidth required>
                  <InputLabel sx={{ backgroundColor: "#fff" }}>Unit</InputLabel>
                  <Select
                    name="unit"
                    value={assessment.unit._id}
                    onChange={handleChange}>
                    {units.map((unit) => (
                      <MenuItem key={unit._id} value={unit._id}>
                        {unit.unit_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <CircularProgress /> // Show a loading indicator if unit data is not available
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="total_score"
                label="Total Score"
                type="number"
                variant="outlined"
                value={assessment.total_score}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="expire_time"
                label="Expire Time (s)"
                type="number"
                variant="outlined"
                value={assessment.expire_time}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
              <Button
                onClick={handleCancel}
                variant=""
                color="secondary"
                sx={{
                  marginTop: 2,
                }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  marginTop: 2,
                  backgroundColor: "#e0e0e0",
                  marginLeft: 2,
                }}>
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditAssessment;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Container,
  TablePagination,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { getToken } from "../../../utils/AuthUtils";

const Parents = () => {
  const [parents, setParents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(100); // Items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of parents
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchParents = async () => {
      setIsLoading(true);
      setError(""); // Reset error state
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/parents/bypartner`,
          {
            params: {
              page: page + 1, // Backend expects 1-based page index
              limit: rowsPerPage,
              searchTerm: searchTerm,
              selectedCategory, // Passing selected school category
              selectedSubCategory, // Passing selected school
              selectedSchool,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
          }
        );
        setParents(response.data.data); // Access the 'data' field from the response
        setTotalItems(response.data.pagination.totalItems); // Set the total number of items for pagination
      } catch (err) {
        setError("Error fetching parents");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchParents();
    fetchCourseCategories();
    fetchschoolSubCategories();
  }, [
    page,
    rowsPerPage,
    searchTerm,
    selectedCategory,
    selectedSubCategory,
    selectedSchool,
  ]); // Fetch data when page or rowsPerPage changes

  // Fetch Course Categories
  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchschoolSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category/getsubcat/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolSubCategories(response.data.data || []);
        // get list of filter units
        // const subcategoryResponse1 = await axios.get(
        //   `${process.env.REACT_APP_BACKEND}/api/list/parents/bypartner/${selectedCategory}`,
        //   {
        //     headers: { Authorization: `Bearer ${token}` },
        //   }
        // );
        // setParents(subcategoryResponse1.data.data);
        // console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/getschool/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSchools(subcategoryResponse4.data.data);
        console.log("get school :", subcategoryResponse4.data.data);

        // // get list of filter units
        // const subcategoryResponse2 = await axios.get(
        //   `${process.env.REACT_APP_BACKEND}/api/list/parents/bypartner/${selectedCategory}/${selectedSubCategory}`,
        //   {
        //     headers: { Authorization: `Bearer ${token}` },
        //   }
        // );
        // setParents(subcategoryResponse2.data.data);
        // console.log("filtered units:", subcategoryResponse2.data.data);
      }
      // if (selectedSchool) {
      //   // get list of filter units
      //   const subcategoryResponse9 = await axios.get(
      //     `${process.env.REACT_APP_BACKEND}/api/list/parents/bypartner/${selectedCategory}/${selectedSubCategory}/${selectedSchool}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   );
      //   setParents(subcategoryResponse9.data.data);
      //   console.log(
      //     "filtered teachers with school:",
      //     subcategoryResponse9.data.data
      //   );
      // }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };
  // Handle Category Change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  // Handle Subcategory Change
  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  // Handle Search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage); // Update page state
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100)); // Update rowsPerPage state
    setPage(0); // Reset to first page
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Parent List by partner</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  schoolCategories.find(
                    (category) => category._id === selectedCategory
                  ) || null
                } // Find the selected school category by ID
                onChange={(e, newValue) => {
                  setSelectedCategory(newValue ? newValue._id : ""); // Update selectedCategory with the selected category's ID
                  setSelectedSubCategory(''); 
                  setSelectedSchool('');
                }}
                options={schoolCategories.sort((a, b) =>
                  a.school_name.localeCompare(b.school_name)
                )} // Sort categories A to Z
                getOptionLabel={(option) => option.school_name} // Display the school category name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by school category ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School Category"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
              />
            </FormControl>
          </Grid>

   { selectedCategory &&      
    <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  schoolSubCategories.find(
                    (subCategory) => subCategory._id === selectedSubCategory
                  ) || null
                } // Find the selected subcategory by ID
                onChange={(e, newValue) => {
                  setSelectedSubCategory(newValue ? newValue._id : ""); // Update selectedSubCategory with the selected subcategory's ID
                  setSelectedSchool('');
                }}
                options={schoolSubCategories.sort((a, b) =>
                  a.SubCategory_name.localeCompare(b.SubCategory_name)
                )} // Sort subcategories A to Z
                getOptionLabel={(option) => option.SubCategory_name} // Display the subcategory name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by subcategory ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School Subcategory"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
              />
            </FormControl>
          </Grid>}

     { selectedSubCategory &&  
        <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  schools.find(
                    (school) => school._id === selectedSchool
                  ) || null
                } // Find the selected school by ID
                onChange={(e, newValue) => {
                  setSelectedSchool(newValue ? newValue._id : ""); // Update selectedSubCategory with the selected school's ID
                }}
                options={schools.sort((a, b) =>
                  a.school_name.localeCompare(b.school_name)
                )} // Sort schools A to Z
                getOptionLabel={(option) => option.school_name} // Display the school name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by school ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
              />
            </FormControl>
          </Grid>}
        </Grid>
      </Box>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20px" }}> No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>School Category</TableCell>
                <TableCell>School Subcategory</TableCell>
                <TableCell>School</TableCell>
                <TableCell>Student</TableCell>
                <TableCell>Class</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Relation to Student</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parents.length > 0 ? (
                parents.map((parent, index) => (
                  <TableRow key={parent._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{parent.parents_name}</TableCell>
                    <TableCell>
                      {parent.school_category?.school_name
                        ? parent.school_category?.school_name
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {parent.school_sub_category?.SubCategory_name
                        ? parent.school_sub_category?.SubCategory_name
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {parent.school?.school_name
                        ? parent.school?.school_name
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {parent.student?.name ? parent.student?.name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {parent.grade_division ? parent.grade_division : "N/A"}
                    </TableCell>
                    <TableCell>{parent.p_email}</TableCell>
                    <TableCell>{parent.contact_num}</TableCell>
                    <TableCell>{parent.relation_to_student}</TableCell>
                    <TableCell>
                      {parent.status ? "Active" : "Inactive"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No parents found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Table Pagination */}
      {totalItems > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 75, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Container>
  );
};

export default Parents;
